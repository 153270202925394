import { objectStatus } from 'shared/libs/lib';
import { IStock } from 'wishlist/types';

export const filterActiveProducts = (productsList: any[], showEOL: boolean) => {
	if (!productsList || productsList.length == 0) {
		return [];
	}

	if (showEOL) {
		return productsList;
	}

	const filteredProducts = productsList.filter((product: any, index) => {
		const { stock, behavior } = product;
		const productStatus = objectStatus({
			stock,
			behavior,
		});
		return productStatus.canOrder;
	});

	return filteredProducts;
};

export const filterInStock = (products: any[]) => {
	const filterStockProducts = (product: any) => {
		let stock: IStock = product?.stock;
		let stockQuantity: number = stock?.stockQuantity;

		return stockQuantity > 0;
	};

	const isActive = (product: any) => {
		const excludedStatus: string[] = ['Stopped', 'EOL'];

		let status: string = product?.behavior?.status;
		let isStopped: boolean = excludedStatus.includes(status);

		return !isStopped;
	};

	let activeProducts = products.slice().filter(isActive);
	// set to original array when no products are active
	if (activeProducts.length == 0) {
		activeProducts = products;
	}

	let productsInStock = activeProducts.slice().filter(filterStockProducts);

	// return array with active products in case no products are in stock
	if (productsInStock.length == 0) {
		return activeProducts;
	}

	return productsInStock;
};
