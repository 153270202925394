import React, { FC, Fragment } from 'react';
import { IProductBlockState } from 'productblock/container/types';
import ProductItem from 'productblock/container/productItem';
import ProductShadow from 'productblock/components/productShadow';
import createConnect from 'productblock/container/connect';

interface OwnProps {
	reactId: string;
	hideEOLProducts: boolean;
}

interface StateProps {
	list: IProductBlockState;
}

interface DispatchProps {}

type IProps = StateProps & DispatchProps & OwnProps;

const ProductContainer: FC<IProps> = (props) => {
	const { list } = props;
	const { products, isFetching } = list;

	return (
		<div className='g g-equal-height swipable jc-center-b4 list-click'>
			{isFetching && (
				<Fragment>
					<ProductShadow />
					<ProductShadow />
					<ProductShadow />
					<ProductShadow />
				</Fragment>
			)}
			{!isFetching &&
				products &&
				products.length > 0 &&
				products.map((product, index) => {
					return (
						<ProductItem
							reactId={props.reactId}
							key={index}
							product={product}
							hideEOLProducts={props.hideEOLProducts}
						/>
					);
				})}
		</div>
	);
};


const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(ProductContainer);
