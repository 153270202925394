import DirectOrder from './DirectOrder';
import { Product } from './Product';
import { IDirectOrderState } from 'directorder/container/types';
import { IJSON } from 'directorder/types';

export default class Mapper {
	static getDirectOrderState(initial: object, verbose: boolean = false): IDirectOrderState {
		if (verbose) {
			console.log('mapping', initial);
		}

		let data = initial as IDirectOrderState;
		if (verbose) {
			console.log('data', data);
		}

		let output: IDirectOrderState = {
			orders: data.orders.map((order) => new DirectOrder(order)),
			isFetching: false,
			fetchProductsResult: undefined,
			addToBasketResult: undefined,
			finishOrderResult: undefined,
			doNotDeliverResult: undefined,
		};

		if (verbose) {
			console.log('output', output);
		}

		return output;
	}

	static getDirectOrders(response: IJSON, verbose: boolean = false): DirectOrder[] {
		if (verbose) {
			console.log('mapping', response);
		}

		if (!response.data['orders']) {
			if (verbose) {
				console.log('invalid response', response);
			}
			return [];
		}

		let data = response.data['orders'] as DirectOrder[];
		if (verbose) {
			console.log('data', data);
		}

		let output = data.map((line) => new DirectOrder(line));
		if (verbose) {
			console.log('output', output);
		}

		return output;
	}

	static getProducts(response: object, verbose: boolean = false): Product[] {
		if (verbose) {
			console.log('mapping', response);
		}

		let data = response as Product[];
		if (verbose) {
			console.log('data', data);
		}

		let output = data.map((line) => new Product(line));
		if (verbose) {
			console.log('output', output);
		}

		return output;
	}
}
