import Cookies from 'js-cookie';

export function objectStatus(data) {
	const stock = data?.stock ?? undefined;
	const behavior = data?.behavior ?? undefined;
	const status = behavior?.status ?? '';
	const productSubType = behavior?.productSubType ?? '';
	const hasBundleConfiguration = behavior?.hasBundleConfiguration ?? false;
	const isBundleItem = behavior?.isBundleItem ?? false;
	const isBundleItemAddVisible = behavior?.isBundleItemAddVisible ?? true;
	const stockQuantity = stock?.stockQuantity ?? 0;
	const stockLevel = stock?.stockLevel ?? 0;
	const stockDate = stock?.date ?? '';
	const statusMessage = stock?.statusMessage ?? null;
	const replacementMessage = stock?.replacementMessage ?? null;
	const stockText = stock?.stockText ?? '';
	const internal = behavior?.internal ?? false;

	//stockDate uses dd-mm-yyyy format
	var parts = stockDate.split('-');
	var maybeFutureDate =
		parts.length == 0
			? null
			: new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
	var now = new Date();
	var dateInFuture = Boolean(maybeFutureDate > now);

	var obj = {
		isShelfPlan: false,
		dispayStock: false,
		dispayStockPDP: false,
		dispayStockMobile: true,
		displayReplacement: false,
		stockText: stockText,
		stockMessage: statusMessage,
		stockSubMessage: '',
		statusClass: '',
		quantityClass: '',
		quantityText: '',
		showPrice: true,
		canOrder: true,
		canDropship: false,
		isEOL: false,
		isEOLReplaced: false,
		hasBundleConfiguration: hasBundleConfiguration,
		canOrderAsBundle: true,
	};

	if (stockQuantity > 0) {
		obj.canDropship = true;
	}
	if (stockQuantity === 0) {
		obj.displayReplacement = true;
	}

	switch (status) {
		case 'Active':
			//status active
			obj.dispayStock = true;
			obj.dispayStockPDP = true;
			if (stockQuantity === 0) {
				obj.quantityText = 0;
				if (stockDate === '') {
					//status active stock 0 datum leeg - rood - leverdatum onbekend
					obj.statusClass = 'alert-negative';
				} else if (dateInFuture) {
					// status active stock 0 datum in toekomst - rood - leverdatum op
					obj.statusClass = 'alert-negative';
				} else {
					// status active stock 0 datum onbekend - rood - leverdatum onbekend
					obj.statusClass = 'alert-negative';
				}
			} else if (stockQuantity > 0 && stockQuantity <= stockLevel) {
				//status active  stock tussen 0 en drempelwaarde - oranje + aantal
				obj.quantityText = stockQuantity > 99 ? 99 : stockQuantity;
				obj.statusClass = 'alert-warning';
			} else if (stockQuantity > 0 && stockQuantity > stockLevel) {
				// status active stock groter dan drempelwaarde - groen + vinkje - aantal als kb gebruiker is ingelogd
				obj.statusClass = 'alert-positive';

				if (typeof internal === 'boolean' && internal === true) {
					obj.quantityText = stockQuantity > 99 ? 99 : stockQuantity;
					if (stockQuantity > 99) {
						// iets met een plusje
						obj.statusClass += ' plus';
					}
				} else {
					obj.quantityClass = 'check';
				}
			}
			break;

		case 'Ending':
			//status ending
			obj.dispayStock = true;
			obj.dispayStockPDP = true;
			if (stockQuantity === 0 && stockDate === '') {
				// status ending stock 0 - grijs - niet meer leverbaar
				obj.dispayStock = false;
				obj.dispayStockPDP = false;
				obj.statusClass = 'alert-disabled';
				obj.isEOL = true;
				obj.displayReplacement = true;
			} else if (stockQuantity > 0 && stockQuantity <= stockLevel) {
				//sttus ending stock tussen 0 en drempelwaarde  - oranje + aantal
				obj.statusClass = 'alert-warning';
				obj.quantityText = stockQuantity > 99 ? 99 : stockQuantity;
			} else if (stockQuantity > 0 && stockQuantity > stockLevel) {
				// status ending  stock groter dan drempelwaarde - groen + vinkje
				obj.statusClass = 'alert-positive';
				if (typeof internal === 'boolean' && internal === true) {
					obj.quantityText = stockQuantity > 99 ? 99 : stockQuantity;
					if (stockQuantity > 99) {
						// iets met een plusje
						obj.statusClass += ' plus';
					}
				} else {
					obj.quantityClass = 'check';
				}
			}
			if (stockDate != '') {
				obj.dispayStock = true;
				obj.statusClass = 'alert-negative';
				obj.quantityText = stockQuantity > 99 ? 99 : stockQuantity;
			}

			break;

		case 'Inactive':
			// status inactive
			obj.dispayStock = true;
			obj.dispayStockPDP = true;
			obj.quantityText = 0;
			obj.statusClass = 'alert-disabled';
			break;

		case 'OnOrder':
			//status onorder oranje + vinkje - leverbaar op bestelling
			//oranje vinkje niet tonen op PDP
			obj.dispayStock = true;
			obj.dispayStockPDP = false;
			obj.statusClass = 'alert-warning';

			let stockMessages = statusMessage?.split('|');
			if (stockMessages?.length > 0) {
				obj.stockMessage = stockMessages[0];
				if (dateInFuture) {
					obj.stockSubMessage = stockMessages[1];
				}
			}

			obj.quantityClass = 'check';

			break;

		case 'Blocked':
			//status blocked - grijs + vinkje - tijdelijk niet meer leverbaar
			obj.dispayStock = true;
			obj.dispayStockPDP = false;
			obj.dispayStockMobile = false;
			obj.statusClass = 'alert-disabled';
			obj.quantityClass = 'check';
			break;

		case 'Stopped':
			//status stopped - grijs - niet meer leverbaar
			obj.statusClass = 'alert-disabled';
			obj.quantityText = 0;
			obj.isEOL = true;
			obj.displayReplacement = true;
			break;

		case 'HasReplacement':
			// status EOL & has replacement - oranje - Alternatief beschikbaar
			obj.statusClass = 'alert-warning';
			obj.quantityText = 0;
			obj.isEOL = true;
			obj.displayReplacement = true;
			obj.isEOLReplaced = true;

			let eolReplacementMessage = statusMessage?.split('|');
			if (eolReplacementMessage?.length > 0) {
				obj.stockMessage = eolReplacementMessage[0];
				obj.stockSubMessage = eolReplacementMessage[1];
			}

			break;

		case 'EOL':
			// status EOL - grijs - niet meer leverbaar
			obj.statusClass = 'alert-disabled';
			obj.quantityText = 0;
			obj.isEOL = true;
			obj.displayReplacement = true;
			break;

		case 'PreOrder':
			//status preorder - groen + vinkje - leverbaar vanaf datum
			obj.dispayStock = true;
			obj.dispayStockPDP = true;
			obj.statusClass = 'alert-positive';
			obj.quantityClass = 'check';
			//PreOrder does not rely on stock to be able to dropship
			obj.canDropship = true;
			break;

		default:
	}

	if (obj.isEOL) {
		obj.canOrder = false;
		obj.showPrice = false;
	}
	if (productSubType == 'ShelfPlan') {
		obj.showPrice = false;
		obj.isShelfPlan = true;
		obj.quantityText = '';
		obj.statusClass = '';
		obj.stockMessage = '';
	}

	if (obj.quantityText === 0) {
		obj.replacementMessage = replacementMessage;
		obj.replacementClass = 'alert-warning small'; //use the same color for replacement products
	}

	if (!obj.canOrder) {
		obj.canDropship = false;
	}

	if (hasBundleConfiguration) {
		obj.canOrder = false;
		obj.canDropship = false;
		obj.displayReplacement = false;
	}

	if (isBundleItem && !isBundleItemAddVisible) {
		obj.canOrderAsBundle = obj.canOrder;
		obj.canOrder = false;
		obj.canDropship = false;
		obj.displayReplacement = false;
	}

	return obj;
}

export function setProductIndexes(products, startIndex) {
	let index = 0;
	if (startIndex) {
		index = startIndex;
	}
	let productItems = [];

	if (products == null) {
		return productItems;
	}

	for (var i = 0; i < products.length; i++) {
		let product = products[i];
		product.index = index;
		product.isChild = false;
		productItems.push(product);
		index++;
	}

	return productItems;
}

export function mergeProductVariants(products, startIndex, isPDP) {
	let index = 0;
	if (startIndex) {
		index = startIndex;
	}
	let productItems = [];

	if (products == null) {
		return productItems;
	}

	for (var i = 0; i < products.length; i++) {
		let product = products[i];

		let isParent = product.productFamily != null && product.productFamily.familyItems.length > 1;
		let hasReplacements = product.replacementItems != null && product.replacementItems.length > 0;
		let childCount = 0;
		if (isPDP) {
			isParent = false;
			if (hasReplacements) {
				childCount = product.replacementItems.length;
			}
		} else if (isParent) {
			childCount = product.productFamily.familyItems.length;
		}

		product.isParent = isParent;
		product.isReplacedProduct = hasReplacements;
		product.isChild = false;
		product.index = index;
		product.childCount = childCount;
		productItems.push(product);

		index++;

		const getParent = function () {
			return this;
		}.bind(product);

		// default child product data
		function addChildProducts(childProducts, currentIndex) {
			let lastIndex = childProducts.length - 1;
			let childIndex = currentIndex;

			for (let j = 0; j < childProducts.length; j++) {
				let child = childProducts[j];
				child.isParent = false;
				child.isChild = true;
				child.isFirstChild = j == 0 ? true : false;
				child.isLastChild = lastIndex == j ? true : false;
				child.index = childIndex;
				child.parentSku = product.sku;
				child.isReplacement = hasReplacements;
				child.isFollowUp =
					child.productLabels?.find((label) => label.name === 'followup') !== undefined
						? true
						: false;
				child.getParent = getParent;

				childIndex++;
				productItems.push(child);
			}
			return childIndex;
		}

		if (isPDP) {
			if (hasReplacements) {
				index = addChildProducts(product.replacementItems, index);
			}
		} else if (isParent) {
			index = addChildProducts(product.productFamily.familyItems, index);
		}
	}

	return productItems;
}

export function findIndex(index, action, products, openMap, showTileView) {
	//disabled arrow support on tileview for now
	if (showTileView) {
		return index;
	}

	let newIdx = index;
	let product = products[index];
	//let update = true;

	switch (action) {
		case 'down':
			//find next regular element
			if (product.isChild && !openMap.get(product.parentSku)) {
				let i = newIdx;

				while (products[i]) {
					if (!products[i].isChild) {
						newIdx = products[i].index;
						break;
					}
					i++;
				}
			}
			break;

		case 'up':
			//find previous regular element
			if (product.isChild && !openMap.get(product.parentSku)) {
				let i = newIdx;

				while (products[i]) {
					if (!products[i].isChild) {
						newIdx = products[i].index;
						break;
					}
					i--;
				}
			}
			break;

		case 'click':
			//toggle parent open state and select first child
			if (product.isParent) {
				var isOpen = openMap.get(product.sku) ? false : true;
				if (isOpen) {
					newIdx += 1;
				}
			}
			break;
		default:
			break;
	}

	return newIdx;
}

export function toggleOpen(index, action, products, openMap, showTileView) {
	let update = false;
	switch (action) {
		case 'open':
		case 'close':
		case 'click':
			update = true;
			break;
		default:
			break;
	}

	if (!update) {
		return openMap;
	}

	let product = products[index];
	if (product.isChild) {
		return openMap;
	}

	let isOpen = false;
	switch (action) {
		case 'open':
			isOpen = true;
			break;
		case 'close':
			isOpen = false;
			break;
		default:
			isOpen = openMap.get(product.sku) ? false : true;
			break;
	}

	var clone = new Map(openMap);
	clone.set(product.sku, isOpen);

	function mapElements(value, key, map) {
		if (key !== product.sku) {
			map.delete(key);
		}
	}

	if (Cookies.get('showTileView') === 'true') {
		clone.forEach(mapElements);
	}

	return clone;
}

/*
 * Transform the flat filter array (each with a .level property) to a nested object
 * Returns a array containing the root filters.
 * each filter has a .children array that can contain 0 or more sub filters
 */
export function createNestedFilters(filterElements) {
	let verbose = false;

	//output array containing only the root elements
	let out = [];

	//breadcrumb trail
	let path = [];

	//state of parent/current elements
	let parentElement = null;
	let currentLevel = 0;

	//used for requirement "uncollapse level 1 with highest number of results"
	let levelOneElementWithMaxResults = null;

	//used for requrement "only display the deepest nested element as selected"
	let selectedElement = null;

	for (let i = 0; i < filterElements.length; i++) {
		//create a copy of every filter item (prop == readonly!)
		let currentElement = Object.assign({}, filterElements[i], {
			children: [],
			collapsed: true,
			showAsSelected: false,
		});

		if (path.length > 0 && currentElement.level == 0) {
			//add root element and reset path/parentElement
			if (verbose) {
				console.log('adding root element:', path[0].name, path[0].level, path);
			}
			out.push(path[0]);
			path = [];
			parentElement = null;
		}

		//useful for debugging
		if (verbose) {
			let outputPath = '';
			for (let j = 0; j < path.length; j++) {
				outputPath += '[' + path[j].name + '] ' + path[j].level + ' ';
			}
			console.log('', outputPath);
		}

		if (parentElement == null || currentElement.level > currentLevel) {
			//next level:
			path.push(currentElement);
			if (parentElement != null) {
				parentElement.children.push(currentElement);
				if (verbose) {
					console.log(
						'next level:',
						currentElement.name,
						currentElement.level,
						' added to ',
						parentElement.name,
						parentElement.level
					);
				}
			}

			parentElement = currentElement;
		} else if (currentElement.level < currentLevel) {
			//previous level:
			//go back until currentLevel-1
			while (parentElement.level != currentElement.level - 1) {
				parentElement = path.pop();
				if (path.length == 0) {
					break;
				}
			}
			parentElement.children.push(currentElement);
			path.push(parentElement);
			path.push(currentElement);

			if (verbose) {
				console.log(
					'previous level:',
					currentElement.name,
					currentElement.level,
					' added to ',
					parentElement.name,
					parentElement.level
				);
			}

			parentElement = currentElement;
		} else if (currentElement.level == currentLevel) {
			parentElement = path.pop();
			parentElement = path.pop();
			parentElement.children.push(currentElement);

			//replace last element in path
			path.push(parentElement);
			path.push(currentElement);

			if (verbose) {
				console.log(
					'same level:',
					currentElement.name,
					currentElement.level,
					' added to ',
					parentElement.name,
					parentElement.level
				);
			}

			parentElement = currentElement;
		}

		//set collapsed prop
		if (currentElement.level == 0) {
			currentElement.collapsed = false;
		}
		if (currentElement.level == 1) {
			if (levelOneElementWithMaxResults == null) {
				levelOneElementWithMaxResults = currentElement;
			} else if (currentElement.numberOfResults > levelOneElementWithMaxResults.numberOfResults) {
				levelOneElementWithMaxResults = currentElement;
			}
		}

		//set showAsSelected prop
		if (currentElement.selected) {
			if (selectedElement == null) {
				selectedElement = currentElement;
			} else if (currentElement.level > selectedElement.level) {
				selectedElement = currentElement;
			}
		}

		currentLevel = currentElement.level;
	}

	if (levelOneElementWithMaxResults != null) {
		levelOneElementWithMaxResults.collapsed = false;
	}

	if (selectedElement != null) {
		selectedElement.showAsSelected = true;
	}

	//add current root element
	if (path.length > 0) {
		if (verbose) {
			console.log('adding root element:', path[0].name, path[0].level, path);
		}
		out.push(path[0]);
	}

	//debug output
	function outputResult(filters, level) {
		for (var i = 0; i < filters.length; i++) {
			var filter = filters[i];
			var indent = '----'.repeat(level);
			console.log(indent, filter.name, filter.level);

			var currentLevel = level + 1;
			outputResult(filter.children, currentLevel);
		}
	}

	if (verbose) {
		console.log('***result***');
		outputResult(out, 0);
	}

	return out;
}

export function getOrderQuantity(quantity, stockQuantity, stockDate, maxQuantity, status) {
	var orderQuantity = {
		quantity: quantity,
		message: false,
	};

	stockDate = !stockDate ? '' : stockDate;

	//test: Ending + stockDate in the future, no check on quantity
	// status = 'Ending';
	// stockDate = '01-01-2030';

	//test: Ending + stockDate in the past, validate (quantity <= stockQuantity | maxQuantity)
	// status = 'Ending';
	// stockDate = '01-01-2018';

	//stockDate uses dd-mm-yyyy format
	var parts = stockDate.split('-');
	var maybeFutureDate =
		parts.length == 0
			? null
			: new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
	var now = new Date();
	var dateInFuture = Boolean(maybeFutureDate > now);

	let maximumQuantities = [];
	if (status === 'Ending') {
		if (!dateInFuture) {
			maximumQuantities.push(stockQuantity);
		}
	} else if (maxQuantity > 0) {
		maximumQuantities.push(maxQuantity);
	}

	// is quantity calculation needed
	let quantityCalculation = maximumQuantities.length > 0;
	if (quantityCalculation) {
		let maxOrderQuantity = Math.min(...maximumQuantities);
		if (maxOrderQuantity < quantity) {
			orderQuantity.quantity = maxOrderQuantity;
			orderQuantity.message = true;
		}
	}

	orderQuantity.quantityCalculation = quantityCalculation;

	return orderQuantity;
}

export function objectBehavior(productTypeBehavior) {
	if (productTypeBehavior === 'Bike') {
		let obj_bike = {
			expressDelivery: true,
			multipleconfigurations: true,
			canhavealternatives: false,
			candropship: false,
		};
		return obj_bike;
	} else {
		let obj_part = {
			expressDelivery: false,
			multipleconfigurations: true, // multiple configurations also allowed for parts
			canhavealternatives: true,
			candropship: true,
		};
		return obj_part;
	}
}

export function getProductPrices(prices, sale, showPrices, showMsrPrices, showPrice) {
	let productPrices = {
		productPrice: false,
		grossPrice: false,
	};

	if (showPrice && prices && showPrices) {
		if (showMsrPrices) {
			productPrices.productPrice = prices.find(function (price) {
				return price.name == 'ListPrice';
			});
		} else {
			productPrices.productPrice = prices.find(function (price) {
				return price.name == 'NettPrice';
			});
			if (productPrices.productPrice == null) {
				productPrices.productPrice = prices.find(function (price) {
					return price.name == 'CostPrice';
				});
			}
		}

		if (sale) {
			productPrices.grossPrice = prices.find(function (price) {
				return price.name == 'GrossPrice';
			});
		}
	}

	return productPrices;
}
