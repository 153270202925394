import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import Dropshipment from './applications/Dropshipment';
import NotificationBar from './applications/NotificationBar';
import SearchBar from './applications/SearchBar';
import HeaderMainMenu from './applications/HeaderMainMenu';
import ProductBlock from './../../productblock/index';
import DirectOrder from './../../directorder/index';
import TweakwiseProducts from './../../dashboard/tweakwiseproducts/index';
import OrderInfo from 'dashboard/orderinfo/index';
import ErrorBoundary from './errorBoundary';

import {
	addLocalizationsToStore,
	addSynchronizerTokenToStore,
	addDataToStore,
	addExtensionsToStore,
} from 'general/actions';

import { productblockReducer } from 'productblock/container/reducers';
import { directorderReducer } from 'directorder/container/reducers';
import { headermainmenuReducer } from 'shared/general/headermainmenu/reducers';
import { tweakwiseProductsReducer } from './../../dashboard/tweakwiseproducts/container/reducers';
import { orderInfoReducer } from 'dashboard/orderinfo/container/reducers';

export function createGeneralComponents(store) {
	function genericComponent(name) {
		const generalMap = {
			'./Dropshipment': Dropshipment,
			'./NotificationBar': NotificationBar,
			'./HeaderMainMenu': HeaderMainMenu,
			'./SearchBar': SearchBar,
			'./ProductBlock': ProductBlock,
			'./DirectOrder': DirectOrder,
			'./TweakwiseProducts': TweakwiseProducts,
			'./OrderInfo': OrderInfo,
		};
		return generalMap[name];
	}
	const apps = Array.from(document.querySelectorAll('[data-react-general]'));

	const renderGeneralComponent = (element) => {
		const {
			reactGeneral,
			reactId,
			reactUrl,
			reactSynchronizertoken,
			reactLocalizations,
			reactData,
			reactExtensions,
		} = element.dataset;
		let localizations = null;
		let data = null;
		let extensions = null;

		if (reactLocalizations) {
			localizations = JSON.parse(reactLocalizations);
			addLocalizationsToStore(store, localizations);
		}

		if (reactSynchronizertoken) {
			addSynchronizerTokenToStore(store, reactId, reactSynchronizertoken);
		}

		if (reactData) {
			data = JSON.parse(reactData);
			addDataToStore(store, reactId, data);
		}

		if (reactExtensions) {
			extensions = JSON.parse(reactExtensions);
			addExtensionsToStore(store, extensions);
		}
		if (reactId && store.injectReducer) {
			switch (reactGeneral) {
				case './DirectOrder':
					store.injectReducer(reactId, directorderReducer(reactId));
					break;
				case './TweakwiseProducts':
					store.injectReducer(reactId, tweakwiseProductsReducer(reactId));
					break;
				case './ProductBlock':
					store.injectReducer(reactId, productblockReducer(reactId));
					break;
				case './OrderInfo':
					store.injectReducer(reactId, orderInfoReducer(reactId));
					break;
				case './HeaderMainMenu':
					if (data) {
						store.injectReducer(reactId, headermainmenuReducer(data));
					}
					break;
				default:
			}
		}

		const GenericComponent = genericComponent(reactGeneral);
		if (GenericComponent) {
			render(
				<ErrorBoundary>
					<Provider store={store}>
						<GenericComponent url={reactUrl} reactId={reactId} />
					</Provider>
				</ErrorBoundary>,
				document.querySelector(`[data-react-id="${reactId}"]`)
			);
		}
	};

	apps.forEach((element) => {
		try {
			renderGeneralComponent(element);
		} catch (e) {
			console.error('could not render component:', element, e);
		}
	});
}
