export interface IHTTPResult {
	json: object;
	status: number;
	error: boolean;
}

export interface History {
	push(path: string, state?: any): void;
	goBack(): any;
}

export interface IHTTPResult {
	json: object;
	status: number;
	error: boolean;
}

export interface IOption {
	key: string;
	value: string;
	disabled?: boolean;
	label?: string;
	identifier?: string;
}

export interface IOrderAdviceGroup {
	id: string;
	groupId: string;
	title: string;
	progressOpen: number;
	progressTarget: number;
	recommendedProducts?: boolean;
	products: IOrderAdviceGroupProduct[];
}
export interface IOrderAdviceGroupProduct {
	sku: string;
	recommended: boolean;
	suggesteddate: string;
}

export interface IProduct {
	sku: string;
	images: [];
	name: string;
	//status: string;
	stock: IStock;
	behavior: IBehavior;
	statusMessage: string;
	prices: IPrice[];
	sale: boolean;
	quantity: number;
	isLoaded: boolean;
	error: boolean;
	success: boolean;
	waiting: boolean;
	recommended: string;
	suggesteddate: string;
	suggesteddateFriendly: string;
}

export interface IStock {
	date: Date;
	stockLevel: number;
	stockMessage: string;
	stockQuantity: number;
	stockStatus: string;
}

export interface IPrice {
	discountPercentage: string;
	fraction: string;
	name: string;
	quantityFrom: number;
	whole: string;
}

export interface IBehavior {
	status: string;
	productType: string;
	productTypeBehavior: string;
	maxQuantity: number;
	hasReplacement: boolean;
	hasBrochure: boolean;
	replacementLinks: Array<object>;
}

export interface IOrderAdvice {
	customerNo: string;
	dateEnd: string;
	adviceId: string;
	id: string;
	title: string;
	subtitle: string;
	description: string;
	expired: boolean;
	daysBeforeExpired: number;
	progressOpen: number;
	progressTarget: number;
	groups: IOrderAdviceGroup[];
}
export interface IOrderAdviceState {
	orderAdviceGroups: IOrderAdviceGroup[];
	orderAdviceSelected: IOrderAdvice;
	orderAdviceGroupsCollection: object;
}

export enum OrderAdvicesActions {
	SET_ORDER_ADVICE_SELECTED = 'SET_ORDER_ADVICE_SELECTED',
	REQUEST_ORDER_ADVICES_LIST = 'REQUEST_ORDER_ADVICES_LIST',
	RECEIVE_ORDER_ADVICES_LIST = 'RECEIVE_ORDER_ADVICES_LIST',
	REQUEST_ORDER_ADVICES_DETAIL = 'REQUEST_ORDER_ADVICES_DETAIL',
	RECEIVE_ORDER_ADVICES_DETAIL = 'RECEIVE_ORDER_ADVICES_DETAIL',
	REQUEST_ORDER_ADVICE_GROUPS = 'REQUEST_ORDER_ADVICE_GROUPS',
	RECEIVE_ORDER_ADVICE_GROUPS = 'RECEIVE_ORDER_ADVICE_GROUPS',
	REQUEST_ORDER_ADVICE_GROUP_PRODUCTS = 'REQUEST_ORDER_ADVICE_GROUP_PRODUCTS',
	RECEIVE_ORDER_ADVICE_GROUP_PRODUCTS = 'RECEIVE_ORDER_ADVICE_GROUP_PRODUCTS',
	REQUEST_ORDER_ADVICE_GROUP_PRODUCT = 'REQUEST_ORDER_ADVICE_GROUP_PRODUCT',
	RECEIVE_ORDER_ADVICE_GROUP_PRODUCT = 'RECEIVE_ORDER_ADVICE_GROUP_PRODUCT',
	REQUEST_ADDPRODUCTTOBASKET_WITHREFERENCE = 'REQUEST_ADDPRODUCTTOBASKET_WITHREFERENCE',
	RECEIVE_ADDPRODUCTTOBASKET_WITHREFERENCE = 'RECEIVE_ADDPRODUCTTOBASKET_WITHREFERENCE',
}
//TODO:remove
interface RequestOrderAdvicesDetail {
	type: typeof OrderAdvicesActions.REQUEST_ORDER_ADVICES_DETAIL;
	id: string;
}
//TODO:remove
interface RecieveOrderAdvicesDetail {
	type: typeof OrderAdvicesActions.RECEIVE_ORDER_ADVICES_DETAIL;
	orderAdvice: IOrderAdvice;
}

interface SetOrderAdviceSelected {
	type: typeof OrderAdvicesActions.SET_ORDER_ADVICE_SELECTED;
	orderAdvice: IOrderAdvice;
}

interface RequestOrderAdviceGroups {
	type: typeof OrderAdvicesActions.REQUEST_ORDER_ADVICE_GROUPS;
	id: string;
}

interface RecieveOrderAdviceGroups {
	type: typeof OrderAdvicesActions.RECEIVE_ORDER_ADVICE_GROUPS;
	id: string;
	orderAdviceGroups: IOrderAdviceGroup[];
}

interface RequestOrderAdviceGroupProducts {
	type: typeof OrderAdvicesActions.REQUEST_ORDER_ADVICE_GROUP_PRODUCTS;
	adviceId: string;
	id: string;
}

interface RecieveOrderAdviceGroupProducts {
	type: typeof OrderAdvicesActions.RECEIVE_ORDER_ADVICE_GROUP_PRODUCTS;
	id: string;
	adviceId: string;
	orderAdviceGroupProducts: IOrderAdviceGroupProduct[];
}

interface RequestOrderAdviceGroupProduct {
	type: typeof OrderAdvicesActions.REQUEST_ORDER_ADVICE_GROUP_PRODUCT;
	sku: string;
	adviceId: string;
	groupId: string;
}

interface RecieveOrderAdviceGroupProduct {
	type: typeof OrderAdvicesActions.RECEIVE_ORDER_ADVICE_GROUP_PRODUCT;
	sku: string;
	product: object;
	adviceId: string;
	groupId: string;
}

interface RequestAddProductToBasketWithRef {
	type: typeof OrderAdvicesActions.REQUEST_ADDPRODUCTTOBASKET_WITHREFERENCE;
	sku: string;
	reference: string;
	suggestedDate: string;
	adviceId: string;
	groupId: string;
}

interface RecieveAddProductToBasketWithRef {
	type: typeof OrderAdvicesActions.RECEIVE_ADDPRODUCTTOBASKET_WITHREFERENCE;
	sku: string;
	reference: string;
	suggestedDate: string;
	adviceId: string;
	groupId: string;
	result: any;
}

export type OrderAdvicesActionsType =
	| RequestOrderAdvicesDetail
	| RecieveOrderAdvicesDetail
	| RequestOrderAdviceGroups
	| RecieveOrderAdviceGroups
	| RequestOrderAdviceGroupProducts
	| RecieveOrderAdviceGroupProducts
	| SetOrderAdviceSelected
	| RequestOrderAdviceGroupProduct
	| RecieveOrderAdviceGroupProduct
	| RequestAddProductToBasketWithRef
	| RecieveAddProductToBasketWithRef;
