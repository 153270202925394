import { IHTTPResult } from 'shared/types';
import { getResultOrRedirect } from 'shared/libs/urlhandling';
import { OrderInfoActions } from './types';

const assignReactId = (obj: object, reactId: string) => {
	return Object.assign(obj, {
		reactId: reactId,
	});
};

const requestOrderInfoData = () => {
	return {
		type: OrderInfoActions.REQUEST_ORDERINFO_DATA,
	};
};

const receiveOrderInfoData = (result?: IHTTPResult) => {
	return {
		type: OrderInfoActions.RECEIVE_ORDERINFO_DATA,
		result,
	};
};

export const fetchOrderInfoData = (reactId: string) => {
	return (dispatch: any, getState: any) => {
		dispatch(assignReactId(requestOrderInfoData(), reactId));
		const { extensions, synchronizer } = getState();
		const synchronizerToken = synchronizer[reactId];

		let url = extensions.OrderInfoUrl;
		let body = '';
		body += '&SynchronizerToken=' + synchronizerToken;

		return fetch(`${url}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getResultOrRedirect)
			.then((result: object) => result as IHTTPResult)
			.then((result: IHTTPResult) => {
				dispatch(assignReactId(receiveOrderInfoData(result), reactId));
			})
			.catch((error: any) => {
				dispatch(assignReactId(receiveOrderInfoData({ error: true, status: 500 }), reactId));
			});
	};
};
