import { isEmail, isFloat, isDate } from 'validator';
import { truncate } from 'fs';

export function required(value) {
	if (!value) {
		return false;
	}
	if (!value.toString().trim().length) {
		// We can return string or jsx as the 'error' prop for the validated Component
		return false;
	}
	return true;
}

export function email(value) {
	if (!value) {
		return false;
	}
	if (!isEmail(value)) {
		return false;
	}
	return true;
}

export function range(value, min, max) {
	if (!value) {
		return false;
	}
	if (!isFloat(value, { min: min, max: max })) {
		return false;
	}
	return true;
}

export function regex(value, regex) {
	if (!value) {
		return false;
	}

	if (!value.match(regex)) {
		return false;
	}

	return true;
}

export function date(value, format) {
	if (!value) {
		return false;
	}
	if (!isDate(value, format)) {
		return false;
	}
	return true;
}
