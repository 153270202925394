import React, { FC } from 'react';

interface OwnProps {
	showPrices?: boolean;
}

const ProductShadow: FC<OwnProps> = (props: OwnProps) => {
	const { showPrices } = props;

	return (
		<article className='list-item b0_12 b3_12 b4_06 b6_04'>
			<div className='p'>
				<div className='tiles-img-wrap'>
					<picture data-picture>
						<source
							srcSet='https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:320:320:0:1/bg:ffffff/q:60, https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:640:640:0:1/bg:ffffff/q:60 2x'
							media='(min-width: 320px)'
						/>

						<img
							src='https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:320:320:0:1/bg:ffffff/q:60'
							alt='Placeholder'
						/>
					</picture>
				</div>

				<div>
					<div className='list-item-titlewrap'>
						<div className='title shadow-element'>&nbsp;</div>

						<div className='sku shadow-element'>
							<span className='inline-block narrow'>
								<small className='color-color06'>&nbsp;</small>
							</span>
						</div>

						<div className='pb'>
							{showPrices && (
								<div className='prices shadow-element'>
									<span className='price'>&nbsp;</span>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</article>
	);
};

export default ProductShadow;
