import React, { FC, useState, useEffect } from 'react';
import { Item } from 'shared/messages/Messages';
import ProgressElement from 'shared/components/ProgressElement';
import MenuItemWrap from './MenuItemWrap';

interface OwnProps {
	mainMenuItems: Item[] | undefined;
	part: boolean;
	bike: boolean;
	loaded: boolean;
	sparepartsUrl: string;
	localizations: object;
}

type IProps = OwnProps;

const MenuItems: FC<IProps> = (props) => {
	const { mainMenuItems, part, bike, loaded, localizations, sparepartsUrl } = props;
	const [hideMenu, setHidemenu] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setHidemenu(false);
		}, 1000);

		return () => clearTimeout(timer);
	}, [hideMenu]);

	const maxSubMenuLength = 19;

	return (
		<div className={`main-nav-sub sub ${hideMenu ? 'js-hide' : ''}`} data-subcontainer>
			<div className='main-nav-sub-level1'>
				<ul>
					{mainMenuItems?.map((mainMenuItem, index) => {
						return (
							<MenuItemWrap
								key={`${index}-${mainMenuItem.link}`}
								classes={`main-nav-sub-level1-item`}
							>
								<a href={mainMenuItem.link}>
									{mainMenuItem.title}
									{mainMenuItem.subMenuItems && <em className='icon-right-open'></em>}
								</a>
								{mainMenuItem.subMenuItems && (
									<div className='main-nav-sub-level2'>
										<ul>
											{!loaded && (
												<li key={'loader'} className='main-nav-sub-level2-item p'>
													<ProgressElement waiting={true} />
												</li>
											)}
											{mainMenuItem.subMenuItems
												?.slice(0, maxSubMenuLength)
												.map((subMenuItem, index: number) => {
													return (
														<MenuItemWrap
															key={`${index}-${subMenuItem.link}`}
															classes={`main-nav-sub-level2-item`}
														>
															<a href={subMenuItem.link}>
																{subMenuItem.title}
																{subMenuItem.menuItems && subMenuItem.menuItems.length > 0 && (
																	<em className='icon-right-open'></em>
																)}
															</a>
															<div className='main-nav-sub-level3'>
																<ul className='main-nav-sub-level3-items'>
																	{subMenuItem.menuItems
																		?.slice(0, maxSubMenuLength)
																		.map((subSubMenuItem, index) => {
																			return (
																				<MenuItemWrap
																					key={`${index}-${subMenuItem.link}`}
																					classes={`main-nav-sub-level3-item`}
																				>
																					<a href={subSubMenuItem.link}>
																						{subSubMenuItem.title}
																						{subSubMenuItem.menuItems &&
																							subSubMenuItem.menuItems.length > 0 && (
																								<em className='icon-right-open'></em>
																							)}
																					</a>
																				</MenuItemWrap>
																			);
																		})}
																	<MenuItemWrap classes={`main-nav-sub-level3-item all`}>
																		<a href={subMenuItem.link}>
																			{`${localizations['common.header.navigation.item.all.text']} ${subMenuItem?.title}`}
																		</a>
																	</MenuItemWrap>
																</ul>
															</div>
														</MenuItemWrap>
													);
												})}
											<MenuItemWrap classes={`main-nav-sub-level2-item all`}>
												<a href={mainMenuItem.link}>
													{`${localizations['common.header.navigation.item.all.text']} ${mainMenuItem.title}`}
												</a>
											</MenuItemWrap>
										</ul>
									</div>
								)}
							</MenuItemWrap>
						);
					})}
					{bike && (
						<li key={'spareparts'} className={`main-nav-sub-level1-item parts`}>
							<a data-sl='bike-parts' href={sparepartsUrl}>
								{localizations['common.header.navigation.spareparts.text']}
							</a>
						</li>
					)}
				</ul>
			</div>
		</div>
	);
};

export default MenuItems;
