import React, { FC } from 'react';

interface IProps {
	/*
	 * Shows spinner when false, overlay when true. The default is "false".
	 */
	overlay?: boolean;
	/*
	 * The overlay is always centered horizontally but when "centered" is true, this is displayed centered vertically as well. The default is "true".
	 */
	centered?: boolean;
	/*
	 * The overlay is displayed with a top margin specifically for close buttons. The default is "false".
	 */
	closeButton?: boolean;
}

const Loading: FC<IProps> = (props) => {
	const { overlay, centered, closeButton } = props;
	const isOverlay = overlay !== null && overlay === true;
	const isCentered = centered === null || centered === true;
	const hasCloseButton = closeButton !== null && closeButton === true;

	if (!isOverlay) {
		return (
			<div className='p p-b3-2x bgcolor-color01'>
				<i className='icon-spin4 animate-spin spinner'></i>
			</div>
		);
	} else {
		return (
			<div
				id='overlayLoading'
				className={`overlay-loader${isCentered ? '-centered' : ''}${
					hasCloseButton ? ' closebutton' : ''
				}`}
			>
				<i className='icon-kruitbosch-logo'></i>
				<div className='saving'>
					<span>.</span>
					<span>.</span>
					<span>.</span>
				</div>
			</div>
		);
	}
};

export default Loading;
