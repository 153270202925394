import { OrderInfoActionTypes, OrderInfoActions } from 'dashboard/orderinfo/container/types';
import { IOrderInfoState } from 'dashboard/orderinfo/types';
import produce from 'immer';
import { IOrderInfoData } from '../messages/types';

const initialState: IOrderInfoState = {
	isFetching: false,
	reactId: undefined,
	orderinfoData: undefined,
	orderinfoResult: undefined,
};

export function orderInfoReducer(reactId: string) {
	const componentInitialState: IOrderInfoState = Object.assign({}, initialState, {
		reactId: reactId,
	});
	const orderInfoComponent = (
		state = componentInitialState,
		action: OrderInfoActionTypes
	): IOrderInfoState => {
		return orderInfo(state, action);
	};
	return orderInfoComponent;
}

const orderInfo = (state = initialState, action: OrderInfoActionTypes): IOrderInfoState => {
	if (action.reactId != state.reactId) {
		return state;
	}
	switch (action.type) {
		case OrderInfoActions.REQUEST_ORDERINFO_DATA: {
			return produce(state, (draftState) => {
				draftState.isFetching = true;
			});
		}

		case OrderInfoActions.RECEIVE_ORDERINFO_DATA: {
			const { result } = action;

			let newState = produce(state, (draftState) => {
				draftState.isFetching = false;
				draftState.orderinfoResult = result;
				if (result && result.json && !result.error) {
					let data: IOrderInfoData = result.json.data as IOrderInfoData;
					draftState.orderinfoData = data;
				}
			});

			return newState;
		}

		default:
			return state;
	}
};
