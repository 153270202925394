import React, { FC } from 'react';
import { objectStatus } from 'shared/libs/lib';
import useSettings from 'productblock/container/useSettings';
import ProductImageElement from 'shared/components/Image';
import { ISizes } from 'shared/types';
import ProductPrice from 'productblock/components/productPrice';
import ProductSale from './productSale';
import ProductSku from './productSku';
import { pushProductClick } from 'shared/libs/gaevents';
import { IProduct } from '../messages/messages';

interface OwnProps {
	reactId: string;
	product: IProduct;
}

interface StateProps {
	localizations: object;
}

type IProps = StateProps & OwnProps;

const ProductItem: FC<IProps> = (props) => {
	const { reactId, product } = props;
	const { localizations } = props;
	const { sku, name, link, sale, images, stock, behavior } = product;

	const productStatus = objectStatus({
		stock,
		behavior,
	});

	const { showMsrPrices, showPrices, showEOL } = useSettings();

	if (!showEOL && productStatus.isEOL) {
		return null;
	}

	const handleProductClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		pushProductClick(product, 'tweakwiseproductslister', 0);
		window.location.href = link;
	};

	return (
		<article
			data-sku={sku}
			className='list-item b0_12 b3_12 b4_06 b6_04'
			onClick={handleProductClick}
		>
			<div className='p'>
				<div className='tiles-img-wrap'>
					<ProductImageElement images={images} b1={ISizes.huge} />

					<ProductSale sale={sale} showMsrPrices={showMsrPrices} localizations={localizations} />
				</div>

				<div className='list-item-titlewrap'>
					<div className='title'>{name}</div>

					<div className='sku pb'>
						<ProductSku product={product} />
					</div>

					<div className='g ai-center'>
						<div className='b0_12'>
							<ProductPrice
								horizontal={true}
								prices={product.prices}
								productStatus={productStatus}
								sale={product.sale}
								showMsrPrices={showMsrPrices}
								showPrices={showPrices}
							/>
						</div>
					</div>
				</div>
			</div>
		</article>
	);
};

export default ProductItem;
