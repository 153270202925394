/**
 * REPORTS ACTIONS CONSTANTS
 */
import { IHTTPResult, ProgressStatus } from 'shared/types';
import { DirectOrderActions } from './types';

import fetch from 'isomorphic-fetch';
import { getResultOrRedirect, errorResult, uriEncodeFormObject } from 'shared/libs/urlhandling';
import { IOrderRemarks } from '../messages/messages';
import { Product } from 'directorder/objects/Product';
import Mapper from 'directorder/objects/Mapper';
import DirectOrder from 'directorder/objects/DirectOrder';

const delayAsync = (time) => {
	return new Promise((resolve) => {
		setTimeout(resolve, time);
	});
};

/* INIT */

function receiveDirectOrdersInit() {
	return {
		type: DirectOrderActions.RECEIVE_DIRECTORDERS_INIT,
	};
}

export function init() {
	return (dispatch: any, getState: any) => {
		dispatch(receiveDirectOrdersInit());
	};
}

/* GET DIRECT ORDER LINE */

function requestDirectOrderLine(orderID: string) {
	return {
		type: DirectOrderActions.REQUEST_DIRECTORDERLINE,
		orderID,
	};
}

function receiveDirectOrderLine(result: IHTTPResult, orderID: string, orders: DirectOrder[]) {
	return {
		type: DirectOrderActions.RECEIVE_DIRECTORDERLINE,
		result,
		orderID,
		orders,
	};
}

const getDirectOrderLinePromise = (orderID: string, domainName: string, getState: any) => {
	const { extensions, synchronizerToken } = getState();
	let url = extensions.DirectOrdersUrl;

	let body = 'getDirectOrderLine=getDirectOrderLine';
	body += '&SynchronizerToken=' + synchronizerToken;
	body += '&OrderID=' + orderID;
	body += '&DomainName=' + domainName;

	return fetch(`${url}`, {
		method: 'post',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: body,
	}).then(getResultOrRedirect);
};

export function fetchDirectOrderLine(orderID: string, domainName: string) {
	return (dispatch: any, getState: any) => {
		dispatch(requestDirectOrderLine(orderID));

		return getDirectOrderLinePromise(orderID, domainName, getState)
			.then((postResult: IHTTPResult) => {
				if (!postResult?.json) {
					throw new Error();
				}
				let orders: DirectOrder[] = Mapper.getDirectOrders(postResult.json);
				dispatch(receiveDirectOrderLine(postResult, orderID, orders));
			})
			.catch((error: any) => dispatch(receiveFinishDirectOrder(errorResult, orderID, [])));
	};
}

/* DELIVER FROM OWN SUPPLY */

function requestDeliverFromOwnSupply(orderID: string) {
	return {
		type: DirectOrderActions.REQUEST_DELIVERFROMOWNSUPPLY,
		orderID,
	};
}

function receiveDeliverFromOwnSupply(result: IHTTPResult, orderID: string, orders: DirectOrder[]) {
	return {
		type: DirectOrderActions.RECEIVE_DELIVERFROMOWNSUPPLY,
		result,
		orderID,
		orders,
	};
}

const getDeliverFromOwnSupplyPromise = (orderID: string, domainName: string, getState: any) => {
	const { extensions, synchronizerToken } = getState();
	let url = extensions.DirectOrdersUrl;

	let body = 'deliverFromOwnSupply=deliverFromOwnSupply';
	body += '&SynchronizerToken=' + synchronizerToken;
	body += '&OrderID=' + orderID;
	body += '&DomainName=' + domainName;

	return fetch(`${url}`, {
		method: 'post',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: body,
	}).then(getResultOrRedirect);
};

export function deliverFromOwnSupply(
	orderID: string,
	domainName: string,
	userName: string,
	remark: string
) {
	return (dispatch: any, getState: any) => {
		dispatch(updateAddRemark('waiting'));

		return (
			Promise.resolve()
				//add remark
				.then(() => dispatch(requestAddRemark()))
				.then(() => getAddOrderRemarkPromise(orderID, domainName, userName, remark, getState))
				.then((postResult: IHTTPResult) => {
					if (!postResult?.json || postResult.error) {
						throw new Error();
					}
					let remarks: IOrderRemarks = postResult?.json?.data as IOrderRemarks;
					dispatch(receiveAddRemark(orderID, postResult, remarks));
				})
				//update order
				.then(() => dispatch(requestDeliverFromOwnSupply(orderID)))
				.then(() => getDeliverFromOwnSupplyPromise(orderID, domainName, getState))
				.then((postResult: IHTTPResult) => {
					if (!postResult?.json || postResult.error) {
						throw new Error();
					}
					let orders: DirectOrder[] = Mapper.getDirectOrders(postResult.json);
					dispatch(receiveDeliverFromOwnSupply(postResult, orderID, orders));
				})
				.then(() => dispatch(updateAddRemark('success')))
				.then(() => delayAsync(2000))
				.then(() => dispatch(updateAddRemark('none')))
				.catch((error: any) => {
					Promise.resolve()
						.then(() => {
							dispatch(updateAddRemark('error'));
							dispatch(receiveDeliverFromOwnSupply(errorResult, orderID, []));
							dispatch(receiveAddRemark(orderID, errorResult));
						})
						.then(() => delayAsync(2000))
						.then(() => dispatch(updateAddRemark('none')));
				})
		);
	};
}

/* FINISH ORDER */

function requestFinishDirectOrder(orderID: string) {
	return {
		type: DirectOrderActions.REQUEST_FINISHDIRECTORDER,
		orderID,
	};
}

function receiveFinishDirectOrder(result: IHTTPResult, orderID: string, orders: DirectOrder[]) {
	return {
		type: DirectOrderActions.RECEIVE_FINISHDIRECTORDER,
		result,
		orderID,
		orders,
	};
}

const getFinishDirectOrderPromise = (
	orderID: string,
	domainName: string,
	deliverydate: string,
	getState: any
) => {
	const { extensions, synchronizerToken } = getState();
	let url = extensions.DirectOrdersUrl;

	let bodyObject = {};
	bodyObject['finishOrder'] = 'finishOrder';
	bodyObject['SynchronizerToken'] = synchronizerToken;
	bodyObject['OrderID'] = orderID;
	bodyObject['DomainName'] = domainName;
	bodyObject['DealerDeliveryDate_DeliveryDate'] = deliverydate;
	let bodyString = uriEncodeFormObject(bodyObject);

	return fetch(`${url}`, {
		method: 'post',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: bodyString,
	}).then(getResultOrRedirect);
};

export function finishDirectOrder(
	orderID: string,
	domainName: string,
	userName: string,
	remark: string,
	deliverydate: string
) {
	return (dispatch: any, getState: any) => {
		dispatch(updateAddRemark('waiting'));

		return (
			Promise.resolve()
				//add remark
				.then(() => dispatch(requestAddRemark()))
				.then(() => getAddOrderRemarkPromise(orderID, domainName, userName, remark, getState))
				.then((postResult: IHTTPResult) => {
					if (!postResult?.json || postResult.error) {
						throw new Error();
					}
					let remarks: IOrderRemarks = postResult?.json?.data as IOrderRemarks;
					dispatch(receiveAddRemark(orderID, postResult, remarks));
				})
				//update order
				.then(() => dispatch(requestFinishDirectOrder(orderID)))
				.then(() => getFinishDirectOrderPromise(orderID, domainName, deliverydate, getState))
				.then((postResult: IHTTPResult) => {
					if (!postResult?.json || postResult.error) {
						throw new Error();
					}
					let orders: DirectOrder[] = Mapper.getDirectOrders(postResult.json);
					dispatch(receiveFinishDirectOrder(postResult, orderID, orders));
				})
				.then(() => dispatch(updateAddRemark('success')))
				.then(() => delayAsync(2000))
				.then(() => dispatch(updateAddRemark('none')))
				.catch((error: any) => {
					Promise.resolve()
						.then(() => {
							dispatch(updateAddRemark('error'));
							dispatch(receiveFinishDirectOrder(errorResult, orderID, []));
							dispatch(receiveAddRemark(orderID, errorResult));
						})
						.then(() => delayAsync(2000))
						.then(() => dispatch(updateAddRemark('none')));
				})
		);
	};
}

/* DO NOT DELIVER */

function requestDoNotDeliver(orderID: string) {
	return {
		type: DirectOrderActions.REQUEST_DONOTDELIVER,
		orderID,
	};
}

function receiveDoNotDeliver(result: IHTTPResult, orderID: string, orders: DirectOrder[]) {
	return {
		type: DirectOrderActions.RECEIVE_DONOTDELIVER,
		result,
		orderID,
		orders,
	};
}

export function doNotDeliver(orderID: string, domainName: string) {
	return (dispatch: any, getState: any) => {
		dispatch(requestDoNotDeliver(orderID));
		const { extensions, synchronizerToken } = getState();
		let url = extensions.DirectOrdersUrl;

		let body = 'doNotDeliver=doNotDeliver';
		body += '&SynchronizerToken=' + synchronizerToken;
		body += '&OrderID=' + orderID;
		body += '&DomainName=' + domainName;

		let postResult: IHTTPResult = {
			json: undefined,
			status: 0,
			error: true,
		};

		return fetch(`${url}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getResultOrRedirect)
			.then((postResult: IHTTPResult) => {
				if (!postResult?.json || postResult.error) {
					throw new Error();
				}
				let orders: DirectOrder[] = Mapper.getDirectOrders(postResult.json);
				dispatch(receiveDoNotDeliver(postResult, orderID, orders));
			})
			.catch((error: any) => dispatch(receiveDoNotDeliver(postResult, orderID, [])));
	};
}

/* ADD DIRECT ORDER LINE PRODUCTS TO BASKET */

function requestAddOrderToBasket(orderID: string) {
	return {
		type: DirectOrderActions.REQUEST_ADDORDERTOBASKET,
		orderID,
	};
}

function receiveAddOrderToBasket(result: IHTTPResult, orderID: string, orders: DirectOrder[]) {
	return {
		type: DirectOrderActions.RECEIVE_ADDORDERTOBASKET,
		result,
		orderID,
		orders,
	};
}

const getAddOrderToBasketPromise = (orderID: string, domainName: string, getState: any) => {
	const { extensions, synchronizerToken } = getState();
	let url = extensions.DirectOrdersUrl;

	let body = 'addOrderToBasket=addOrderToBasket';
	body += '&SynchronizerToken=' + synchronizerToken;
	body += '&OrderID=' + orderID;
	body += '&DomainName=' + domainName;

	return fetch(`${url}`, {
		method: 'post',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: body,
	}).then(getResultOrRedirect);
};

export function addOrderToBasket(
	orderID: string,
	domainName: string,
	userName: string,
	remark: string
) {
	return (dispatch: any, getState: any) => {
		dispatch(updateAddRemark('waiting'));

		return (
			Promise.resolve()
				//add remark
				.then(() => dispatch(requestAddRemark()))
				.then(() => getAddOrderRemarkPromise(orderID, domainName, userName, remark, getState))
				.then((postResult: IHTTPResult) => {
					if (!postResult?.json || postResult.error) {
						throw new Error();
					}
					let remarks: IOrderRemarks = postResult?.json?.data as IOrderRemarks;
					dispatch(receiveAddRemark(orderID, postResult, remarks));
				})
				//addtobasket / updateorder
				.then(() => dispatch(requestAddOrderToBasket(orderID)))
				.then(() => getAddOrderToBasketPromise(orderID, domainName, getState))
				.then((postResult: IHTTPResult) => {
					if (!postResult?.json || postResult.error) {
						throw new Error();
					}
					let orders: DirectOrder[] = Mapper.getDirectOrders(postResult.json);
					dispatch(receiveAddOrderToBasket(postResult, orderID, orders));
				})
				.then(() => dispatch(updateAddRemark('success')))
				.then(() => delayAsync(2000))
				.then(() => dispatch(updateAddRemark('none')))
				.catch((error: any) => {
					Promise.resolve()
						.then(() => {
							dispatch(updateAddRemark('error'));
							dispatch(receiveAddOrderToBasket(errorResult, orderID, []));
							dispatch(receiveAddRemark(orderID, errorResult));
						})
						.then(() => delayAsync(2000))
						.then(() => dispatch(updateAddRemark('none')));
				})
		);
	};
}

/* FETCH PRODUCTS */

function requestFetchProducts(orderID: string, skus: string[]) {
	return {
		type: DirectOrderActions.REQUEST_FETCHPRODUCTS,
		orderID,
		skus,
	};
}

function receiveFetchProducts(
	orderID: string,
	skus: string[],
	result: IHTTPResult,
	products: Product[]
) {
	return {
		type: DirectOrderActions.RECEIVE_FETCHPRODUCTS,
		orderID,
		skus,
		result,
		products,
	};
}

export function fetchProducts(orderID: string, skus: string[]) {
	return (dispatch: any, getState: any) => {
		dispatch(requestFetchProducts(orderID, skus));

		const { extensions, synchronizerToken } = getState();
		let url = extensions.ProductContentUrl;
		let skuData = skus.reduce((accumulator, currentValue) => accumulator + ',' + currentValue);

		let body = '';
		body += 'SKUS=' + skuData;
		body += '&SynchronizerToken=' + synchronizerToken;

		//body += '&DataFlag=AddBasketQuantity';
		//body += '&DataFlag=AddDropshipmentQuantity';
		//body += '&DataFlag=AddVariantProducts';
		body += '&DataFlag=AddPermissions';
		//body += '&DataFlag=AddFavorites';
		body += '&DataFlag=AddStock';
		//body += '&DataFlag=AddBackorder';
		body += '&DataFlag=AddPrice';
		//body += '&DataFlag=AddExtraImages';

		let result: IHTTPResult = {
			json: undefined,
			status: 0,
			error: true,
		};

		return fetch(`${url}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response: any) => {
				result.status = response.status;
				if (response.status == 200) {
					result.error = false;
					return response.json();
				} else {
					throw new Error('products not found');
				}
			})
			.then((data: any) => {
				result.json = data;
				return Mapper.getProducts(data);
			})
			.then((data: Product[]) => dispatch(receiveFetchProducts(orderID, skus, result, data)))
			.catch((error: string) => dispatch(receiveFetchProducts(orderID, skus, result, [])));
	};
}

/* FETCHREMARKS */

function requestFetchRemarks(orderID: string) {
	return {
		type: DirectOrderActions.REQUEST_FETCHREMARKS,
		orderID,
	};
}

function receiveFetchRemarks(orderID: string, result: IHTTPResult, remarks?: IOrderRemarks) {
	return {
		type: DirectOrderActions.RECEIVE_FETCHREMARKS,
		orderID,
		result,
		remarks,
	};
}

export function fetchRemarks(orderID: string) {
	return (dispatch: any, getState: any) => {
		dispatch(requestFetchRemarks(orderID));
		const { extensions, synchronizerToken } = getState();
		let url = extensions.DirectOrdersUrl;

		let body = 'getOrderRemarks=getOrderRemarks';
		body += '&SynchronizerToken=' + synchronizerToken;
		body += '&OrderID=' + orderID;
		//body += '&DomainName=' + domainName;

		return fetch(`${url}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getResultOrRedirect)
			.then((postResult: IHTTPResult) => {
				let remarks: IOrderRemarks = postResult?.json?.data as IOrderRemarks;
				dispatch(receiveFetchRemarks(orderID, postResult, remarks));
			})
			.catch((error: any) => dispatch(receiveFetchRemarks(orderID, errorResult)));
	};
}

/* ADDREMARK */

const requestAddRemark = () => {
	return {
		type: DirectOrderActions.REQUEST_ADDREMARK,
	};
};

const updateAddRemark = (progress: ProgressStatus) => {
	return {
		type: DirectOrderActions.UPDATE_ADDREMARK,
		progress,
	};
};

const receiveAddRemark = (orderID: string, result?: IHTTPResult, remarks?: IOrderRemarks) => {
	return {
		type: DirectOrderActions.RECEIVE_ADDREMARK,
		orderID,
		result,
		remarks,
	};
};

const getAddOrderRemarkPromise = (
	orderID: string,
	domainName: string,
	userName: string,
	remark: string,
	getState: any
) => {
	const { extensions, synchronizerToken } = getState();
	let url = extensions.DirectOrdersUrl;

	let bodyObject = {};
	bodyObject['addOrderRemark'] = 'addOrderRemark';
	bodyObject['SynchronizerToken'] = synchronizerToken;
	bodyObject['OrderID'] = orderID;
	bodyObject['AddOrderRemark_UserName'] = userName;
	bodyObject['AddOrderRemark_Remark'] = remark;
	//body += '&DomainName=' + domainName;
	let bodyString = uriEncodeFormObject(bodyObject);

	return fetch(`${url}`, {
		method: 'post',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: bodyString,
	}).then(getResultOrRedirect);
};

export function addOrderRemark(
	orderID: string,
	domainName: string,
	userName: string,
	remark: string
) {
	return (dispatch: any, getState: any) => {
		dispatch(requestAddRemark());
		dispatch(updateAddRemark('waiting'));

		return (
			Promise.resolve()
				.then(() => getAddOrderRemarkPromise(orderID, domainName, userName, remark, getState))
				.then((postResult: IHTTPResult) => {
					if (!postResult?.json || postResult.error) {
						throw new Error();
					}
					let remarks: IOrderRemarks = postResult?.json?.data as IOrderRemarks;
					dispatch(receiveAddRemark(orderID, postResult, remarks));
				})
				//update order
				.then(() => dispatch(requestDirectOrderLine(orderID)))
				.then(() => getDirectOrderLinePromise(orderID, domainName, getState))
				.then((postResult: IHTTPResult) => {
					if (!postResult?.json || postResult.error) {
						throw new Error();
					}
					let orders: DirectOrder[] = Mapper.getDirectOrders(postResult.json);
					dispatch(receiveDirectOrderLine(postResult, orderID, orders));
				})
				.then(() => dispatch(updateAddRemark('success')))
				.then(() => delayAsync(2000))
				.then(() => dispatch(updateAddRemark('none')))
				.catch((error: any) => {
					Promise.resolve()
						.then(() => {
							dispatch(receiveAddRemark(orderID, errorResult));
							dispatch(updateAddRemark('error'));
						})
						.then(() => delayAsync(2000))
						.then(() => dispatch(updateAddRemark('none')));
				})
		);
	};
}
