import fetch from 'isomorphic-fetch';
import { IHTTPResult } from 'returnorders/types';
import { getJsonOrRedirect } from 'shared/libs/urlhandling';
import { MainMenuActions } from 'shared/general/headermainmenu/types';
import { IHeaderMainMenu } from 'shared/messages/Messages';
import Mapper from 'shared/objects/Mapper';

/* GET MAINMENU */

function requestMainMenu(customerNo: string) {
	return {
		type: MainMenuActions.REQUEST_MAINMENU,
		customerNo,
	};
}

function receiveMainMenu(
	customerNo: string,
	result: IHTTPResult | null,
	menuData: IHeaderMainMenu | null
) {
	return {
		type: MainMenuActions.RECEIVE_MAINMENU,
		customerNo,
		result,
		menuData,
	};
}

export function fetchMainMenu(customerNo: string) {
	return (dispatch: any, getState: any) => {
		dispatch(requestMainMenu(customerNo));

		const { extensions, general, synchronizerToken } = getState();
		let url = `${extensions.MainMenuUrl}?CacheKey=${customerNo}`;

		let httpResult: IHTTPResult = {
			json: {},
			status: 0,
			error: true,
		};

		Promise.resolve()
			//test error
			//.then(()=> {throw new Error("test error");})
			.then(() =>
				fetch(url, {
					method: 'get',
					credentials: 'same-origin',
				})
			)
			.then((response: Response) => {
				httpResult.status = response.status;
				if (response.status == 200) {
					httpResult.error = false;
					return response.json();
				} else {
					throw new Error('mainmenu not found');
				}
			})
			.then((json: object) => {
				httpResult.json = json;
				let menuData: IHeaderMainMenu | null = json as IHeaderMainMenu;
				return menuData;
			})
			.then((menuData: IHeaderMainMenu | null) => {
				dispatch(receiveMainMenu(customerNo, httpResult, menuData));
			})
			.catch((error: string) => dispatch(receiveMainMenu(customerNo, httpResult, null)));
	};
}

/* END MAINMENU */
