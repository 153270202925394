import React, { FC} from 'react';
import ProductImageElement from 'shared/components/Image';
import { DirectOrderPrice } from './directOrderPrice';
import DirectOrderLine from 'directorder/objects/DirectOrderLine';
import { IImage } from 'wishlist/types';
import Notify from 'returnorders/forms/notify';
import { ISizes } from 'shared/types';

interface OwnProps {
	orderLine: DirectOrderLine;
	localizations: object;
}

interface StateProps {}

interface DispatchProps {}

type IProps = StateProps & DispatchProps & OwnProps;

export const DirectOrderLineItem: FC<IProps> = (props) => {
	const { orderLine, localizations } = props;
	const { product } = orderLine;

	const notFoundImage: IImage[] = [
		{
			altText: 'not found',
			imageLink:
				'/INTERSHOP/static/WFS/kruitbosch-kruitboschb2b-Site/-/kruitbosch/nl_NL/not_available_500x500.png',
			name: '79x79',
		},
	];

	return (
		<article className='g gutter-none ai-center list-item do-item-list-detail no-hover border bgcolor-color01'>
			<div className='b0_03 b3_06 b4_02 b5_01 img-wrap'>
				<ProductImageElement
					images={orderLine.isLoaded && product ? product.images : notFoundImage}
					b1={ISizes.tiny}
				/>
			</div>
			<div className='b0_09 b3_12 b4_14 b5_15'>
				<div className='list-item-titlewrap'>
					<div className='g gutter-none ai-center'>
						<div className='b0_12 as-flex-start'>
							{orderLine.isLoaded && product ? <strong>{product.name}</strong> : orderLine.sku}
						</div>
						<div className='b0_12'>
							<small className='color-color06'>{orderLine.sku}</small>
						</div>
					</div>
				</div>
			</div>
			<div className='b0_09 b1_push_03 b3_06 b3_push_none b4_08 b5_08 ta-right-b3 prices pr'>
				{orderLine.isLoaded && product ? (
					<DirectOrderPrice price={orderLine.price} />
				) : (
					<Notify
						state='disabled'
						message={localizations['common.account.directorder.product.notavailable']}
					/>
				)}
			</div>
		</article>
	);
};

export default DirectOrderLineItem;
