import { IHTTPResult, IProgressResult, ProgressStatus } from 'productblock/types';
import { IProduct } from 'productblock/messages/messages';

export interface IProductBlockState {
	isFetching: boolean;
	productResult?: IHTTPResult;
	products: IProduct[];
	addToBasketResult?: IProgressResult;
	reactId: string | undefined;
}

export enum ProductActions {
	REQUEST_PRODUCT = 'REQUEST_PRODUCT',
	RECEIVE_PRODUCT = 'RECEIVE_PRODUCT',

	REQUEST_ADDTOBASKET = 'REQUEST_ADDTOBASKET',
	UPDATE_ADDTOBASKET = 'UPDATE_ADDTOBASKET',
	RECEIVE_ADDTOBASKET = 'RECEIVE_ADDTOBASKET',

	REQUEST_FAVORITEPRODUCT = 'REQUEST_FAVORITEPRODUCT',
	RECEIVE_FAVORITEPRODUCT = 'RECEIVE_FAVORITEPRODUCT',
}

interface ProductBlockAction {
	reactId: string;
}

interface RequestProduct extends ProductBlockAction {
	type: typeof ProductActions.REQUEST_PRODUCT;
	skus: string[];
}

interface RecieveProduct extends ProductBlockAction {
	type: typeof ProductActions.RECEIVE_PRODUCT;
	skus: string[];
	productResult?: IHTTPResult;
	products: IProduct[];
}

interface RequestAddToBasket extends ProductBlockAction {
	type: typeof ProductActions.REQUEST_ADDTOBASKET;
	sku: string;
	quantity: number;
	status: ProgressStatus;
}

interface UpdateAddToBasket extends ProductBlockAction {
	type: typeof ProductActions.UPDATE_ADDTOBASKET;
	sku: string;
	status: ProgressStatus;
}

interface RecieveAddToBasket extends ProductBlockAction {
	type: typeof ProductActions.RECEIVE_ADDTOBASKET;
	sku: string;
	quantity: number;
	status: ProgressStatus;
}

interface RequestFavoriteProduct extends ProductBlockAction {
	type: typeof ProductActions.REQUEST_FAVORITEPRODUCT;
	sku: string;
}

interface RecieveFavoriteProduct extends ProductBlockAction {
	type: typeof ProductActions.RECEIVE_FAVORITEPRODUCT;
	sku: string;
	favorite: boolean;
	postResult?: IHTTPResult;
}

export type ProductActionTypes =
	| RequestFavoriteProduct
	| RecieveFavoriteProduct
	| RequestProduct
	| RecieveProduct
	| RequestAddToBasket
	| UpdateAddToBasket
	| RecieveAddToBasket;
