import React, { FC, useEffect } from 'react';
import { IProductBlockState } from 'productblock/container/types';
import { IData } from 'productblock/types';
import ProductContainer from 'productblock/container/productContainer';

//import { connect } from 'react-redux';
//import { fetchProduct } from 'shared/general/actions';

interface OwnProps {
	reactId: string;
}

interface StateProps {
	list: IProductBlockState;
	data: IData;
}

interface DispatchProps {
	fetchProduct: any;
}

type IProps = StateProps & DispatchProps & OwnProps;

const ProductBlock: FC<IProps> = (props) => {
	useEffect(() => {
		const { data, reactId } = props;
		const { skus } = data;
		props.fetchProduct(reactId, skus);
	}, []);

	const { data, list } = props;
	const { products, isFetching } = list;
	const { title, subTitle, buttonText, buttonLink, hideEOLProducts } = data;

	return (
		<div className='container'>
			{title && <h2 className='head head1 mb'>{title}</h2>}
			{subTitle && <h3 className='head5 mb-2x'>{subTitle}</h3>}
			<ProductContainer reactId={props.reactId} hideEOLProducts={hideEOLProducts} />
			{buttonText && buttonText !== '' && buttonLink && buttonLink !== '' && (
				<a href={buttonLink} className='btn mt-3x'>
					{buttonText}
				</a>
			)}
		</div>
	);
};

import createConnect from 'productblock/container/connect';
const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(ProductBlock);