import { IHTTPResult } from 'shared/types';
import { IProduct } from 'shared/messages/Messages';
import { EProductType, ESortOption } from 'wishlist/types';

export enum WishListActions {
	REQUEST_REMOVEFROMWISHLIST = 'REQUEST_REMOVEFROMWISHLIST',
	RECEIVE_REMOVEFROMWISHLIST = 'RECEIVE_REMOVEFROMWISHLIST',

	REQUEST_GETFREQUENTLYBOUGHT = 'REQUEST_GETFREQUENTLYBOUGHT',
	RECEIVE_GETFREQUENTLYBOUGHT = 'RECEIVE_GETFREQUENTLYBOUGHT',

	UPDATE_SORTFREQUENTLYBOUGHT = 'UPDATE_SORTFREQUENTLYBOUGHT',
}

interface RequestRemoveAction {
	type: typeof WishListActions.REQUEST_REMOVEFROMWISHLIST;
	product: IProduct;
}

interface RecieveRemoveAction {
	type: typeof WishListActions.RECEIVE_REMOVEFROMWISHLIST;
	product: IProduct;
	json;
}

interface RequestFrequentlyBoughtAction {
	type: typeof WishListActions.REQUEST_GETFREQUENTLYBOUGHT;
}

interface RecieveFrequentlyBoughtAction {
	type: typeof WishListActions.RECEIVE_GETFREQUENTLYBOUGHT;
	response: IHTTPResult;
}

interface UpdateSortFrequentlyBought {
	type: typeof WishListActions.UPDATE_SORTFREQUENTLYBOUGHT;
	option: ESortOption;
}

export type WishListActionsTypes =
	| RequestRemoveAction
	| RecieveRemoveAction
	| RequestFrequentlyBoughtAction
	| RecieveFrequentlyBoughtAction
	| UpdateSortFrequentlyBought;
