import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as searchActions from 'shared/general/searchbar/actions';

function createConnect<StateProps, DispatchProps, OwnProps>() {
	const mapStateToProps = (state: any, ownProps: any): any => {
		let initialState = {
			localizations: state.localizations,
			extensions: state.extensions,
			general: state.general,
			searchbar: state.searchbar,
		};

		if (ownProps.reactId) {
			//data-react-data attributes are gathered in a single object for all components, map the state back to this specific component
			initialState = Object.assign(initialState, {
				synchronizerToken: state.synchronizer[ownProps.reactId],
			});
		}
		return initialState;
	};

	const mapDispatchProps = (dispatch: any): any => {
		const actions = {
			...searchActions,
		};
		return bindActionCreators(actions, dispatch);
	};

	const connectContext = connect<StateProps, DispatchProps, OwnProps>(
		mapStateToProps,
		mapDispatchProps
	);
	return connectContext;
}

export default createConnect;

// function mapStateToProps(state) {
// 	return {
// 		notifications: state.notifications,
// 		general: state.general,
// 		localizations: state.localizations,
// 		extensions: state.extensions,
// 	};
// }

// export default connect(mapStateToProps, { fetchNotications })(NotificationBar);
