import React, { useState } from 'react';
import { useHoverIntent } from 'react-use-hoverintent';

interface OwnProps {
	classes: string;
	children: any;
	dataAttr?: any;
}

type IProps = OwnProps;

const MenuItemWrap: React.FC<IProps> = ({ classes, children, dataAttr }) => {
	const [isHovering, ref]: any = useHoverIntent({
		timeout: 100,
		sensitivity: 1,
		interval: 100,
	});
	const [open, setOpen] = useState(false);

	const openMenuItem = (e) => {
		setOpen(!open);
	};
	const handleHoverOff = (e) => {
		setOpen(false);
	};

	return (
		<li
			ref={ref}
			className={`${classes} ${isHovering ? 'hover' : ''} ${open ? 'isopen' : ''}`}
			{...dataAttr}
			onClick={openMenuItem}
			onMouseLeave={handleHoverOff}
		>
			{children}
		</li>
	);
};
export default MenuItemWrap;
