export class Product {
	public constructor(init?: Partial<Product>) {
		Object.assign(this, init);
	}
	sku: string = '';
	uuid: string = '';
	name: string = '';
	description: string = '';
	sale: boolean = false;
	prices: ProductPrice[] = [];
	images: ProductImage[] = [];
	productQuantity: ProductQuantity = new ProductQuantity();
	productUrl: string = '';
	defaultCategory: string = '';
}

export class ProductPrice {
	public constructor(init?: Partial<ProductPrice>) {
		Object.assign(this, init);
	}
	name: string = '';
	whole: string = '';
	fraction: string = '';
	discountPercentage: number = 0;
	quantityFrom: number = 0;
}

export class ProductQuantity {
	public constructor(init?: Partial<ProductQuantity>) {
		Object.assign(this, init);
	}

	quantity: number = 0;
	quantityInnerPackage: number = 0;
	quantityOuterPackage: number = 0;
	showQuantityWarningPopup: boolean = false;
}

export class ProductImage {
	public constructor(init?: Partial<ProductImage>) {
		Object.assign(this, init);
	}

	altText: string = '';
	imageLink: string = '';
	name: string = '';
}
