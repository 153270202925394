import React, { Fragment, ReactNode } from 'react';
import Alert from 'returnorders/forms/alert';
import Button from 'shared/formelements/button';
import Box from 'returnorders/components/box/box';
import LoadingElement from 'shared/components/LoadingElement';

interface OwnProps {
	children: ReactNode;
}

interface StateProps {
	localizations: object;
}

interface DispatchProps {}

type IProps = StateProps & DispatchProps & OwnProps;

interface IState {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidUpdate = (prevProps: IProps, prevState: IState): any => {
		if (prevState.hasError && this.state.hasError && prevProps.children != this.props.children) {
			this.onTryAgain();
		}
	};

	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
	}

	onTryAgain() {
		this.setState({ hasError: false });
	}

	render() {
		if (this.state.hasError) {
			return <Fragment></Fragment>;
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
