import services from 'services/gatagmanager';
import moment from 'moment';

export function pushUpdateToFavorite(product, isFavorite) {
	var price = getPrice(product);

	var brand = null;
	if (product.brand) {
		brand = product.brand;
	}

	var defaultCategory = null;
	if (product.defaultCategory) {
		defaultCategory = product.defaultCategory;
	}

	var variant = getVariant(product);

	var products = [
		{
			name: product.name,
			id: product.sku,
			price: price,
			brand: brand,
			category: defaultCategory,
			variant: variant,
			quantity: 1,
		},
	];

	if (isFavorite) {
		pushAddToFavorite(products);
	} else {
		pushRemoveToFavorite(products);
	}
}

function pushAddToFavorite(products) {
	var data = {
		event: 'addToFavorite',
		ecommerce: {
			addToFavorite: {
				products: products,
			},
		},
	};

	services.gatagmanager.push(data);
}

function pushRemoveToFavorite(products) {
	var data = {
		event: 'removeFromFavorite',
		ecommerce: {
			removeFromFavorite: {
				products: products,
			},
		},
	};

	services.gatagmanager.push(data);
}

export function pushProductClick(product, list, position) {
	var price = getPrice(product);

	var brand = null;
	if (product.brand) {
		brand = product.brand;
	}

	var defaultCategory = null;
	if (product.defaultCategory) {
		defaultCategory = product.defaultCategory;
	}

	var variant = getVariant(product);

	var impressions = [
		{
			name: product.name,
			id: product.sku,
			price: price,
			brand: brand,
			category: defaultCategory,
			variant: variant,
			position: position,
		},
	];

	var data = {
		event: 'productClick',
		ecommerce: {
			click: {
				actionField: {
					list: list,
				},
				products: impressions,
			},
		},
	};

	services.gatagmanager.push(data);
}

export function pushProductImpressions(products, list, extra) {
	var startIndex = 1;
	var impressions = [];
	var position = startIndex;

	for (var i = 0; i < products.length; i++) {
		var product = products[i];

		var price = getPrice(product);

		var brand = null;
		if (product.brand) {
			brand = product.brand;
		}

		var defaultCategory = null;
		if (product.defaultCategory) {
			defaultCategory = product.defaultCategory;
		}

		var variant = getVariant(product);

		var impression = {
			name: product.name,
			id: product.sku,
			price: price,
			brand: brand,
			category: defaultCategory,
			variant: variant,
			list: list,
			position: position,
			...extra,
		};

		impressions.push(impression);

		position++;
	}

	if (impressions.length === 0) {
		return;
	}

	var data = {
		event: 'productImpression',
		ecommerce: {
			currencyCode: 'EUR',
			impressions: impressions,
		},
	};

	services.gatagmanager.push(data);
}

export function pushProductImpressionsIfLoaded(products, list) {
	var impressions = [];

	for (var i = 0; i < products.length; i++) {
		var product = products[i];

		//all products should be loaded
		if (product.isLoaded == null) {
			continue;
		}

		//tracking parents will cause duplicate impressions
		if (product.isParent) {
			continue;
		}

		var price = getPrice(product);

		var brand = null;
		if (product.brand) {
			brand = product.brand;
		}

		var defaultCategory = null;
		if (product.defaultCategory) {
			defaultCategory = product.defaultCategory;
		}

		var variant = getVariant(product);

		var position = product.index + 1;

		var impression = {
			name: product.name,
			id: product.sku,
			price: price,
			brand: brand,
			category: defaultCategory,
			variant: variant,
			list: list,
			position: position,
		};

		impressions.push(impression);
	}

	if (impressions.length === 0) {
		return;
	}

	var data = {
		event: 'productImpression',
		ecommerce: {
			currencyCode: 'EUR',
			impressions: impressions,
		},
	};

	services.gatagmanager.push(data);
}

export function pushProductSearch(state) {
	var hasResult = state.products.length > 0;
	var data = {
		event: 'siteSearch',
		searchResult: hasResult,
		searchTerm: state.search.searchTerm,
		authorisationId: state.authorisationIds,
	};

	services.gatagmanager.push(data);
}

export function pushUpdateFromBasket(product, newQuantity, list, extra) {
	var price = getPrice(product);

	var brand = null;
	if (product.brand) {
		brand = product.brand;
	}

	var defaultCategory = null;
	if (product.defaultCategory) {
		defaultCategory = product.defaultCategory;
	}

	var variant = getVariant(product);

	var changedQuantity = newQuantity - product.quantity;
	if (newQuantity === 0) {
		changedQuantity = product.quantity;
	} else if (newQuantity < product.quantity) {
		changedQuantity = product.quantity - newQuantity;
	}

	var products = [
		{
			name: product.name,
			id: product.sku,
			price: price,
			brand: brand,
			category: defaultCategory,
			variant: variant,
			quantity: changedQuantity,
			dimension18: product.isFavorite ? 'Ja' : 'Nee',
			...extra,
		},
	];

	if (newQuantity < product.quantity) {
		pushRemoveFromBasket(products, list);
	} else {
		pushAddToBasket(products, list);
	}
}

export function pushProductImageDownload(SKU) {
	var data = {
		event: 'downloadLink',
		productSku: SKU,
	};

	services.gatagmanager.push(data);
}

export function pushUpdateMessage(message, messageStatus) {
	switch (messageStatus) {
		case 'READ':
			pushMessageRead(message);
			break;

		default:
			break;
	}
}

export function pushMessageRead(message) {
	var data = {
		inboxMessageSend: formatDate(message.dateImported),
		inboxMessageRead: formatDate(Date.now()),
		inboxMessageId: message.id,
		event: 'berichtenbox',
	};

	services.gatagmanager.push(data);
}

export function pushMessageView(message) {
	var data = {
		inboxMessageView: formatDate(Date.now()),
		inboxMessageId: message.id,
		event: 'berichtenbox',
	};

	services.gatagmanager.push(data);
}

export function pushDirectOrderOrdering(directOrderNumber, directOrderDate, directOrderPlatform) {
	let orderDate = moment(directOrderDate, 'DD-MM-YYYY').format();
	var data = {
		orderDate: orderDate,
		orderDateAction: formatDate(Date.now()),
		orderNumber: directOrderNumber,
		platform: directOrderPlatform,
		orderAction: 'ordering',
		event: 'directorder',
	};

	services.gatagmanager.push(data);
}

export function pushDirectOrderCompleted(
	directOrderNumber,
	directOrderDate,
	directOrderDeliveryDate,
	directOrderPlatform
) {
	let orderDate = moment(directOrderDate, 'DD-MM-YYYY').format();
	let deliveryDate = moment(directOrderDeliveryDate, 'DD-MM-YYYY').format();
	var data = {
		orderDate: orderDate,
		deliveryDate: deliveryDate,
		orderDateAction: formatDate(Date.now()),
		orderNumber: directOrderNumber,
		platform: directOrderPlatform,
		orderAction: 'completed',
		event: 'directorder',
	};

	services.gatagmanager.push(data);
}

export function pushDirectOrderCancelled(directOrderNumber, directOrderDate, directOrderPlatform) {
	let orderDate = moment(directOrderDate, 'DD-MM-YYYY').format();
	var data = {
		orderDate: orderDate,
		orderDateAction: formatDate(Date.now()),
		orderNumber: directOrderNumber,
		platform: directOrderPlatform,
		orderAction: 'cancelled',
		event: 'directorder',
	};

	services.gatagmanager.push(data);
}

export function pushDocumentDownload(document, product) {
	var defaultCategory = null;
	if (product.defaultCategory) {
		defaultCategory = product.defaultCategory;
	}

	var data = {
		name: document.name,
		path: document.path,
		sku: product.sku,
		productCategory: defaultCategory,
		event: 'download',
	};

	services.gatagmanager.push(data);
}

export function pushPackingSlipDownload(packingSlipNumber) {
	var data = {
		name: packingSlipNumber,
		event: 'packingslip-download',
	};

	services.gatagmanager.push(data);
}

function pushAddToBasket(impressions, list) {
	var data = {
		event: 'addToCart',
		ecommerce: {
			currencyCode: 'EUR',
			add: {
				actionField: { list: list, action: 'add' },
				products: impressions,
			},
		},
	};

	services.gatagmanager.push(data);
}

function pushRemoveFromBasket(impressions, list) {
	var data = {
		event: 'removeFromCart',
		ecommerce: {
			currencyCode: 'EUR',
			remove: {
				actionField: { list: list, action: 'remove' },
				products: impressions,
			},
		},
	};

	services.gatagmanager.push(data);
}

function getPrice(product) {
	if (product.priceNet) {
		return product.priceNet.whole + '.' + product.priceNet.fraction;
	} else if (product.prices) {
		var price = product.prices.find(function(price) {
			return price.name === 'NettPrice';
		});
		if (!price) {
			price = product.prices.find(function(price) {
				return price.name === 'CostPrice';
			});
		}
		if (price) {
			return price.whole + '.' + price.fraction;
		}
	}

	return null;
}

function getVariant(product) {
	var variant = null;

	if (product.variantGroups) {
		for (
			var variantGroupIndex = 0;
			variantGroupIndex < product.variantGroups.length;
			variantGroupIndex++
		) {
			var variantGroup = product.variantGroups[variantGroupIndex];

			for (
				var variantIndex = 0;
				variantIndex < variantGroup.productVariants.length;
				variantIndex++
			) {
				var productVariant = variantGroup.productVariants[variantIndex];
				if (productVariant.selected) {
					variant = productVariant.name;
					break;
				}
			}

			if (variant) {
				break;
			}
		}
	}

	return variant;
}

function formatDate(date) {
	let messageDate = new Date(date);
	return moment(messageDate).format();
}
