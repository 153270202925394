import React, { FC, useEffect } from 'react';
import { ITweakwiseProductsState } from './types';
import { IProduct } from 'dashboard/tweakwiseproducts/messages/messages';
import ProductItem from './../components/productItem';
import ProductShadow from './../components/productShadow';
import useSettings from 'productblock/container/useSettings';
import { filterActiveProducts } from 'shared/libs/productshelper';

interface OwnProps {
	reactId: string;
	title: string;
	subTitle: string;
}

interface StateProps {
	list: ITweakwiseProductsState;
	localizations: object;
}

interface DispatchProps {
	fetchRecommendedProducts: any;
}

type IProps = StateProps & DispatchProps & OwnProps;

export const TweakwiseProductsContainer: FC<IProps> = (props) => {
	const { list, localizations } = props;
	const { title, subTitle } = props;
	const { products, isFetching } = list;

	const { showEOL, showPrices } = useSettings();
	const filteredProducts = filterActiveProducts(products, showEOL);

	useEffect(() => {
		// Tweakwise call includes EOL cookie
		props.fetchRecommendedProducts();
	}, [showEOL]);

	const headerElement = (
		<header className='widget-head border'>
			<div className=''>
				<h4 className='head head4 upper'>{!isFetching && title}</h4>
				<small className=''>{!isFetching && subTitle}</small>
			</div>
		</header>
	);

	return (
		<div>
			{isFetching && (
				<>
					{headerElement}
					<div className='widget '>
						<div className='content-subwrap tiles-view bgcolor-color01'>
							<section className='list g'>
								<ProductShadow showPrices={showPrices} />
								<ProductShadow showPrices={showPrices} />
								<ProductShadow showPrices={showPrices} />
								<ProductShadow showPrices={showPrices} />
								<ProductShadow showPrices={showPrices} />
								<ProductShadow showPrices={showPrices} />
							</section>
						</div>
					</div>
				</>
			)}
			{!isFetching && filteredProducts.length > 0 && (
				<>
					{headerElement}
					<div className='widget'>
						<div className='content-subwrap tiles-view bgcolor-color01 swipable p-0'>
							<section className='list tweakwise-list g g-equal-height '>
								{products.map((product: IProduct, index) => {
									return (
										<ProductItem
											reactId={props.reactId}
											key={index}
											product={product}
											localizations={localizations}
										/>
									);
								})}
							</section>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

import createConnect from './connect';
const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(TweakwiseProductsContainer);
