import React, { FC, useEffect, useState } from 'react';
import createConnect from 'shared/general/headermainmenu/connect';
import MenuItems from 'shared/general/headermainmenu/MenuItems';
import { IHeaderMainMenuState } from 'shared/general/headermainmenu/types';
import { IHeaderMainMenu } from 'shared/messages/Messages';
import { Subject, fromEvent, merge } from 'rxjs';
import { take } from 'rxjs/operators';
import MenuItemWrap from '../headermainmenu/MenuItemWrap';

interface OwnProps {
	reactId: string;
}

interface StateProps {
	localizations: object;
	general: object;
	extensions: any;
	headermainmenu: IHeaderMainMenuState;
}

interface DispatchProps {
	fetchMainMenu: Function;
}

type IProps = StateProps & DispatchProps & OwnProps;

const HeaderMainMenu: FC<IProps> = (props) => {
	const { localizations, headermainmenu, fetchMainMenu } = props;
	if (!headermainmenu) {
		return null;
	}
	const { menuData } = headermainmenu;
	if (!menuData) {
		return null;
	}

	const sparepartsUrl: string = props?.extensions?.SparePartsUrl;

	const getData = () => {
		let customerNumber = headermainmenu.menuData?.customerNumber;
		if (customerNumber) {
			let hash = window.btoa(customerNumber);
			fetchMainMenu(hash);
		}
	};

	useEffect(() => {
		//headerservicenav.js
		var source1 = fromEvent(
			document.querySelectorAll('header.header [data-navitem]'),
			'mouseenter'
		);
		//mainnav.js (for mobile)
		var source2 = fromEvent(document.querySelectorAll('#main-nav li[data-navitem]>a'), 'click');
		//combine both events and take the 1st event to load data
		var sources = merge(source1, source2);
		sources.pipe(take(1)).subscribe((e) => {
			getData();
		});
	}, []);

	return (
		<ul className='main-nav'>
			{menuData &&
				menuData.headerMainItems.map((menuItem, index) => {
					let cssClass = menuItem?.bike ? 'bikes' : menuItem?.part ? 'parts' : '';
					let hasSub = menuItem?.mainMenuItems ? 'has-sub' : '';

					if (menuItem.title === 'Pendlr') {
						return (
							<li key={`${index}-${menuItem.link}`} className='main-nav-item' id='pendlrButton'>
								<a href={menuItem.link}>{menuItem.title}</a>
							</li>
						);
					}

					return (
						<MenuItemWrap
							key={`${index}-${menuItem.link}`}
							classes={`main-nav-item ${cssClass} ${hasSub}`}
							dataAttr={{ 'data-navitem': true }}
						>
							<a data-sl='top-nav-link'>{menuItem.title}</a>
							<MenuItems
								mainMenuItems={menuItem.mainMenuItems}
								localizations={localizations}
								bike={menuItem?.bike}
								part={menuItem?.part}
								loaded={menuData.loaded}
								sparepartsUrl={sparepartsUrl}
							/>
						</MenuItemWrap>
					);
				})}
		</ul>
	);
};

const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(HeaderMainMenu);
