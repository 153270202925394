import React, { FC, Fragment, ReactNode } from 'react';
import { ProgressStatus, IProgressResult } from 'shared/types';
import Empty from 'shared/components/Empty';

interface IProps {
	//use status when you want to control when the icon is visible
	status?: ProgressStatus;

	//use result + resultKey to let Progress figure out when it should display
	result?: IProgressResult;
	resultKey?: string;
}

const Progress: FC<IProps> = (props) => {
	const { status, result, resultKey } = props;

	let progressStatus = status;
	if (result && resultKey && result.key === resultKey) {
		progressStatus = result.status;
	}

	let loader: ReactNode = null;
	if (progressStatus === 'error') {
		loader = <i className='icon-cancel error' data-sl='list-icon-cancel-error'></i>;
	} else if (progressStatus === 'success') {
		loader = <i className='icon-check success' data-sl='list-icon-check-success'></i>;
	} else if (progressStatus === 'waiting') {
		loader = <i className='icon-spin4 animate-spin'></i>;
	} else {
		return <Empty />;
	}

	return <div className='list-item-icon'>{loader}</div>;
};

export default Progress;
