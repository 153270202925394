import React, { FC } from 'react';
import OrderInfoContainer from 'dashboard/orderinfo/container/orderInfoContainer';
import { IOrderInfoState } from 'dashboard/orderinfo/types';
import createConnect from 'dashboard/orderinfo/container/connect';

interface OwnProps {
	reactId: string;
}

interface StateProps {
	orderinfo: IOrderInfoState;
}

interface DispatchProps {}

type IProps = StateProps & DispatchProps & OwnProps;

const OrderInfo: FC<IProps> = (props) => {
	const { reactId } = props;
	return (
		<>
			<OrderInfoContainer reactId={reactId} />
		</>
	);
};

const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(OrderInfo);
