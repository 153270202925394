import React, { useState, useEffect, useRef } from 'react';
import { PROGRESS_RESULT } from '../types';
import { animations } from 'react-animation';
import SubMenu from '../components/SubMenu';
import { connect } from 'react-redux';

const Person = (props) => {
	const { notifications, general } = props;
	const [open, setOpen] = useState(false);
	const name = 'person';
	const cssClass = `${name} ${open ? 'open' : ''}`;
	const wrapperRef = useRef(null);

	useEffect(() => {
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [wrapperRef]);

	const handleClickOutside = (e) => {
		if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
			setOpen(false);
		}
	};

	const message = {
		url: general.accountSubMenu.messages.url,
		name: general.accountSubMenu.messages.title,
		iconClass: 'icon-mail-thin',
		numberOfProducts: notifications.message.numberOfUnreadMessages,
	};
	const wishlist = {
		url: general.accountSubMenu.wishlist.url,
		name: general.accountSubMenu.wishlist.title,
		iconClass: 'icon-favorites-thin',
	};
	const mylists = {
		url: general.accountSubMenu.myLists.url,
		name: general.accountSubMenu.myLists.title,
		iconClass: 'icon-note-filled-thin',
	};
	const children = [message, wishlist, mylists];
	const style =
		message == null || message.status == null || message.status !== PROGRESS_RESULT.SUCCESS
			? null
			: {
					animation: animations.popIn,
			  };

	const showNotificationBalloon = message && message.numberOfProducts > 0;

	const onClick = (e) => {
		e.preventDefault();
		setOpen(!open);
	};

	return (
		<div className={cssClass} ref={wrapperRef}>
			<a href='#' data-sl={name} onClick={onClick}>
				{name === 'person' ? (
					<i className={`icon-${name}`} />
				) : (
					<i className={`icon-${name}-thin`} />
				)}
				{showNotificationBalloon && (
					<em className='sticker-amount' style={style}>
						<span className='nr'>{message.numberOfProducts}</span>
					</em>
				)}
			</a>
			{children && <SubMenu items={children} general={general} />}
		</div>
	);
};

function mapStateToProps(state) {
	return {
		notifications: state.notifications,
		general: state.general,
		extensions: state.extensions,
	};
}
export default connect(mapStateToProps)(Person);
