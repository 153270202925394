import React, { FC, useEffect, useState } from 'react';
import { DirectOrderLineItem } from './directOrderLineItem';
import { DirectOrderState } from './directOrderState';
import { DirectOrderPrice } from './directOrderPrice';
import { DirectOrderCustomerInfo } from './directOrderCustomerInfo';
import { Waypoint } from 'react-waypoint';
import createConnect from 'directorder/container/connect';
import DirectOrder from 'directorder/objects/DirectOrder';
import {
	EDirectOrderState,
	EPlatform,
	EProgressStatus,
	IDirectOrderState,
} from 'directorder/container/types';
import Alert from 'returnorders/forms/alert';
import { Portal } from 'react-portal';
import DirectOrderRemarkPopup, { EPopupType } from './directOrderRemarkPopup';
import DirectOrderRemarks from './directOrderRemarks';
import DirectOrderItemAlerts from './directOrderItemAlerts';
import DirectOrderItemDeliveryDate from './directOrderItemDeliveryDate';

interface OwnProps {
	order: DirectOrder;
	openOrder: string;
	setOpenOrder: Function;
}

interface StateProps {
	localizations: any;
	directorder: IDirectOrderState;
}

interface DispatchProps {
	finishDirectOrder: Function;
	addOrderToBasket: Function;
	doNotDeliver: Function;
	fetchProducts: Function;
	fetchRemarks: Function;
	addOrderRemark: Function;
	fetchDirectOrderLine: Function;
}

type IProps = StateProps & DispatchProps & OwnProps;

export const DirectOrderItem: FC<IProps> = (props) => {
	const { order, openOrder, setOpenOrder } = props;
	const { doNotDeliver, fetchProducts, fetchRemarks, fetchDirectOrderLine } = props;
	const { localizations, directorder } = props;
	const {
		fetchProductsResult,
		addToBasketResult,
		finishOrderResult,
		doNotDeliverResult,
		deliverFromOwnSupplyResult,
	} = directorder;
	const [readMoreOpen, setReadMoreOpen] = useState(false);

	const toggleReadMore = () => {
		setReadMoreOpen(!readMoreOpen);
	};

	useEffect(() => {
		if (order?.orderRemarks && order?.orderRemarks.orderRemarks.length < 2) {
			setReadMoreOpen(true);
		}
	}, [order.orderRemarks]);

	//toggle logic
	const open = openOrder === order.id;
	const toggleArticleClass = open ? 'open' : '';
	const toggleDetailClass = open ? 'show' : 'hide';
	const toggleIconClass = open ? 'up' : 'down';

	const onToggle = (e: any) => {
		e.preventDefault();
		setOpenOrder(open ? '' : order.id);
	};

	const onClickOrderAddToBasket = (e: any) => {
		e.preventDefault();
		openAddToBasketPopup();
	};

	const onClickDeliverFromOwnSupply = (e: any) => {
		e.preventDefault();
		openDeliveryFromOwnSupplyPopup();
	};

	const onClickFinishDirectOrder = (e: any) => {
		e.preventDefault();
		openFinishPopup();
	};

	const onClickDoNotDeliver = (e: any) => {
		e.preventDefault();
		doNotDeliver(order.id, order.platform);
	};

	const onClickAddRemark = (e: any) => {
		e.preventDefault();
		openAddRemarkPopup();
	};

	const onEnter = () => {
		if (!order.isProductsLoaded) {
			let skus = order.orderLines.map((product) => product.sku);
			fetchProducts(order.id, skus);
		}
		if (!order.isRemarksLoaded) {
			fetchRemarks(order.id);
		}
	};

	//popup logic
	const [popupType, setPopupType] = useState<EPopupType | false>(false);

	const openAddRemarkPopup = () => {
		setPopupType(EPopupType.ADD_REMARK);
	};

	const openAddToBasketPopup = () => {
		setPopupType(EPopupType.ADD_TO_BASKET);
	};

	const openDeliveryFromOwnSupplyPopup = () => {
		setPopupType(EPopupType.DELIVER_FROM_OWN_SUPPLY);
	};

	const openFinishPopup = () => {
		setPopupType(EPopupType.FINISH);
	};

	const closeRemarkPopup = () => {
		setPopupType(false);
	};

	const platform = order.getChannel();

	let icon: string;
	switch (order.platform) {
		case EPlatform.cortina_nl:
		case EPlatform.cortina_en:
		case EPlatform.cortina_de:
		case EPlatform.cortina_be:
			icon = 'icon-cortina';
			break;
		case EPlatform.alpina_nl:
			icon = 'icon-alpina';
			break;
		default:
			icon = '';
			break;
	}

	const isUnableToOrder =
		fetchProductsResult &&
		fetchProductsResult.json &&
		fetchProductsResult?.json?.errorMessages &&
		fetchProductsResult?.json?.errorMessages[0] === 'HANDLING_UNABLE_TO_ORDER';

	const reloadOrder = (e) => {
		e.preventDefault();
		fetchDirectOrderLine(order.id, order.platform);
	};

	return (
		<>
			<article className={`list-item do-item ${toggleArticleClass}`} onClick={onToggle}>
				<div className='g gutter-none'>
					<div className='b0_03 b4_02 b6_01'>
						<i className={`${icon} ml-b4`}></i>
					</div>
					<div className='b0_09 b4_14 b6_17'>
						<div className='g gutter-none ai-center'>
							<div className='b0_12 b5_12'>
								{localizations['common.account.directorder.number.text']}: {order.orderNumber}
								<small className='color-color06'>
									{`${localizations['common.account.directorder.platform.from.text']} ${
										localizations[`common.account.directorder.platform.from.${platform}.text`]
									}`}
								</small>
							</div>
							<div className='b0_12 b5_12'>
								<div>{order.date}</div>
								{/* TODO: maybe later add remainingDays */}
								{/* <DirectOrderRemainingDays state={order.state} remainingDays={order.remainingDays} localizations={localizations} /> */}
							</div>
						</div>
					</div>
					<div className='b0_12 b1_push_03 b4_08 b4_push_none b6_06'>
						<div className='g gutter-none ai-center'>
							<div className='b0_08'>
								<DirectOrderState
									orderState={order.state}
									progressStatus={order.progressStatus}
									localizations={localizations}
								/>
							</div>
							<div className='b0_04'>
								<i className={`icon-${toggleIconClass}-open icon fr`} onClick={onToggle}></i>
							</div>
						</div>
					</div>
				</div>
			</article>
			<div className={`do-item-wrap bgcolor-color01 p-2x ${toggleDetailClass}`}>
				{(!order.isProductsLoaded || !order.isRemarksLoaded) && <Waypoint onEnter={onEnter} />}

				{/* {(order.state === EDirectOrderState.open ||
					order.state === EDirectOrderState.inprogress) && ( */}
				<div className='do-item-detail'>
					<h4 className='head head4'>
						{localizations['common.account.directorder.process.options.text']}
					</h4>
					<div className='do-item-actions'>
						{order.state === EDirectOrderState.inprogress &&
							order.progressStatus === EProgressStatus.ordering && (
								<button className='btn' onClick={onClickFinishDirectOrder}>
									{localizations['common.account.directorder.button.action.delivered.text']}
								</button>
							)}

						{(order.state === EDirectOrderState.open ||
							(order.state === EDirectOrderState.inprogress &&
								order.progressStatus === EProgressStatus.inprogress)) && (
							<>
								<button className='btn' onClick={onClickDeliverFromOwnSupply}>
									{
										localizations[
											'common.account.directorder.button.action.supplyfrominventory.text'
										]
									}
								</button>
								<button
									className={`btn ${
										!order.isProductsLoaded || isUnableToOrder ? 'btn-disabled' : ''
									}`}
									{...(!isUnableToOrder && { onClick: onClickOrderAddToBasket })}
									{...(isUnableToOrder && { disabled: true })}
								>
									{localizations['common.account.directorder.button.action.tobasket.text']}
								</button>
							</>
						)}

						{order.state !== EDirectOrderState.canceled && order.state !== EDirectOrderState.completed && (
							<button className='btn-color01-border' onClick={onClickDoNotDeliver}>
								{localizations['common.account.directorder.button.action.donotsupply.text']}
							</button>
						)}

						<button className='btn-color01-border last' onClick={onClickAddRemark}>
							{localizations['common.account.directorder.button.action.addremark.text']}
						</button>
					</div>
					{/* show fetchProductsResult */}
					{/* {(order.state === EDirectOrderState.open ||
					order.state === EDirectOrderState.inprogress) && ( */}
					{(order.state === EDirectOrderState.open ||
						order.state === EDirectOrderState.inprogress) &&
						isUnableToOrder && (
							<Alert
								state='warning'
								message={
									localizations[
										'common.account.directorder.button.action.tobasket.error.unable_to_order.text'
									]
								}
							/>
						)}
				</div>
				{/* )} */}

				<DirectOrderItemAlerts
					addToBasketResult={addToBasketResult}
					deliverFromOwnSupplyResult={deliverFromOwnSupplyResult}
					doNotDeliverResult={doNotDeliverResult}
					finishOrderResult={finishOrderResult}
					localizations={localizations}
				/>

				<DirectOrderRemarks
					order={order}
					readMoreOpen={readMoreOpen}
					toggleReadMore={toggleReadMore}
					localizations={localizations}
				/>
				{popupType && (
					<Portal closeOnEsc closeOnOutsideClick onClose={closeRemarkPopup} isOpened={true}>
						<DirectOrderRemarkPopup
							order={order}
							closePopup={closeRemarkPopup}
							popupType={popupType}
							icon={icon}
						/>
					</Portal>
				)}

				<h4 className='head head4'>
					{localizations['common.account.directorder.products.ordered.text']}
				</h4>
				<div className='list'>
					{order?.orderLines?.map((orderLine, index) => (
						<DirectOrderLineItem localizations={localizations} key={index} orderLine={orderLine} />
					))}
					<section className='g gutter-none ai-center list-item do-item-list-total no-hover'>
						<div className='b0_12 flex jc-flex-end'>
							<label className='mr'>
								{localizations['common.account.directorder.totalvat.text']}:
							</label>
							<div className='prices mr'>
								<DirectOrderPrice price={order.totalPrice} />
							</div>
						</div>
					</section>
				</div>
				<div className='do-item-customer'>
					<div className='g'>
						<DirectOrderItemDeliveryDate order={order} localizations={localizations} />
						<div className='b0_12 b5_18'>
							<h4 className='head head4'>
								{localizations['common.account.directorder.customer.info.text']}
							</h4>
							<DirectOrderCustomerInfo customer={order.customer} />
							{order.customerNote && order.customerNote.length && (
								<>
									<h4 className='head head4'>
										{localizations['common.account.directorder.customer.note.text']}
									</h4>
									<p>{order.customerNote}</p>
								</>
							)}
							<strong>{localizations['common.account.directorder.customer.tradebike.text']}</strong>
							&nbsp;
							{order.tradeInBike
								? localizations['common.account.directorder.customer.tradebike.confirmed.text']
								: localizations['common.account.directorder.customer.tradebike.unconfirmed.text']}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(DirectOrderItem);
