import { useState, useEffect } from 'react';
import { Forms } from 'castlecss-forms';

interface IForm {
	values: any;
	errors: any;
	handleChange: any;
	handleBlur: any;
	handleClientSubmit: any;
	updateElements: any;
	resetErrors?: any;
}

interface ITarget {
	target?: {
		name: any;
	};
}

const useForm = (submitCallback, validate, initialValues = {}): IForm => {
	const [values, setValues] = useState(initialValues);
	const [errors, setErrors] = useState({});
	let initialTarget: ITarget = { target: undefined };
	const [changedTarget, setChangedTarget] = useState(initialTarget);
	const [submitEvent, setSubmitEvent] = useState(undefined);

	useEffect(() => {
		if (Object.keys(errors).length === 0 && submitEvent) {
			setSubmitEvent(undefined);
			submitCallback(submitEvent);
		}
		//for every change on errors, update castlecss forms
		Forms();
	}, [errors]);

	useEffect(() => {
		//for uploads, simulate handleBlur to trigger validation
		if (changedTarget && changedTarget.target) handleBlur(changedTarget);
	}, [changedTarget]);

	const updateElements = (newValues, updateErrors = true) => {
		//update values
		let updatedValues = {
			...values,
			...newValues,
		};
		setValues(updatedValues);

		//update errors
		if (updateErrors === true) {
			//create a copy of the current errors
			let newErrors = Object.assign({}, errors);
			for (let key of Object.keys(newValues)) {
				//validate only the current element
				let updatedErrors = validate(updatedValues);
				//create a copy that only contains the error we want
				let updatedError = extractError(updatedErrors, { [key]: true });
				//remove old error
				delete newErrors[key];
				//add new error if there is one
				newErrors = Object.assign({}, newErrors, updatedError);
			}

			setErrors(newErrors);
		}
	};

	const handleClientSubmit = (event) => {
		event.persist();
		if (event) event.preventDefault();
		setSubmitEvent(event);
		setErrors(validate(values));
	};

	const handleChange = (event, triggerValidate = false) => {
		event.persist();
		let changedValue = event.target.value;
		//handle upload inputs
		if (event.target.files) {
			changedValue = event.target.files;
		}
		let updatedValues = {
			...values,
			[event.target.name]: changedValue,
		};
		setValues(updatedValues);

		//for uploads, update setChangedTarget that we are watching with useEffect to create a 'onBlur' effect
		if (event.target.files) {
			let target: ITarget = { target: { name: event.target.name } };
			setChangedTarget(target);
		}

		if (triggerValidate) {
			updateErrors(event.target.name, updatedValues);
		}
	};

	const updateErrors = (name, updatedValues) => {
		//validate only the current element
		let updatedErrors = validate(updatedValues);
		//create a copy that only contains the error we want
		let updatedError = extractError(updatedErrors, { [name]: true });
		//create a copy of the current errors
		let newErrors = Object.assign({}, errors);
		//remove old error
		delete newErrors[name];
		//add new error if there is one
		newErrors = Object.assign({}, newErrors, updatedError);

		setErrors(newErrors);
	};

	const extractError = (currentErrors, elementToExtract) => {
		let key = Object.keys(elementToExtract)[0];
		let specificError = {};
		specificError[key] = currentErrors[key];
		return specificError;
	};

	const handleBlur = (event) => {
		updateErrors(event.target.name, values);
	};

	const resetErrors = () => {
		setErrors({});
	};

	return {
		values,
		errors,
		handleChange,
		handleBlur,
		handleClientSubmit,
		updateElements,
		resetErrors,
	};
};

export default useForm;
