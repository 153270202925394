import { IHTTPResult } from 'returnorders/types';
import { IHeaderMainMenu } from 'shared/messages/Messages';
import AutoComplete from 'shared/objects/AutoComplete';

export interface IHeaderMainMenuState {
	isFetching: boolean;
	menuData?: IHeaderMainMenu;
	result?: IHTTPResult;
}

export enum MainMenuActions {
	REQUEST_MAINMENU = 'REQUEST_MAINMENU',
	RECEIVE_MAINMENU = 'RECEIVE_MAINMENU',
}

interface RequestMainMenuAction {
	type: typeof MainMenuActions.REQUEST_MAINMENU;
	customerNo: string;
}

interface ReceiveMainMenuAction {
	type: typeof MainMenuActions.RECEIVE_MAINMENU;
	customerNo: string;
	result: IHTTPResult;
	menuData: IHeaderMainMenu;
}

export type MainMenuActionTypes = RequestMainMenuAction | ReceiveMainMenuAction;
