import React from 'react';
import LoadingElement from 'shared/components/LoadingElement';

interface OwnProps {
	wrap?: boolean;
	children?: any;
}

interface StateProps {}

interface DispatchProps {}

type IProps = StateProps & DispatchProps & OwnProps;

interface IState {
	hasError: boolean;
}

class ItemBoundary extends React.Component<IProps, IState> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
	}

	render() {
		if (this.state.hasError) {
			let element = (
				<>
					<div className='b0_03 b4_02 b5_02 b6_02 error'>
						<div className='g gutter-none ai-center'>
							<LoadingElement extraClass='thumb' />
						</div>
					</div>
					<div className='b0_08 b4_push_none b4_22 b5_22 b6_22'>
						<div className='g gutter-none ai-center pl'>
							<div className='b0_12'>
								<LoadingElement extraClass='narrow mr mb-s' />
								<LoadingElement extraClass='narrow mr mb-s' />
							</div>
						</div>
					</div>
				</>
			);

			let wrapper =
				this.props.wrap === false ? (
					element
				) : (
					<article className='g gutter-none ai-center list-item no-hover'>{element}</article>
				);

			return wrapper;
		}
		return this.props.children;
	}
}

export default ItemBoundary;
