import React, { FC } from 'react';
import { IProduct } from 'productblock/messages/messages';

interface IProps {
	product: IProduct;
	isFetching: boolean;
	updateFavoriteProduct: Function;
	reactId: string;
}

const ProductFavorite: FC<IProps> = (props) => {
	const { reactId, product, updateFavoriteProduct, isFetching } = props;
	const { isFavorite } = product;

	const onClickUpdateFavorite = (e) => {
		e.preventDefault();

		if (!props.isFetching) {
			updateFavoriteProduct(reactId, product, !isFavorite);
		}
	};

	if (isFavorite === null) {
		return null;
	}

	let iconClass = !isFavorite ? 'icon-heart-empty' : 'icon-heart';

	return (
		<div className='favorite-item' onClick={onClickUpdateFavorite}>
			<span className={iconClass} data-sl={isFavorite ? 'favorite' : 'non-favorite'}></span>
		</div>
	);
};

export default ProductFavorite;
