import React, { FC } from 'react';

interface IProps {}

const Empty: FC<IProps> = (props) => {
	const nbsp = () => {
		return { __html: '&nbsp;' };
	};
	return <span dangerouslySetInnerHTML={nbsp()} />;
};

export default Empty;
