import React, { FC, useState, useEffect, Fragment } from 'react';
import { ISearchState } from 'shared/general/searchbar/types';
import createConnect from 'shared/general/searchbar/connect';
import Loading from 'shared/components/Loading';
import Picture from 'returnorders/components/picture';
import { useKeyPress } from 'shared/hooks/useKeyPress';
import {
	ProductSuggestion,
	CategorySuggestion,
	SearchSuggestion,
} from 'shared/objects/AutoComplete';
import Image from 'returnorders/objects/Image';

interface OwnProps {
	onSelectSearchTerm: Function;
	visible: boolean;
	searchTerm: string;
}

interface StateProps {
	searchbar: ISearchState;
	localizations: object;
}

interface DispatchProps {}

type IProps = StateProps & DispatchProps & OwnProps;
type TSuggestion = SearchSuggestion | ProductSuggestion | CategorySuggestion;

const AutoComplete: FC<IProps> = (props) => {
	const { onSelectSearchTerm, visible, searchTerm } = props;
	const { searchbar, localizations } = props;
	const { autocomplete, isFetching } = searchbar;
	const [show, setShow] = useState(false);
	const [cursor, setCursor] = useState(0);
	const [suggestions, setSuggestions] = useState<TSuggestion[]>();

	let hasSuggestions =
		autocomplete &&
		(autocomplete.productSuggestions.length > 0 ||
			autocomplete.categorySuggestions.length > 0 ||
			autocomplete.searchSuggestions.length > 0);

	useEffect(() => {
		if (hasSuggestions) {
			setShow(true);
			if (autocomplete) {
				//order of arrays is fixed
				const sug: TSuggestion[] = [
					...autocomplete.searchSuggestions,
					...autocomplete.productSuggestions,
					...autocomplete.categorySuggestions,
				];
				setSuggestions(sug);
			}
		}
	}, [autocomplete]);

	useEffect(() => {
		setShow(visible);
	}, [visible]);

	useEffect(() => {
		const currentSuggestion = getSuggestion();
		if (currentSuggestion && !('sku' in currentSuggestion)) {
			updateSearchField(currentSuggestion);
		}
	}, [cursor]);

	const handleKeyUp = () => {
		if (suggestions) {
			if (cursor < 1) {
				setCursor(0);
			} else {
				setCursor(cursor - 1);
			}
		}
	};

	const updateSearchField = (suggestion: TSuggestion) => {
		if ('title' in suggestion) {
			onSelectSearchTerm(suggestion.title, false);
		}

		if ('name' in suggestion) {
			onSelectSearchTerm(suggestion.name, false);
		}
	};

	const handleKeyDown = () => {
		if (suggestions) {
			const totalLength = suggestions.length;
			if (cursor < totalLength) {
				setCursor(cursor + 1);
			} else {
				setCursor(totalLength);
			}
		}
	};
	const handleKeyEnter = (e) => {
		if (cursor > 0) {
			//SearchBar will handle submit if no suggestion is highlighted
			e.preventDefault();

			const currentSuggestion = getSuggestion();
			if (currentSuggestion) {
				//check if sugesstion or link
				if ('title' in currentSuggestion) {
					onSelectSearchTerm(currentSuggestion.title);
				} else {
					window.location.href = currentSuggestion.link;
				}
			}
		}
	};

	useKeyPress('ArrowDown', handleKeyDown);
	useKeyPress('ArrowUp', handleKeyUp);
	useKeyPress('Enter', handleKeyEnter);

	const getSuggestion = () => {
		if (suggestions) {
			if (cursor > 0) {
				const currentSuggestion = suggestions[cursor - 1];
				return currentSuggestion;
			}
		}
		return null;
	};

	const autoCompleteClassName = `autocomplete searchbox ${show ? 'open' : ''}`;

	const onClickLayer = (e: any) => {
		e.preventDefault();
		setShow(false);
		setCursor(0);
	};

	const onClickSearchTerm = (e: any, suggestion: any) => {
		e.preventDefault();
		onSelectSearchTerm(suggestion.title);
	};

	const replaceAll = (value, searchStr, replaceStr) => {
		// escape regexp special characters in search string
		searchStr = searchStr.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
		return value.replace(new RegExp(searchStr, 'gi'), replaceStr);
	};

	const replaceSearchTerm = (value: string) => {
		let replaceValue = '</strong>' + searchTerm + '<strong>';
		let newValue = '<strong>' + replaceAll(value, searchTerm, replaceValue) + '</strong>';
		//remove empty strong tags
		newValue = replaceAll(newValue, '<strong></strong>', '');
		return newValue;
	};

	let suggestCounter = 0;

	return (
		<div className={autoCompleteClassName}>
			<div className='autocomplete-layer' onClick={onClickLayer} />
			<div className='autocomplete-results'>
				{autocomplete && autocomplete.searchSuggestions.length > 0 && (
					<div className='autocomplete-separator'>
						<div className='g ai-center'>
							<div className='b0_12 b3_24'>
								<small className='color-color06'>
									{localizations['common.header.searchsuggest.suggests.title']}
								</small>
							</div>
						</div>
					</div>
				)}
				{autocomplete && autocomplete.searchSuggestions.length > 0 && (
					<Fragment>
						<ul className='autocomplete-suggestions'>
							{autocomplete.searchSuggestions.map((suggestion, index) => {
								suggestCounter++;
								return (
									<li
										key={index}
										className={`autocomplete-suggestions-item ${
											cursor === suggestCounter ? 'active' : ''
										}`}
									>
										<a
											data-href={suggestion.link}
											onClick={(e) => onClickSearchTerm(e, suggestion)}
										>
											<span
												dangerouslySetInnerHTML={{ __html: replaceSearchTerm(suggestion.title) }}
											></span>
										</a>
									</li>
								);
							})}
						</ul>
					</Fragment>
				)}
				{autocomplete && autocomplete.productSuggestions.length > 0 && (
					<Fragment>
						<div className='autocomplete-separator'>
							<div className='g ai-center'>
								<div className='b0_12 b3_24'>
									<small className='color-color06'>
										{localizations['common.header.searchsuggest.products.title']}
									</small>
								</div>
							</div>
						</div>
						<ul className='autocomplete-suggestions'>
							{autocomplete.productSuggestions.map((suggestion, index) => {
								suggestCounter++;

								let images: Image[] = [];
								if (suggestion.image) {
									let image: Image = {
										name: suggestion.sku,
										altText: suggestion.name,
										imageLink: suggestion.image,
									};
									images.push(image);
								}

								return (
									<li
										key={index}
										className={`autocomplete-suggestions-item-product ${
											cursor === suggestCounter ? 'active' : ''
										}`}
									>
										<a className='g gutter-none list-item' href={suggestion.link}>
											<div className='b0_02 b2_01 b4_04 b5_04 b6_02 img-wrap'>
												<Picture sku={suggestion.sku} altText={suggestion.name} images={images} />
											</div>
											<div className='b0_09 b4_20 autocomplete-suggestions-item-product-details'>
												<div className=''>
													<div>
														<div
															className='autocomplete-suggestions-item-product-name pr-2x'
															dangerouslySetInnerHTML={{
																__html: replaceSearchTerm(suggestion.name),
															}}
														></div>
													</div>
													<div>
														<small className='color-color06 autocomplete-suggestions-item-product-sku'>{`${suggestion.sku}`}</small>
													</div>
												</div>
											</div>
										</a>
									</li>
								);
							})}
						</ul>
					</Fragment>
				)}
				{autocomplete && autocomplete.categorySuggestions.length > 0 && (
					<Fragment>
						<div className='autocomplete-separator'>
							<div className='g ai-center'>
								<div className='b0_12 b3_24'>
									<small className='color-color06'>
										{localizations['common.header.searchsuggest.categories.title']}
									</small>
								</div>
							</div>
						</div>
						<ul className='autocomplete-suggestions'>
							{autocomplete.categorySuggestions.map((suggestion, index) => {
								suggestCounter++;
								return (
									<li
										key={index}
										className={`autocomplete-suggestions-item ${
											cursor === suggestCounter ? 'active' : ''
										}`}
									>
										<a href={suggestion.link}>
											<span
												dangerouslySetInnerHTML={{ __html: replaceSearchTerm(suggestion.name) }}
											></span>
										</a>
									</li>
								);
							})}
						</ul>
					</Fragment>
				)}
				{isFetching && <Loading overlay={false} />}
				{!isFetching && hasSuggestions === false && (
					<div className='autocomplete-footer'>
						{localizations['returnorder.suggest.noresults.title']}
					</div>
				)}
			</div>
		</div>
	);
};

const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(AutoComplete);
