import React, { FC } from 'react';
export interface IPrice {
	discountPercentage: null;
	fraction: string;
	name: 'ListPrice' | 'NettPrice' | 'CostPrice' | 'GrossPrice' | 'PriceGrossSolr' | string;
	quantityFrom: null;
	whole: string;
}

interface IProps {
	prices: IPrice[];
	sale: boolean;
	showPrices: boolean;
	showMsrPrices: boolean;
	centerOnDesktop?: boolean;
	isParent?: boolean;
	showFromLabel?: boolean;
	fromText?: string;
	horizontal?: boolean;
	productStatus: any;
}

const ProductPrice: FC<IProps> = (props) => {
	const {
		prices,
		sale,
		showPrices,
		showMsrPrices,
		centerOnDesktop,
		isParent,
		showFromLabel,
		fromText,
		horizontal,
		productStatus,
	} = props;
	let productPrice: IPrice | undefined = undefined;
	let grossPrice: IPrice | undefined = undefined;

	const showPrice = productStatus
		? productStatus.showPrice || productStatus.isShelfPlan === true
		: true;

	if (showPrice && prices && showPrices) {
		if (showMsrPrices) {
			productPrice = prices.find(function (price) {
				return price.name == 'ListPrice';
			});
		} else {
			productPrice = prices.find(function (price) {
				return price.name == 'NettPrice';
			});
			if (productPrice == null) {
				productPrice = prices.find(function (price) {
					return price.name == 'CostPrice';
				});
			}
		}
		if (sale) {
			grossPrice = prices.find(function (price) {
				return price.name == 'PriceGrossSolr';
			});
			if (grossPrice == null) {
				grossPrice = prices.find(function (price) {
					return price.name == 'GrossPrice';
				});
			}
		}
	}

	if (showPrice && sale && !showMsrPrices) {
		const productPriceSaleElement = productPrice ? (
			<div
				className={
					'prices piece ' + (centerOnDesktop ? 'ta-center-b4' : '') + (horizontal ? ' flex' : '')
				}
			>
				{isParent && showFromLabel && <span>{fromText}&nbsp;</span>}
				{grossPrice && (
					<del>
						{grossPrice.whole},<span className='sup'>{grossPrice.fraction}</span>
					</del>
				)}
				{horizontal && <>&nbsp;</>}
				<ins className='price' itemProp='price'>
					{productPrice.whole},<span className='sup'>{productPrice.fraction}</span>
				</ins>
			</div>
		) : (
			<div className='prices'>&nbsp;</div>
		);
		return productPriceSaleElement;
	}

	const productPriceElement = productPrice ? (
		<div
			className={
				'prices ' +
				(centerOnDesktop ? 'ta-center-b4 ' : ' ') +
				(isParent ? 'piece' : '') +
				(horizontal ? ' flex' : '')
			}
		>
			{isParent && showFromLabel && <span>{fromText}&nbsp;</span>}
			<span className='price' itemProp='price'>
				{productPrice.whole},<span className='sup'>{productPrice.fraction}</span>
			</span>
		</div>
	) : (
		<div className='prices'>&nbsp;</div>
	);

	return productPriceElement;
};

ProductPrice.defaultProps = {
	centerOnDesktop: false,
	isParent: false,
	showFromLabel: false,
	fromText: undefined,
};

export default ProductPrice;
