import React, { FC } from 'react';

const ProductShadow: FC = (props) => {
	return (
		<article className='b0_10 b3_12 b4_12 b5_06'>
			<div className='list-item list-item-block column'>
				{/*<span className="list-item-sale" data-sale>shared.listeritem.sale.label</span>*/}
				<a className='list-img' href='' data-link>
					<picture data-picture>
						<source
							srcSet='https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:320:320:0:1/bg:ffffff/q:60, https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:640:640:0:1/bg:ffffff/q:60 2x'
							media='(min-width: 320px)'
						/>
						<img
							src='https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:320:320:0:1/bg:ffffff/q:60'
							alt='Placeholder'
						/>
					</picture>
				</a>
				<h2 className='head head5 mb-2x' data-name>
					<span className='shadow-element'>&nbsp;</span>
				</h2>
				<div className='g ai-center'>
					<div className='b0_06'>
						<div className='prices' data-price>
							<span className='shadow-element'>&nbsp;</span>
						</div>
					</div>
					<div className='b0_02' data-status></div>
					<div className='b0_04' data-button-add>
						<span className='shadow-element input'>&nbsp;</span>
					</div>
				</div>
			</div>
		</article>
	);
};

export default ProductShadow;
