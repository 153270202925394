import fetch from 'isomorphic-fetch';
import $ from 'jquery';
import { getJsonOrRedirect, uriEncodeFormObject } from 'shared/libs/urlhandling';

import { PROGRESS_RESULT } from 'shared/general/types';

export const REQUEST_NOTFICATIONS = 'REQUEST_NOTFICATIONS';
export const RECEIVE_NOTFICATIONS = 'RECEIVE_NOTFICATIONS';

export const REQUEST_ACCOUNTSWITCH = 'REQUEST_ACCOUNTSWITCH';
export const RECEIVE_ACCOUNTSWITCH = 'RECEIVE_ACCOUNTSWITCH';

export function requestNotifcations(status) {
	return {
		type: REQUEST_NOTFICATIONS,
		status,
	};
}

export function receiveNotifcations(data, status) {
	return {
		type: RECEIVE_NOTFICATIONS,
		data,
		status,
	};
}

export function fetchNotications(url, params) {
	return (dispatch, getState) => {
		dispatch(requestNotifcations(PROGRESS_RESULT.WAITING));

		const delayAsync = (time) => {
			return new Promise((resolve) => {
				setTimeout(resolve, time);
			});
		};

		let urlParams = params ? params : '';

		Promise.resolve()
			//test error
			//.then(()=> {throw new Error("test error");})
			.then(() =>
				fetch(`${url + urlParams}`, {
					method: 'get',
					credentials: 'same-origin',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				})
			)
			.then(getJsonOrRedirect)
			.then((data) => dispatch(receiveNotifcations(data, PROGRESS_RESULT.SUCCESS)))
			.then(() => delayAsync(300))
			.then((data) => dispatch(receiveNotifcations(null, PROGRESS_RESULT.NONE)))
			.catch((error) => dispatch(receiveNotifcations(null, PROGRESS_RESULT.ERROR)));
	};
}

export function requestAccountSwitch() {
	return {
		type: REQUEST_ACCOUNTSWITCH,
	};
}

export function receiveAccountSwitch() {
	return {
		type: RECEIVE_ACCOUNTSWITCH,
	};
}

export function accountSwitch(customerID) {
	return (dispatch, getState) => {
		const { general } = getState();
		const accountSwitchUrl = general.accountSubMenu.accountSwitch.url;

		if (accountSwitchUrl == null) {
			console.error("Can't find accountSwitchUrl!");
			return;
		}

		return dispatch((dispatch) => {
			dispatch(requestAccountSwitch());
			let bodyJson = {};
			bodyJson['CustomerID'] = customerID;
			bodyJson['SynchronizerToken'] = general.synchronizerToken;

			let bodyString = uriEncodeFormObject(bodyJson);

			return fetch(`${accountSwitchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: bodyString,
			})
				.then((response) => response.json())
				.then((response) => redirectAfterSwitch(response))
				.then((response) => dispatch(receiveAccountSwitch()));
		});
	};
}

export function redirectAfterSwitch(response) {
	if (!response.success) {
		return;
	}

	$.removeCookie('dealerportal_feature_lastUpdated', { path: '/' });
	$.removeCookie('dealerportal_feature_flushDate', { path: '/' });

	var returnOrders = $('#returnOrders');
	if (returnOrders.length > 0) {
		var returnOrdersUrl = returnOrders.data('url');
		window.location.href = returnOrdersUrl;
	} else {
		window.location.reload();
	}
}
