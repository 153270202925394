export interface IHTTPResult {
	json: object;
	status: number;
	error: boolean;
	animate?: IProgressResult;
}

export interface IProgressResult {
	key: string | number;
	status: AnimateStatus;
	isAnimating: Function;
	isStarted: Function;
	isFinished: Function;
}

export class ProgressResult implements IProgressResult {
	public constructor(init?: Partial<ProgressResult>) {
		Object.assign(this, init);
	}
	key: string | number = '';
	status: AnimateStatus = AnimateStatus.start;
	isAnimating: Function = (key: string | number): boolean => {
		if (key === this.key) {
			return this.isStarted();
		}
		return false;
	};
	isStarted: Function = (): boolean => {
		//after "start" more status steps could be added later
		return this.status !== AnimateStatus.finished;
	};
	isFinished: Function = (): boolean => {
		return this.status === AnimateStatus.finished;
	};
}

export enum AnimateStatus {
	start = 'start',
	finished = 'finished',
}

export interface History {
	push(path: string, state?: any): void;
	goBack(): any;
}

export interface IOption {
	key: string;
	value: string;
	disabled?: boolean;
	label?: string;
	identifier?: string;
}

export interface IReturnCustomerData {
	customerNumber: string;
	companyName: string;
	login: string;

	bikeAddresses: Array<IAddress>;
	batteryAddresses: Array<IAddress>;
	partAddresses: Array<IAddress>;
}

export interface IAddress {
	address: string;
	addressID: string;
	street: string;
	streetNumber: string;
	streetNumberAddition: string;
	zipCode: string;
	city: string;
	country: string;
	countryCode: string;
	deliveryDates: Array<string>;
}

export enum subSearchEntries {
	serialnumber = 'serialnumber',
	productcode = 'productcode',
	framenumber = 'framenumber',
}

export enum searchEntries {
	part = 'part',
	battery = 'battery',
	bike = 'bike',
	bikepart = 'bikepart',
	packingslip = 'packingslip',
	linereference = 'linereference',
	linereference_exactmatch = 'linereference_exactmatch',
}

export enum searchUrls {
	part = 'parts/?productCode',
	batteryserialnumber = 'batteries/?serialNumber',
	batteryproductcode = 'batteries/?productCode',
	battery = 'batteries/?serialNumber',
	bikeframenumber = 'bikes/?frameNumber',
	bikeproductcode = 'bikes/?productCode',
	bike = 'bikes/?frameNumber',
	bikepart = 'bikes/?frameNumber',
	packingslip = 'packingslips/?packingSlipNumber',
	linereference = 'packingslips/?lineReference',
	linereferenceExactMatch = 'packingslips/?lineReferenceExactMatch=true&lineReference',
}

export enum productTypeCodes {
	part = 'part',
	battery = 'battery',
	bike = 'bike',
	service = 'service',
}

export enum statusTypes {
	request = 'request',
	container = 'container',
	order = 'order',
	orderline = 'orderline',
}

export enum STATUSTYPE {
	REQUEST = 'request',
	CONTAINER = 'container',
	ORDER = 'order',
	ORDERLINE = 'orderline',
}

export enum statusCodes {
	concept = 'concept',
	pending = 'pending',
	actionrequired = 'actionrequired',
	submitted = 'submitted',
	invalid = 'invalid',
	approved = 'approved',
	rejected = 'rejected',
	cancelled = 'cancelled',
	cannotbeprocessed = 'cannotbeprocessed',
	readytoreturn = 'readytoreturn',
	processed = 'processed',
	processing = 'processing',
	received = 'received',
	pickuprequested = 'pickuprequested',
	notpickedup = 'notpickedup',
	open = 'open',
}

export enum hintCodes {
	serialnumber = 'SerialNumber',
	explanation = 'Explanation',
	attachments = 'Attachments',
}

export enum ContainerTypes {
	container = 'container',
	order = 'order',
}

export interface IDetailItem {
	productCode: string;
	productDescription: string;
	amount: number;
	statusCode: string;
	requestId: number;
	requestDate?: Date;
}

export interface IDetail {
	name: string;
	productTypeCode: string;
	items: IDetailItem[];
	isCheckoutAllowed: boolean;
	id: number;
	statusCode: string;
}

export interface IData {
	firstName: string;
	lastName: string;
	accountOwner: boolean;
	customerNumber: string;
	login: string;
	companyName: string;
	batteryAddresses: [];
	bikeAddresses: [];
	partAddresses: [];
}

export interface ISelectAPI {
	selectedItem?: number;
	setSelectedItem: (selectedItem: number) => void;
}
