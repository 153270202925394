import React, { FC } from 'react';
import { OrderRemark } from 'directorder/messages/messages';

interface OwnProps {
	remark: OrderRemark;
	localizations: any;
}

interface StateProps {}

type IProps = StateProps & OwnProps;

export const DirectOrderRemark: FC<IProps> = (props) => {
	const { remark, localizations } = props;

	return (
		<article className={`g gutter-none list-item no-hover`}>
			<div className='b0_12 b4_04'>
				<strong className='title'>
					{localizations['common.account.directorder.remarks.user.text']}
				</strong>
				<span>{remark.username}</span>
			</div>
			<div className='b0_12 b4_04'>
				<strong className='title'>
					{localizations['common.account.directorder.remarks.date.text']}
				</strong>
				<span>{remark.date}</span>
			</div>
			<div className='b0_12 b4_16'>
				<strong className='title'>
					{localizations['common.account.directorder.remarks.remark.text']}
				</strong>
				<span>{remark.remark}</span>
			</div>
		</article>
	);
};

export default DirectOrderRemark;
