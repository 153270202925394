import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface OwnProps {
	message: string;
	center?: boolean;
	state?: undefined | 'error' | 'warning' | 'positive' | 'present' | 'disabled';
	name?: string;
}

type IProps = OwnProps;

const Alert: FC<IProps> = (props) => {
	const { message, center, state, name } = props;

	let stateClass: string = 'alert-warning';
	switch (state) {
		case 'error':
			stateClass = 'alert-negative';
			break;
		case 'positive':
			stateClass = 'alert-positive';
			break;
		case 'present':
			stateClass = 'alert-present';
			break;
		case 'disabled':
			stateClass = 'alert-disabled';
			break;
		default:
			stateClass = 'alert-warning';
			break;
	}

	let centerClass: string = center ? 'ta-center' : '';

	let cssClass: string = `${stateClass} ${centerClass}`;

	let attr = {
		className: cssClass,
		...(name && {
			id: `${name}-alert`,
			htmlFor: name,
		}),
	};

	if (name) {
		return <label {...attr}>{message}</label>;
	} else {
		return <div {...attr}>{message}</div>;
	}
};

Alert.defaultProps = {
	state: 'error',
};

export default Alert;
