import React, { FC } from 'react';

interface OwnProps {
	message: string;
	name?: string;
	state?: undefined | 'error' | 'warning' | 'positive' | 'present' | 'disabled';
}

interface StateProps {}

interface DispatchProps {}

type IProps = StateProps & DispatchProps & OwnProps;

const Notify: FC<IProps> = (props) => {
	const { message, name, state } = props;

	let stateClass: string = 'notify-warning';
	switch (state) {
		case 'error':
			stateClass = 'notify-negative';
			break;
		case 'positive':
			stateClass = 'notify-positive';
			break;
		case 'present':
			stateClass = 'notify-present';
			break;
		case 'disabled':
			stateClass = 'notify-disabled';
			break;
		default:
			stateClass = 'notify-warning';
			break;
	}
	if (name) {
		stateClass += '-label';
	}

	let attr = {
		className: stateClass,
		...(name && {
			id: `${name}-notify`,
			htmlFor: name,
		}),
	};

	return <label {...attr}>{message}</label>;
};

Notify.defaultProps = {
	state: 'error',
};

export default Notify;
