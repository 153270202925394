import React, { FC, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { TweakwiseProductsContainer } from './container/tweakwiseProductsContainer';
import { ITweakwiseProductsState } from './container/types';
import { fetchRecommendedProducts } from './container/actions';
import { IData } from './types';
import Cookies from 'js-cookie';

interface OwnProps {
	reactId: string;
}

interface StateProps {
	list: ITweakwiseProductsState;
	data: any; //IData
	localizations: object;
}

interface DispatchProps {
	fetchRecommendedProducts: any;
}

type IProps = StateProps & DispatchProps & OwnProps;

const TweakwiseProducts: FC<IProps> = (props) => {
	const { reactId, data, list, localizations } = props;
	const { displayId, title, subTitle } = data;

	const fetchRecommended = () => {
		props.fetchRecommendedProducts(reactId, displayId);
	};

	return (
		<TweakwiseProductsContainer
			reactId={props.reactId}
			title={title}
			subTitle={subTitle}
			list={list}
			fetchRecommendedProducts={fetchRecommended}
			localizations={localizations}
		/>
	);
};

import createConnect from './container/connect';
const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(TweakwiseProducts);
