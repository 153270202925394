import React, { Component } from 'react';

interface IProps {
	attributes: {
		type: any;
		name?: string;
		value?: string;
		autofocus?: boolean;
		disabled?: boolean;
		onClick?: any;
	};
	text: string;
	className?: string;
}

/**
 * @deprecated consider using formikelements instead
 */
export default class Button extends Component<IProps> {
	render() {
		const { attributes, className, text } = this.props;

		return (
			<button {...attributes} className={'btn ' + (className ? className : '')}>
				{text}
			</button>
		);
	}
}
