import { IHTTPResult } from '../types';
import { IProduct } from './../messages/messages';

export interface ITweakwiseProductsState {
	isFetching: boolean;
	productsResult?: IHTTPResult;
	products: IProduct[];
	reactId: string | undefined;
}

export enum TweakwiseActions {
	REQUEST_RECOMMENDATIONS = 'REQUEST_RECOMMENDATIONS',
	RECEIVE_RECOMMENDATIONS = 'RECEIVE_RECOMMENDATIONS',
}

interface TweakwiseProductAction {
	reactId: string;
}

interface RequestRecommendations extends TweakwiseProductAction {
	type: typeof TweakwiseActions.REQUEST_RECOMMENDATIONS;
	displayId: string;
}

interface RecieveRecommendations extends TweakwiseProductAction {
	type: typeof TweakwiseActions.RECEIVE_RECOMMENDATIONS;
	productResult?: IHTTPResult;
	products: IProduct[];
}

export type TweakwiseActionTypes = RequestRecommendations | RecieveRecommendations;
