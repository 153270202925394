import { ITweakwiseProductsState, TweakwiseActionTypes, TweakwiseActions } from './types';
import produce from 'immer';

const initialState: ITweakwiseProductsState = {
	isFetching: false,
	products: [],
	productsResult: undefined,
	reactId: undefined,
};

export function tweakwiseProductsReducer(reactId: string) {
	const componentInitialState: ITweakwiseProductsState = Object.assign({}, initialState, {
		reactId: reactId,
	});
	function tweakwiseProductsComponent(
		state = componentInitialState,
		action: TweakwiseActionTypes
	): ITweakwiseProductsState {
		return tweakwiseProducts(state, action);
	}
	return tweakwiseProductsComponent;
}

const tweakwiseProducts = (
	state = initialState,
	action: TweakwiseActionTypes
): ITweakwiseProductsState => {
	if (action.reactId != state.reactId) {
		return state;
	}
	switch (action.type) {
		case TweakwiseActions.REQUEST_RECOMMENDATIONS: {
			return produce(state, (draftState) => {
				draftState.isFetching = true;
			});
		}

		case TweakwiseActions.RECEIVE_RECOMMENDATIONS: {
			const { products, productResult } = action;

			let newState = produce(state, (draftState) => {
				draftState.isFetching = false;
				draftState.products = products;
				draftState.productsResult = productResult;
			});

			return newState;
		}

		default:
			return state;
	}
};
