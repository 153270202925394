import { IHTTPResult } from 'shared/types';

export enum OrderInfoActions {
	REQUEST_ORDERINFO_DATA = 'REQUEST_ORDERINFO_DATA',
	RECEIVE_ORDERINFO_DATA = 'RECEIVE_ORDERINFO_DATA',
}

interface OrderInfoAction {
	reactId: string;
}

interface RequestOrderInfo extends OrderInfoAction {
	type: typeof OrderInfoActions.REQUEST_ORDERINFO_DATA;
}

interface RecieveOrderInfo extends OrderInfoAction {
	type: typeof OrderInfoActions.RECEIVE_ORDERINFO_DATA;
	result?: IHTTPResult;
}

export type OrderInfoActionTypes = RequestOrderInfo | RecieveOrderInfo;
