import fetch from 'isomorphic-fetch';
import { getJsonOrRedirect } from 'shared/libs/urlhandling';

/**
 * SPAREPARTLISTER ACTIONS CONSTANTS
 */
export const REQUEST_SPAREPARTLISTER = 'REQUEST_SPAREPARTLISTER';
export const RECEIVE_SPAREPARTLISTER = 'RECEIVE_SPAREPARTLISTER';
/**
 * SPAREPART ACTION CONSTANTS
 */
export const REQUEST_SPAREPART = 'REQUEST_SPAREPART';
export const RECEIVE_SPAREPART = 'RECEIVE_SPAREPART';

/**
 * BASKET ACTION CONSTANTS
 */
export const REQUEST_ADDSPAREPARTTOBASKET = 'REQUEST_ADDSPAREPARTTOBASKET';
export const RECEIVE_ADDSPAREPARTTOBASKET = 'RECEIVE_ADDSPAREPARTTOBASKET';

export const REQUEST_ADDPRODUCTTODROPSHIPMENTBASKET = 'REQUEST_ADDPRODUCTTODROPSHIPMENTBASKET';
export const RECEIVE_ADDPRODUCTTODROPSHIPMENTBASKET = 'RECEIVE_ADDPRODUCTTODROPSHIPMENTBASKET';

export const REQUEST_ADDRELATEDSPAREPARTTOBASKET = 'REQUEST_ADDRELATEDSPAREPARTTOBASKET';
export const RECEIVE_ADDRELATEDSPAREPARTTOBASKET = 'RECEIVE_ADDRELATEDSPAREPARTTOBASKET';

export const RECEIVE_CLEARBASKETITEMPROGRESS = 'RECEIVE_CLEARBASKETITEMPROGRESS';
export const SET_ACTIVE_SPAREPART = 'SET_ACTIVE_SPAREPART';

/**
 * PRODUCTDETAIL ACTION CONSTANTS
 */
export const REQUEST_GETPRODUCTDETAIL_DATA = 'REQUEST_GETPRODUCTDETAIL_DATA';
export const RECEIVE_GETPRODUCTDETAIL_DATA = 'RECEIVE_GETPRODUCTDETAIL_DATA';
export const CLEAR_PRODUCTDETAIL_DATA = 'CLEAR_PRODUCTDETAIL_DATA';

export const REQUEST_ADDPRODUCTCOMMENT = 'REQUEST_ADDPRODUCTCOMMENT';
export const RECEIVE_ADDPRODUCTCOMMENT = 'RECEIVE_ADDPRODUCTCOMMENT';

export const REQUEST_UPDATEFAVORITEPRODUCT = 'REQUEST_UPDATEFAVORITEPRODUCT';
export const RECEIVE_UPDATEFAVORITEPRODUCT = 'RECEIVE_UPDATEFAVORITEPRODUCT';

export const REQUEST_CLOSEBASKETITEMCONFIGURATION = 'REQUEST_CLOSEBASKETITEMCONFIGURATION';
export const REQUEST_ADDBASKETITEMCONFIGURATION = 'REQUEST_ADDBASKETITEMCONFIGURATION';
export const RECEIVE_ADDBASKETITEMCONFIGURATION = 'RECEIVE_ADDBASKETITEMCONFIGURATION';

export const REQUEST_CONFIGURATION_CONTEXT = 'REQUEST_CONFIGURATION_CONTEXT';
export const RECEIVE_CONFIGURATION_CONTEXT = 'RECEIVE_CONFIGURATION_CONTEXT';
export const REQUEST_CONFIGURATION_CLOSE = 'REQUEST_CONFIGURATION_CLOSE';

export const REQUEST_CONFIGURATION_DATA = 'REQUEST_CONFIGURATION_DATA';
export const RECEIVE_CONFIGURATION_DATA = 'RECEIVE_CONFIGURATION_DATA';

/**
 * SPAREPART ACTIONS
 */
export function receiveSparepart(url, index, json) {
	return {
		type: RECEIVE_SPAREPART,
		url,
		index,
		data: json,
	};
}

export function requestSparepart(url, index) {
	return {
		type: REQUEST_SPAREPART,
		url,
		index,
	};
}

function fetchSparepart(url, index) {
	return (dispatch) => {
		dispatch(requestSparepart(url, index));
		return fetch(`${url}`, {
			method: 'get',
			credentials: 'same-origin',
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveSparepart(url, index, json)));
	};
}

export function fetchSparepartVariant(url, index) {
	return (dispatch, getState) => {
		return dispatch(fetchSparepart(url, index));
	};
}

/**
 *  SPAREPARTLISTER ACTIONS
 */
function requestSparepartLister(searchString) {
	return {
		type: REQUEST_SPAREPARTLISTER,
		searchString,
	};
}

export function receiveSparepartLister(url, json) {
	return {
		type: RECEIVE_SPAREPARTLISTER,
		url,
		data: json,
	};
}

/**
 *  PRODUCT COMMENT
 */
function requestAddProductComment() {
	return {
		type: REQUEST_ADDPRODUCTCOMMENT,
	};
}

export function receiveAddProductComment(dispatchUrl, json) {
	return {
		type: RECEIVE_ADDPRODUCTCOMMENT,
		dispatchUrl,
		data: json,
	};
}

function fetchSparepartLister(dispatchUrl, searchString, synchronizerToken) {
	return (dispatch) => {
		dispatch(requestSparepartLister(searchString));

		const body = 'SearchString=' + searchString + '&SynchronizerToken=' + synchronizerToken;
		let request = {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		};

		return fetch(`${dispatchUrl}`, request)
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveSparepartLister(dispatchUrl, json)));
	};
}

export function searchForSpareparts(searchString) {
	return (dispatch, getState) => {
		const { sparepartLister, extensions, synchronizerToken } = getState();
		const { SparepartsDispatchUrl } = extensions;
		const { sparepartListers } = sparepartLister;

		if (!sparepartListers[searchString]) {
			return dispatch(fetchSparepartLister(SparepartsDispatchUrl, searchString, synchronizerToken));
		} else {
			return dispatch(
				receiveSparepartLister(SparepartsDispatchUrl, sparepartListers[searchString])
			);
		}
	};
}

/**
 *  SPAREPARTLISTER BASKET ACTIONS
 */

function requestAddSparepartToBasket(dispatchUrl, sparepart, quantity) {
	return {
		type: REQUEST_ADDSPAREPARTTOBASKET,
		sparepart: sparepart,
		dispatchUrl: dispatchUrl,
		quantity,
	};
}

export function receiveAddSparepartToBasket(dispatchUrl, sparepart, json) {
	return {
		type: RECEIVE_ADDSPAREPARTTOBASKET,
		dispatchUrl,
		sparepart: sparepart,
		data: json,
	};
}

function requestAddProductToDropshipmentBasket(index) {
	return {
		type: REQUEST_ADDPRODUCTTODROPSHIPMENTBASKET,
		index,
	};
}

export function receiveAddProductToDropshipmentBasket(dispatchUrl, groupindex, index, json) {
	return {
		type: RECEIVE_ADDPRODUCTTODROPSHIPMENTBASKET,
		dispatchUrl,
		groupindex,
		index,
		data: json,
	};
}

function requestAddRelatedSparepartToBasket() {
	return {
		type: REQUEST_ADDRELATEDSPAREPARTTOBASKET,
	};
}

function receiveAddRelatedSparepartToBasket(product, json, list) {
	return {
		type: RECEIVE_ADDRELATEDSPAREPARTTOBASKET,
		data: json,
		product,
		list,
	};
}

export function receiveClearBasketItemProgress() {
	return {
		type: RECEIVE_CLEARBASKETITEMPROGRESS,
	};
}

export function addProductComment(subject, message, mailTo) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const dispatchUrl = extensions.ContactDispatchUrl;

		dispatch(requestAddProductComment());

		const body =
			'addProductComment=addProductComment&Contact_Subject=' +
			subject +
			'&Contact_Msg=' +
			message +
			'&SynchronizerToken=' +
			synchronizerToken +
			'&Contact_MailTo=' +
			mailTo;

		return fetch(`${dispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveAddProductComment(dispatchUrl, json)));
	};
}

/**
 * CONFIGURATION CONTEXT
 */
function requestConfigurationContext(popupState) {
	return {
		type: REQUEST_CONFIGURATION_CONTEXT,
		popupState,
	};
}

export function receiveConfigurationContext(data, popupState, configuration) {
	return {
		type: RECEIVE_CONFIGURATION_CONTEXT,
		data,
		popupState,
		configuration,
	};
}

export function getConfigurationContext(popupState, configuration) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl == null) {
			console.error("Can't find BasketDispatchUrl in extension!");
			return;
		}

		return dispatch((dispatch) => {
			dispatch(requestConfigurationContext(popupState));

			let body = 'getConfigurationContext=getConfigurationContext';
			body += '&SynchronizerToken=' + synchronizerToken;
			body += '&ViewBasketConfiguration_SKU=' + popupState.sku;

			return fetch(`${basketDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
				.then(getJsonOrRedirect)
				.then((data) => dispatch(receiveConfigurationContext(data, popupState, configuration)));
		});
	};
}

/**
 *  REQUEST_ADDBASKETITEMCONFIGURATION
 */
function requestAddBasketItemConfiguration(popupState) {
	return {
		type: REQUEST_ADDBASKETITEMCONFIGURATION,
		popupState,
	};
}

export function receiveAddBasketItemConfiguration(data, popupState) {
	return {
		type: RECEIVE_ADDBASKETITEMCONFIGURATION,
		data,
		popupState,
	};
}

export function addBasketItemConfiguration(
	popupState,
	addressID,
	deliveryDate,
	reference,
	expressDelivery,
	configurationQuantity
) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl == null) {
			console.error("Can't find BasketDispatchUrl in extension!");
			return;
		}

		return dispatch((dispatch) => {
			dispatch(requestAddBasketItemConfiguration(popupState));

			let body = 'addConfiguration=addConfiguration';
			body += '&SynchronizerToken=' + synchronizerToken;
			body += '&AddBasketConfiguration_LineID=' + popupState.id;

			if (addressID) {
				body += '&AddBasketConfiguration_AddressID=' + addressID;
			}
			if (deliveryDate) {
				body += '&AddBasketConfiguration_Date=' + deliveryDate;
			}
			if (reference) {
				body += '&AddBasketConfiguration_Reference=' + reference;
			}
			if (expressDelivery) {
				body += '&AddBasketConfiguration_ExpressDelivery=' + expressDelivery;
			}
			if (configurationQuantity) {
				body += '&AddBasketConfiguration_ConfigurationQuantity=' + configurationQuantity;
			}

			return fetch(`${basketDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
				.then(getJsonOrRedirect)
				.then((data) => dispatch(receiveAddBasketItemConfiguration(data, popupState)));
		});
	};
}

/**
 *  CONFIGURATION DATA
 */
function requestConfigurationData() {
	return {
		type: REQUEST_CONFIGURATION_DATA,
	};
}

export function receiveConfigurationData(data) {
	return {
		type: RECEIVE_CONFIGURATION_DATA,
		data,
	};
}

export function getConfigurationData(sku) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl == null) {
			console.error("Can't find BasketDispatchUrl in extension!");
			return;
		}

		let body = 'getConfigurationData=getConfigurationData';
		body += '&SynchronizerToken=' + synchronizerToken;
		body += '&ViewBasketConfiguration_SKU=' + sku;

		return dispatch((dispatch) => {
			dispatch(requestConfigurationData());
			return fetch(`${basketDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
				.then(getJsonOrRedirect)
				.then((data) => dispatch(receiveConfigurationData(data)));
		});
	};
}

/**
 * ADD TO BASKET
 */
let basketItemProgressHandle = null;
export function addSparepartToBasketBySku(sparepart, quantity) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl) {
			dispatch(requestAddSparepartToBasket(sparepart, quantity));

			const body =
				'addProduct=addProduct&SKU=' +
				sparepart.sku +
				'&Quantity_' +
				sparepart.sku +
				'=' +
				quantity +
				'&SynchronizerToken=' +
				synchronizerToken;
			return fetch(`${basketDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
				.then(getJsonOrRedirect)
				.then((json) => {
					dispatch(receiveAddSparepartToBasket(basketDispatchUrl, sparepart, json));
					clearTimeout(basketItemProgressHandle);
					basketItemProgressHandle = setTimeout(() => {
						dispatch(receiveClearBasketItemProgress());
					}, 3000);
				});
		} else {
			console.error("Can't find BasketDispatchUrl in extension!");
		}
	};
}

export function addRelatedSparepartToBasket(product, quantity, listType) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		dispatch(requestAddRelatedSparepartToBasket());

		let list = 'Spareparts - Related';
		//add detail-followuparticles /  detail-accessoryarticles / detail-alternativearticles
		list += ' - ' + listType;

		const body =
			'addProduct=addProduct&SKU=' +
			product.sku +
			'&Quantity_' +
			product.sku +
			'=' +
			quantity +
			'&SynchronizerToken=' +
			synchronizerToken;

		return fetch(`${basketDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveAddRelatedSparepartToBasket(product, json, list)));
	};
}

/**
 *  PRODUCTDETAIL ACTION
 */
function requestUpdateFavoriteProduct(index) {
	return {
		type: REQUEST_UPDATEFAVORITEPRODUCT,
		index,
	};
}

export function receiveUpdateFavoriteProduct(json, sku, favorite) {
	return {
		type: RECEIVE_UPDATEFAVORITEPRODUCT,
		data: json,
		sku,
		favorite,
	};
}

export function requestGetProductDetailData() {
	return {
		type: REQUEST_GETPRODUCTDETAIL_DATA,
	};
}

export function receiveGetProductDetailData(json) {
	return {
		type: RECEIVE_GETPRODUCTDETAIL_DATA,
		data: json,
	};
}

export function getProductDetailData(sku) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const dispatchUrl = extensions.ListerDispatchUrl;

		if (dispatchUrl == null) {
			console.error("Can't find ListerDispatchUrl in extension!");
			return;
		}

		dispatch(requestGetProductDetailData());

		const params =
			'&getProductDetailData=getProductDetailData&SKU=' +
			sku +
			'&SynchronizerToken=' +
			synchronizerToken;
		const url = dispatchUrl + params;

		return fetch(`${url}`, {
			method: 'get',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveGetProductDetailData(json)));
	};
}

export function receiveClearProductDetailData() {
	return {
		type: CLEAR_PRODUCTDETAIL_DATA,
	};
}

export function clearProductDetailData() {
	return (dispatch, getState) => {
		dispatch(receiveClearProductDetailData());
	};
}

export function receiveSetActiveSparepart(sparepart) {
	return {
		type: SET_ACTIVE_SPAREPART,
		sparepart: sparepart,
	};
}

export function setActiveSparepart(sparepart) {
	return (dispatch, getState) => {
		dispatch(receiveSetActiveSparepart(sparepart));
	};
}

export function updateFavoriteProduct(sku, favorite, index) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const listerDispatchUrl = extensions.ListerDispatchUrl;

		dispatch(requestUpdateFavoriteProduct());

		const body =
			'updateFavoriteProduct=updateFavoriteProduct&SKU=' +
			sku +
			'&favorite=' +
			favorite +
			'&SynchronizerToken=' +
			synchronizerToken;

		return fetch(`${listerDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveUpdateFavoriteProduct(json, sku, favorite)));
	};
}

/**
 *  REQUEST_CONFIGURATION_CLOSE
 */
export function requestCloseBasketItemConfiguration() {
	return {
		type: REQUEST_CLOSEBASKETITEMCONFIGURATION,
	};
}

export function closeBasketItemConfiguration() {
	return (dispatch) => {
		return dispatch((dispatch) => {
			dispatch(requestCloseBasketItemConfiguration());
		});
	};
}

/**
 * 	ADD TO DROPSHIPMENT BASKET
 */
function addProductToDropshipmentBasket(
	sku,
	groupindex,
	index,
	quantity,
	dispatchUrl,
	synchronizerToken
) {
	return (dispatch) => {
		dispatch(requestAddProductToDropshipmentBasket(index));

		const body =
			'addDropshipmentProduct=addDropshipmentProduct&SKU=' +
			sku +
			'&Quantity_' +
			sku +
			'=' +
			quantity +
			'&SynchronizerToken=' +
			synchronizerToken;

		return fetch(`${dispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getJsonOrRedirect)
			.then((json) =>
				dispatch(receiveAddProductToDropshipmentBasket(dispatchUrl, groupindex, index, json))
			);
	};
}

export function addProductToDropshipmentBasketBySku(sku, index, quantity, groupindex) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl) {
			return dispatch(
				addProductToDropshipmentBasket(
					sku,
					groupindex,
					index,
					quantity,
					basketDispatchUrl,
					synchronizerToken
				)
			);
		} else {
			console.error("Can't find BasketDispatchUrl in extension!");
		}
	};
}
