import React, { FC } from 'react';
import DirectOrderContainer from './container/directOrderContainer';
import { IDirectOrderState } from './container/types';
import { IData } from './types';
import ErrorBoundary from 'directorder/components/errorBoundary';
import createConnect from './container/connect';

interface OwnProps {}

interface StateProps {
	localizations: object;
	directorder: IDirectOrderState;
	data: IData;
}

interface DispatchProps {
	fetchDirectOrders: any;
}

type IProps = StateProps & DispatchProps & OwnProps;

const DirectOrders: FC<IProps> = (props) => {
	const { directorder, localizations } = props;
	const { orders } = directorder;

	return (
		<div className='bgcolor-color01 content-subwrap'>
			<header className='block-head border-light'>
				<h1 className='head head4 no-wrap upper'>
					{localizations['common.account.directorder.header.text']}
				</h1>
			</header>
			<ErrorBoundary
				message={localizations['common.account.directorder.error.text']}
				buttonText={localizations['common.account.directorder.tryagain.text']}
			>
				<DirectOrderContainer orders={orders} />
			</ErrorBoundary>
		</div>
	);
};


const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(DirectOrders);
