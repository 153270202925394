import { ISearchState, SearchActions, SearchActionTypes } from 'shared/general/searchbar/types';
import produce from 'immer';

const initialSearchState: ISearchState = {
	isFetching: false,
	result: undefined,
	autocomplete: undefined,
};

export const searchReducer = (
	state = initialSearchState,
	action: SearchActionTypes
): ISearchState => {
	switch (action.type) {
		case SearchActions.RECEIVE_AUTOCOMPLETE: {
			const { autocomplete, result } = action;

			const newState = produce(state, (draft) => {
				if (result) {
					draft.isFetching = false;
					draft.result = result;
					draft.autocomplete = undefined;
					if (!result.error) {
						draft.autocomplete = autocomplete;
					}
				}
			});

			return newState;
		}

		case SearchActions.REQUEST_AUTOCOMPLETE: {
			const newState = produce(state, (draft) => {
				draft.isFetching = true;
				draft.result = undefined;
				//draft.autocomplete = undefined;
			});
			return newState;
		}

		default: {
			return state;
		}
	}
};
