import React, { FC, useState } from 'react';
import { IDirectOrderState } from './types';
import DirectOrderItem from '../components/directOrderItem';
import ItemBoundary from 'directorder/components/itemBoundary';
import createConnect from './connect';
import DirectOrder from 'directorder/objects/DirectOrder';
import Progress from 'shared/components/Progress';

interface OwnProps {
	orders: DirectOrder[];
}

interface StateProps {
	directorder: IDirectOrderState;
}

interface DispatchProps {
	init: Function;
}

type IProps = StateProps & DispatchProps & OwnProps;


export const DirectOrderContainer: FC<IProps> = (props) => {
	const { directorder } = props;
	const { init } = props;
	const { orders } = props; // dummy data needs to be replaced by 'directOrderLines' below
	const { isFetching } = directorder;
	const [openOrder, setOpenOrder] = useState('');

	const updateOpenOrder = (orderID: string) => {
		//call init on every toggle to clear IHTTPResult states
		init();
		setOpenOrder(orderID);
	};

	return (
		<section className='list directorders scroll-b5'>
			{orders.map((order, index) => (
				<ItemBoundary key={'IB' + index} wrap={true}>
					<DirectOrderItem
						key={index}
						order={order}
						openOrder={openOrder}
						setOpenOrder={updateOpenOrder}
					/>
				</ItemBoundary>
			))}
			{isFetching && (
				<>
					<Progress status={'waiting'} />
				</>
			)}
		</section>
	);
};


const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(DirectOrderContainer);
