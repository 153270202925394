import {
	IHeaderMainMenuState,
	MainMenuActions,
	MainMenuActionTypes,
} from 'shared/general/headermainmenu/types';
import produce from 'immer';

const initialState: IHeaderMainMenuState = {
	isFetching: false,
	result: undefined,
	menuData: undefined,
};

export function headermainmenuReducer(menuData) {
	const componentInitialState: IHeaderMainMenuState = Object.assign({}, initialState, {
		menuData: menuData,
	});
	function headermainmenuComponent(
		state = componentInitialState,
		action: MainMenuActionTypes
	): IHeaderMainMenuState {
		return headermainmenu(state, action);
	}
	return headermainmenuComponent;
}

const headermainmenu = (
	state = initialState,
	action: MainMenuActionTypes
): IHeaderMainMenuState => {
	switch (action.type) {
		case MainMenuActions.REQUEST_MAINMENU: {
			const newState = produce(state, (draft) => {
				draft.isFetching = true;
				draft.result = undefined;
			});
			return newState;
		}

		case MainMenuActions.RECEIVE_MAINMENU: {
			const { menuData, result } = action;

			const newState = produce(state, (draft) => {
				if (result) {
					draft.isFetching = false;
					draft.result = result;

					if (!result.error) {
						if (!menuData) {
							return;
						}
						//this should filter responses that are incorrectly cached
						if (draft.menuData?.customerNumber != menuData.customerNumber) {
							return;
						}

						draft.menuData.headerMainItems = menuData.headerMainItems;
						draft.menuData.loaded = true;

						//NOTE: do not update the following properties, "current" implies mutable state here, the menuData is a cached response and will override this state
						//draft.menuData.currentCatalogName;
						//draft.menuData.currentCategoryName;
					}
				}
			});

			return newState;
		}

		default: {
			return state;
		}
	}
};
