import React, { FC } from 'react';
import { IBreakpoints, ISizes, IImage } from 'shared/types';

interface ISizeDefinition {
	tiny: ISizeDefinitionEntry;
	small: ISizeDefinitionEntry;
	medium: ISizeDefinitionEntry;
	large: ISizeDefinitionEntry;
	huge: ISizeDefinitionEntry;
	zoom1: ISizeDefinitionEntry;
	zoom2: ISizeDefinitionEntry;
	zoom3: ISizeDefinitionEntry;
}

interface ISizeDefinitionEntry {
	normalImage: string;
	retinaImage: string;
}

const sizeDefinitions: ISizeDefinition = {
	tiny: { normalImage: '79x79', retinaImage: '158x158' },
	small: { normalImage: '117x117', retinaImage: '234x234' },
	medium: { normalImage: '196x196', retinaImage: '392x392' },
	large: { normalImage: '320x320', retinaImage: '640x640' },
	huge: { normalImage: '479x479', retinaImage: '958x958' },
	zoom1: { normalImage: '767x767', retinaImage: '1534x1534' },
	zoom2: { normalImage: '1534x1534', retinaImage: '2400x2400' },
	zoom3: { normalImage: '2400x2400', retinaImage: '4800x4800' },
};

interface IProps {
	images: IImage[];
	b1: ISizes;
	b2?: ISizes;
	b3?: ISizes;
	b4?: ISizes;
	b5?: ISizes;
	b6?: ISizes;
	b7?: ISizes;
}

const ProductImage: FC<IProps> = (props) => {
	const { images } = props;
	const { b1 /*required*/, b2, b3, b4, b5, b6, b7 } = props;

	const getImage = (dimension: string) => {
		return images.find(function(image) {
			return image.name == dimension;
		});
	};

	const getDefinition = (size: ISizes) => {
		let sizeDefinition: ISizeDefinitionEntry = sizeDefinitions[size];
		return sizeDefinition;
	};

	const createSource = (size: ISizes, breakPoint: IBreakpoints) => {
		let sizeDefinition = getDefinition(size);
		let normalImg = getImage(sizeDefinition.normalImage);
		let retinaImg = getImage(sizeDefinition.retinaImage);
		if (normalImg && retinaImg) {
			return (
				<source
					srcSet={normalImg.imageLink + ', ' + retinaImg.imageLink + ' 2x'}
					media={`(min-width: ${breakPoint})`}
				/>
			);
		} else {
			return null;
		}
	};

	const createBase = (size: ISizes) => {
		let sizeDefinition = getDefinition(size);
		let normalImg = getImage(sizeDefinition.normalImage);
		if (normalImg) {
			return <img src={normalImg.imageLink} alt={normalImg.altText} />;
		} else {
			return (
				<img
					src={`/INTERSHOP/static/WFS/kruitbosch-kruitboschb2b-Site/-/kruitbosch/nl_NL/not_available_${sizeDefinition.normalImage}.png`}
					alt='Not available'
				/>
			);
		}
	};

	const productImgElement = (
		<picture>
			{b1 && createSource(b1, IBreakpoints.b1)}
			{b2 && createSource(b2, IBreakpoints.b2)}
			{b3 && createSource(b3, IBreakpoints.b3)}
			{b4 && createSource(b4, IBreakpoints.b4)}
			{b5 && createSource(b5, IBreakpoints.b5)}
			{b6 && createSource(b6, IBreakpoints.b6)}
			{b7 && createSource(b7, IBreakpoints.b7)}
			{b1 && createBase(b1)}
		</picture>
	);

	return productImgElement;
};

ProductImage.defaultProps = {
	b1: ISizes.large,
};

export default ProductImage;
