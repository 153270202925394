import {
	REQUEST_NOTFICATIONS,
	RECEIVE_NOTFICATIONS,
	REQUEST_ACCOUNTSWITCH,
	RECEIVE_ACCOUNTSWITCH,
} from 'shared/general/actions';

import {
	RECEIVE_UPDATEFAVORITEPRODUCT,
	RECEIVE_ADDPRODUCTTOBASKET,
	RECEIVE_ADDPRODUCTTODROPSHIPMENTBASKET,
	RECEIVE_ADDRELATEDPRODUCTTOBASKET,
	RECEIVE_ADDBASKETITEMCONFIGURATION,
	RECEIVE_ADDBUNDLETOBASKET,
	//RECEIVE_ADDPRODUCTTOBASKET_WITHREFERENCE,
} from 'productlister/actions';

import {
	RECEIVE_ADDSPAREPARTTOBASKET,
	RECEIVE_ADDRELATEDSPAREPARTTOBASKET,
} from '../../../sparepartslister/actions';

import {
	RECEIVE_UPDATEPRODUCTQUANTITY,
	RECEIVE_REMOVEPRODUCTFROMBASKET,
	RECEIVE_MOVETOBASKET,
} from 'dropshipment/components/item/BasketItemActions';

import { RECEIVE_MOVETODROPSHIPMENT } from 'basket/basket/item/BasketItemActions';

import { MessageActions } from 'messages/types';

import { ProductActions } from 'productblock/container/types';

import { WishListActions } from 'wishlist/ActionTypes';

import { OrderAdvicesActions } from 'orderadvices/types';

import { BasketActions } from 'shared/reduceractions/Basket';

import { BasketActions as SharedBasketActions } from 'shared/reduceractions/Basket';

import { DirectOrderActions } from 'directorder/container/types';

import Cookies from 'js-cookie';

import { PROGRESS_RESULT } from '../types';

import produce from 'immer';

export function notificationReducer(state = {}, action) {
	switch (action.type) {
		case RECEIVE_ADDPRODUCTTOBASKET:
		case RECEIVE_ADDRELATEDPRODUCTTOBASKET:
		case RECEIVE_ADDBUNDLETOBASKET:
		case RECEIVE_ADDBASKETITEMCONFIGURATION:
		case RECEIVE_ADDSPAREPARTTOBASKET:
		case RECEIVE_ADDRELATEDSPAREPARTTOBASKET:
		case RECEIVE_UPDATEPRODUCTQUANTITY:
		case RECEIVE_REMOVEPRODUCTFROMBASKET:
		case DirectOrderActions.RECEIVE_ADDORDERTOBASKET:
		case ProductActions.RECEIVE_ADDTOBASKET:
		case WishListActions.RECEIVE_ADDPRODUCTTOBASKET:
		case MessageActions.RECEIVE_MESSAGE_UPDATE:
		case OrderAdvicesActions.RECEIVE_ADDPRODUCTTOBASKET_WITHREFERENCE:
		case BasketActions.RECEIVE_BASKET_ADDTOBASKET:
		case SharedBasketActions.RECEIVE_BASKET_ADDTOBASKET:
		case SharedBasketActions.RECEIVE_BASKET_ADDBUNDLETOBASKET: {
			let newState = Object.assign({}, state, {
				outdated: true,
				basket: Object.assign({}, state.basket, {
					status: PROGRESS_RESULT.WAITING,
				}),
			});
			return newState;
		}

		case RECEIVE_UPDATEFAVORITEPRODUCT:
		case WishListActions.RECEIVE_REMOVEFROMWISHLIST: {
			let newState = Object.assign({}, state, {
				outdated: true,
				wishlist: Object.assign({}, state.wishlist, {
					status: PROGRESS_RESULT.WAITING,
				}),
			});
			return newState;
		}

		case RECEIVE_ADDPRODUCTTODROPSHIPMENTBASKET: {
			let newState = Object.assign({}, state, {
				outdated: true,
				dropshipment: Object.assign({}, state.dropshipment, {
					status: PROGRESS_RESULT.WAITING,
				}),
			});
			return newState;
		}

		case RECEIVE_MOVETODROPSHIPMENT:
		case RECEIVE_MOVETOBASKET: {
			let newState = Object.assign({}, state, {
				outdated: true,
				basket: Object.assign({}, state.basket, {
					status: PROGRESS_RESULT.WAITING,
				}),
				dropshipment: Object.assign({}, state.dropshipment, {
					status: PROGRESS_RESULT.WAITING,
				}),
			});
			return newState;
		}

		case REQUEST_ACCOUNTSWITCH:
		case REQUEST_NOTFICATIONS: {
			let newState = Object.assign({}, state, {
				waiting: true,
			});
			return newState;
		}

		case RECEIVE_ACCOUNTSWITCH: {
			let newState = Object.assign({}, state, {
				waiting: false,
			});
			return newState;
		}

		case RECEIVE_NOTFICATIONS: {
			const { data, status } = action;

			const newState = produce(state, (draft) => {
				if (data) {
					draft.waiting = false;
					draft.outdated = false;
					draft.basket = data.basket;
					draft.dropshipment = data.dropshipment;
					draft.wishlist = data.wishlist;
					draft.message = data.message;
					if (data.sidebar) {
						draft.sidebar = data.sidebar;
					}
					let showMessage = false;
					const message = data.basket.message;
					if (
						message &&
						Cookies.get('dealerportal_freight_message') !== message &&
						window['__MASTER_REACT_APP__'] !== 'basketContainer'
					) {
						Cookies.set('dealerportal_freight_message', message, {
							path: '/',
							secure: true,
							sameSite: 'strict',
						});
						showMessage = true;
					}
					draft.showMessage = showMessage;
				}
				if (state.basket) {
					draft.basket.status =
						state.basket.status === PROGRESS_RESULT.WAITING ? status : PROGRESS_RESULT.NONE;
				}
				if (state.dropshipment) {
					draft.dropshipment.status =
						state.dropshipment.status === PROGRESS_RESULT.WAITING ? status : PROGRESS_RESULT.NONE;
				}
				if (state.wishlist) {
					draft.wishlist.status =
						state.wishlist.status === PROGRESS_RESULT.WAITING ? status : PROGRESS_RESULT.NONE;
				}
				if (state.message) {
					draft.message.status =
						state.message.status === PROGRESS_RESULT.WAITING ? status : PROGRESS_RESULT.NONE;
				}
				if (state.sidebar) {
					draft.sidebar.status =
						state.sidebar.status === PROGRESS_RESULT.WAITING ? status : PROGRESS_RESULT.NONE;
				}
			});

			return newState;
		}

		default: {
			return state;
		}
	}
}
