import React, { FC, useState, useEffect } from 'react';
import Image from 'returnorders/objects/Image';
import { productTypeCodes } from 'returnorders/types';

interface IProps {
	sku: string;
	altText: string;
	images?: Image[];
	productTypeCode?: string;
}

const Picture: FC<IProps> = (props) => {
	const { sku, altText, images, productTypeCode } = props;

	const getImages = (sku: string) => {
		let filteredSku: string = sku.replace(/ /g, '');

		if (images?.length) {
			return {
				79: `${images[0].imageLink}`,
				196: `${images[0].imageLink.replace('s:79:79', 's:196:196')}`,
				117: `${images[0].imageLink.replace('s:79:79', 's:117:117')}`,
				158: `${images[0].imageLink.replace('s:79:79', 's:158:158')}`,
				392: `${images[0].imageLink.replace('s:79:79', 's:392:392')}`,
				234: `${images[0].imageLink.replace('s:79:79', 's:234:234')}`,
			};
		} else {
			return {
				79: `https://kruitbosch.xcdn.nl/${filteredSku}.jpg?d=2&f=s:79:79:0:1/bg:ffffff/q:60`,
				196: `https://kruitbosch.xcdn.nl/${filteredSku}.jpg?d=2&f=s:196:196:0:1/bg:ffffff/q:60`,
				117: `https://kruitbosch.xcdn.nl/${filteredSku}.jpg?d=2&f=s:117:117:0:1/bg:ffffff/q:60`,
				158: `https://kruitbosch.xcdn.nl/${filteredSku}.jpg?d=2&f=s:158:158:0:1/bg:ffffff/q:60`,
				392: `https://kruitbosch.xcdn.nl/${filteredSku}.jpg?d=2&f=s:392:392:0:1/bg:ffffff/q:60`,
				234: `https://kruitbosch.xcdn.nl/${filteredSku}.jpg?d=2&f=s:234:234:0:1/bg:ffffff/q:60`,
			};
		}
	};

	const [urls, setUrls] = useState(() => getImages(sku));

	const handleError = (event: any) =>
		setUrls({
			79: `https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:79:79:0:1/bg:ffffff/q:60`,
			196: `https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:196:196:0:1/bg:ffffff/q:60`,
			117: `https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:117:117:0:1/bg:ffffff/q:60`,
			158: `https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:158:158:0:1/bg:ffffff/q:60`,
			392: `https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:392:392:0:1/bg:ffffff/q:60`,
			234: `https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:234:234:0:1/bg:ffffff/q:60`,
		});

	useEffect(() => {
		setUrls(getImages(sku));
	}, [sku, images]); // Added images to dependency array

	if (productTypeCode === productTypeCodes.service) {
		return (
			<picture>
				<source
					srcSet='https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:79:79:0:1/bg:ffffff/q:60, https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:158:158:0:1/bg:ffffff/q:60 2x'
					media='(min-width: 1024px)'
				/>
				<source
					srcSet='https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:196:196:0:1/bg:ffffff/q:60, https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:392:392:0:1/bg:ffffff/q:60 2x'
					media='(min-width: 480px)'
				/>
				<source srcSet='https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:117:117:0:1/bg:ffffff/q:60, https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:234:234:0:1/bg:ffffff/q:60 2x' />

				<img
					src='https://kruitbosch.xcdn.nl/no-image.jpg?d=2&f=s:79:79:0:1/bg:ffffff/q:60'
					alt={productTypeCodes.service}
				/>
			</picture>
		);
	}

	if (sku !== '') {
		return (
			<picture>
				<source srcSet={`${urls[79]}, ${urls[158]} 2x`} media='(min-width: 1024px)' />
				<source srcSet={`${urls[196]}, ${urls[392]} 2x`} media='(min-width: 480px)' />
				<source srcSet={`${urls[117]}, ${urls[234]} 2x`} />

				<img src={`${urls[79]}`} onError={handleError} alt={altText} />
			</picture>
		);
	} else {
		return null;
	}
};

export default Picture;
