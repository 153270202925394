import { Product } from './Product';
import { Price } from './DirectOrder';

export default class DirectOrderLine {
	public constructor(init?: Partial<DirectOrderLine>) {
		Object.assign(this, init);
	}

	sku: string = '';
	id: string = '';
	price: Price = new Price();

	isLoaded: boolean = false;
	product?: Product = undefined;
}
