import React, { FC } from 'react';
import Alert from 'returnorders/forms/alert';

interface OwnProps {
	finishOrderResult: any;
	deliverFromOwnSupplyResult: any;
	addToBasketResult: any;
	doNotDeliverResult: any;
}

interface StateProps {
	localizations: any;
}

type IProps = OwnProps & StateProps;

export const DirectOrderItemAlerts: FC<IProps> = (props) => {
	const {
		finishOrderResult,
		deliverFromOwnSupplyResult,
		addToBasketResult,
		doNotDeliverResult,
		localizations,
	} = props;

	const getAlertElement = (localization: string, state) => {
		if (state) {
			return <Alert state={state} message={localization} />;
		} else {
			return <Alert message={localization} />;
		}
	};

	const showFinishOrderResult = () => {
		if (finishOrderResult?.error) {
			return (
				<Alert
					message={localizations['common.account.directorder.button.action.finishorder.error.text']}
				/>
			);
		}
		if (finishOrderResult && !finishOrderResult.error) {
			return (
				<Alert
					message={
						localizations['common.account.directorder.button.action.finishorder.success.text']
					}
					state={'positive'}
				/>
			);
		}
		return null;
	};

	const showDeliverFromOwnSupplyResult = () => {
		if (deliverFromOwnSupplyResult?.error) {
			return (
				<Alert
					message={
						localizations['common.account.directorder.button.action.supplyfrominventory.error.text']
					}
				/>
			);
		}

		if (deliverFromOwnSupplyResult && !deliverFromOwnSupplyResult.error) {
			return (
				<Alert
					message={
						localizations[
							'common.account.directorder.button.action.supplyfrominventory.success.text'
						]
					}
					state={'positive'}
				/>
			);
		}

		return null;
	};

	const showAddToBasketResult = () => {
		if (addToBasketResult?.error) {
			if (addToBasketResult?.json?.errorMessages[0] === 'HANDLING_EXISTING_BASKETITEM') {
				return (
					<Alert
						message={
							localizations[
								'common.account.directorder.button.action.tobasket.error.existingbasket.text'
							]
						}
						state={'warning'}
					/>
				);
			} else {
				<Alert
					message={
						localizations['common.account.directorder.button.action.supplyfrominventory.error.text']
					}
				/>;
			}
		}

		if (addToBasketResult && !addToBasketResult.error) {
			<Alert
				message={
					localizations['common.account.directorder.button.action.supplyfrominventory.success.text']
				}
				state={'positive'}
			/>;
		}

		return null;
	};

	const showDoNotDeliverResult = () => {
		if (doNotDeliverResult && doNotDeliverResult.error) {
			return (
				<Alert
					message={localizations['common.account.directorder.button.action.donotsupply.error.text']}
				/>
			);
		}

		if (doNotDeliverResult && !doNotDeliverResult.error) {
			return (
				<Alert
					message={
						localizations['common.account.directorder.button.action.donotsupply.success.text']
					}
					state={'positive'}
				/>
			);
		}

		return null;
	};

	return (
		<>
			{showFinishOrderResult()}
			{showDeliverFromOwnSupplyResult()}
			{showAddToBasketResult()}
			{showDoNotDeliverResult()}
		</>
	);
};

export default DirectOrderItemAlerts;
