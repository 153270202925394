import fetch from 'isomorphic-fetch';
import { pushUpdateToFavorite } from 'shared/libs/gaevents';

/**
 * BASKET ACTION CONSTANTS
 */
export const RECEIVE_CLEARBASKETITEMPROGRESS = 'RECEIVE_CLEARBASKETITEMPROGRESS';

export const REQUEST_REMOVEPRODUCTFROMBASKET = 'REQUEST_REMOVEPRODUCTFROMBASKET';
export const RECEIVE_REMOVEPRODUCTFROMBASKET = 'RECEIVE_REMOVEPRODUCTFROMBASKET';

export const REQUEST_UPDATEPRODUCTQUANTITY = 'REQUEST_UPDATEPRODUCTQUANTITY';
export const RECEIVE_UPDATEPRODUCTQUANTITY = 'RECEIVE_UPDATEPRODUCTQUANTITY';

export const REQUEST_MOVETOBASKET = 'REQUEST_MOVETOBASKET';
export const RECEIVE_MOVETOBASKET = 'RECEIVE_MOVETOBASKET';

export const REQUEST_UPDATEFAVORITEPRODUCT = 'REQUEST_UPDATEFAVORITEPRODUCT';
export const RECEIVE_UPDATEFAVORITEPRODUCT = 'RECEIVE_UPDATEFAVORITEPRODUCT';

let basketItemProgressHandle = null;
function receiveClearBasketItemProgress() {
	return {
		type: RECEIVE_CLEARBASKETITEMPROGRESS,
	};
}

/**
 *  BASKET UPDATE PRODUCT ACTIONS
 */
function requestUpdateProductQuantity(sku) {
	return {
		type: REQUEST_UPDATEPRODUCTQUANTITY,
		id: sku,
	};
}

function receiveUpdateProductQuantity(sku, json) {
	return {
		type: RECEIVE_UPDATEPRODUCTQUANTITY,
		id: sku,
		data: json,
	};
}

export function updateProductQuantity(sku, quantity) {
	return (dispatch, getState) => {
		dispatch(requestUpdateProductQuantity(sku));

		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		const body =
			'updateProductQuantity=updateProductQuantity&SKU=' +
			sku +
			'&Quantity_' +
			sku +
			'=' +
			quantity +
			'&SynchronizerToken=' +
			synchronizerToken;
		return fetch(`${basketDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => {
				dispatch(receiveUpdateProductQuantity(sku, json));

				clearTimeout(basketItemProgressHandle);
				basketItemProgressHandle = setTimeout(() => {
					dispatch(receiveClearBasketItemProgress());
				}, 3000);
			});
	};
}

/**
 *  BASKET REMOVE PRODUCT ACTIONS
 */
function requestRemoveProductFromBasket(index) {
	return {
		type: REQUEST_REMOVEPRODUCTFROMBASKET,
		index,
	};
}

function receiveRemoveProductFromBasket(dispatchUrl, index, json) {
	return {
		type: RECEIVE_REMOVEPRODUCTFROMBASKET,
		dispatchUrl,
		index,
		data: json,
	};
}

function removeProductFromBasket(id, index, dispatchUrl, synchronizerToken) {
	return (dispatch) => {
		dispatch(requestRemoveProductFromBasket(index));

		const body = 'removeProduct=removeProduct&id=' + id + '&SynchronizerToken=' + synchronizerToken;
		return fetch(`${dispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => dispatch(receiveRemoveProductFromBasket(dispatchUrl, index, json)));
	};
}

export function removeProductFromBasketById(id, index) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;
		if (basketDispatchUrl) {
			return dispatch(removeProductFromBasket(id, index, basketDispatchUrl, synchronizerToken));
		} else {
			console.error("Can't find BasketDispatchUrl in extension!");
		}
	};
}

/**
 *  REQUEST_MOVETOBASKET
 */
function requestMoveToBasket(sku, lineID) {
	return {
		type: REQUEST_MOVETOBASKET,
		id: sku,
		lineID: lineID,
	};
}

function receiveMoveToBasket(sku, lineID, removed, json) {
	return {
		type: RECEIVE_MOVETOBASKET,
		id: sku,
		lineID: lineID,
		removed: removed,
		data: json,
	};
}

export function moveToBasket(sku, lineID, removed = false) {
	return (dispatch, getState) => {
		// if (removed) {
		//     return dispatch(receiveMoveToBasket(sku, lineID, removed, null));
		// }

		dispatch(requestMoveToBasket(sku, lineID));

		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;
		const body =
			'moveToBasket=moveToBasket&LineID=' + lineID + '&SynchronizerToken=' + synchronizerToken;
		return fetch(`${basketDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => {
				dispatch(receiveMoveToBasket(sku, lineID, removed, json));
				setTimeout(() => {
					dispatch(receiveMoveToBasket(sku, lineID, true, null));
				}, 500);
			});
	};
}

/**
 *  PRODUCT FAVORITE
 */
function requestUpdateFavoriteProduct() {
	return {
		type: REQUEST_UPDATEFAVORITEPRODUCT,
	};
}

export function receiveUpdateFavoriteProduct(lineID, json, favorite) {
	return {
		type: RECEIVE_UPDATEFAVORITEPRODUCT,
		lineID,
		data: json,
		favorite,
	};
}

export function updateFavoriteProduct(productLine, favorite) {
	return (dispatch, getState) => {
		const { id, sku } = productLine;
		const { extensions, synchronizerToken } = getState();
		const listerDispatchUrl = extensions.ListerDispatchUrl;

		dispatch(requestUpdateFavoriteProduct(id));

		const body =
			'updateFavoriteProduct=updateFavoriteProduct&SKU=' +
			sku +
			'&favorite=' +
			favorite +
			'&SynchronizerToken=' +
			synchronizerToken;

		return fetch(`${listerDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => dispatch(receiveUpdateFavoriteProduct(id, json, favorite)))
			.then((json) => pushUpdateToFavorite(productLine, favorite));
	};
}
