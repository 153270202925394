import React, { Fragment, ReactNode } from 'react';
import Alert from 'returnorders/forms/alert';
import Button from 'shared/formelements/button';
import Box from 'returnorders/components/box/box';
import LoadingElement from 'shared/components/LoadingElement';
import { Mug } from 'react-kawaii';

interface OwnProps {
	children: ReactNode;
	message: string;
	buttonText: string;
}

interface StateProps {}

interface DispatchProps {}

type IProps = StateProps & DispatchProps & OwnProps;

interface IState {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidUpdate = (prevProps: IProps, prevState: IState): any => {
		if (prevState.hasError && this.state.hasError && prevProps.children != this.props.children) {
			this.onTryAgain();
		}
	};

	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
	}

	onTryAgain() {
		this.setState({ hasError: false });
	}

	render() {
		const { message, buttonText } = this.props;

		if (this.state.hasError) {
			return (
				<div className='p-2x'>
					<div>
						<Mug size={200} mood='blissful' color='#bf5426' />
						<Alert message={message} />
						<Button
							attributes={{
								type: 'button',
								onClick: this.onTryAgain.bind(this),
							}}
							text={buttonText}
						/>
					</div>
				</div>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
