import DirectOrderLine from './DirectOrderLine';
import { EPlatform, EDirectOrderState, EProgressStatus } from 'directorder/container/types';
import { IOrderRemarks } from 'directorder/messages/messages';

export default class DirectOrder {
	public constructor(init?: Partial<DirectOrder>) {
		Object.assign(this, init);

		this.isProductsLoaded = false;
		this.isRemarksLoaded = false;

		if (init && init.orderLines) {
			let orderLines: DirectOrderLine[] = init.orderLines.map((line) => new DirectOrderLine(line));
			this.orderLines = orderLines;
		}
	}

	id: string = '';
	state: EDirectOrderState = EDirectOrderState.open;
	progressStatus: EProgressStatus = EProgressStatus.none;
	orderNumber: string = '';
	platform: EPlatform = EPlatform.unknown;
	date: string = '';
	dealerDeliveryDate: string = '';
	remainingDays: number = 0;
	orderLines: DirectOrderLine[] = [];
	totalPrice: Price = new Price();
	customer: Customer = new Customer();
	customerNote: string = '';
	tradeInBike: boolean = false;

	isProductsLoaded: boolean = false;
	isRemarksLoaded: boolean = false;
	orderRemarks?: IOrderRemarks = undefined;

	getChannel = () => {
		return this.platform.replace('kruitbosch-', '');
	};
}

export class Customer {
	public constructor(init?: Partial<Customer>) {
		Object.assign(this, init);
	}
	name: string = '';
	email: string = '';
	phone: string = '';
	address: string = '';
	postalCode: string = '';
	city: string = '';
}

export class Price {
	public constructor(init?: Partial<Customer>) {
		Object.assign(this, init);
	}
	discountPercentage: number = 0;
	fraction: string = '';
	name: string = '';
	quantityFrom: number = 0;
	whole: string = '';
}
