export default class AutoComplete {
	public constructor(init?: Partial<AutoComplete>) {
		Object.assign(this, init);
	}
	searchSuggestions: SearchSuggestion[] = [];
	productSuggestions: ProductSuggestion[] = [];
	categorySuggestions: CategorySuggestion[] = [];
}

export class ProductSuggestion {
	sku: string = '';
	name: string = '';
	link: string = '';
	image?: string = '';
}

export class CategorySuggestion {
	name: string = '';
	link: string = '';
	match: string = '';
}

export class SearchSuggestion {
	title: string = '';
	link: string = '';
}
