import React, { FC } from 'react';
import { IOrderInfoState, IData } from 'dashboard/orderinfo/types';
import createConnect from 'dashboard/orderinfo/container/connect';
import { Waypoint } from 'react-waypoint';
import { IChangedDeliveryDate } from '../messages/types';
import OrderInfoLine from 'dashboard/orderinfo/components/orderInfoLine';
import OrderInfoLineShadow from '../components/orderItemLineShadow';
import { SpeechBubble } from 'react-kawaii';
import Alert from 'returnorders/forms/alert';

interface OwnProps {
	reactId: string;
}

interface StateProps {
	orderinfo: IOrderInfoState;
	data: IData;
	localizations: object;
}

interface DispatchProps {
	fetchOrderInfoData: any;
}

type IProps = StateProps & DispatchProps & OwnProps;

const OrderInfoContainer: FC<IProps> = (props) => {
	const { orderinfo, data, localizations } = props;
	const { fetchOrderInfoData } = props;
	const { reactId, orderinfoData, orderinfoResult, isFetching } = orderinfo;
	const { title, internalLink, buttonLabel } = data;

	const onEnter = () => {
		if (!isFetching && !orderinfoResult) {
			fetchOrderInfoData(reactId);
		}
	};

	return (
		<div className='widget widget-order'>
			<header className='widget-head'>
				<h4 className='head head4 upper ellipsis'>
					{internalLink ? <a href={internalLink}>{title}</a> : <>{title}</>}
				</h4>
				{internalLink && buttonLabel && (
					<a href={internalLink} className='btn-color01 widget-head-all'>
						{buttonLabel}
					</a>
				)}
				{!orderinfoData && <Waypoint onEnter={onEnter} />}
			</header>
			{isFetching && (
				<ul className='widget-order-lines'>
					<OrderInfoLineShadow />
					<OrderInfoLineShadow />
					<OrderInfoLineShadow />
					<OrderInfoLineShadow />
					<OrderInfoLineShadow />
					<OrderInfoLineShadow />
				</ul>
			)}

			{orderinfoData && orderinfoData.lines.length === 0 && (
				<div className='m'>
					<div className='g jc-center'>
						<SpeechBubble size={200} mood='happy' color='#d9efe6' />
					</div>
					<Alert
						state='positive'
						message={
							localizations['common.account.orderchangeddeliverydates.header.error.noorders']
						}
					/>
				</div>
			)}

			{orderinfoResult && orderinfoResult.error === true && (
				<div className='m'>
					<div className='g jc-center'>
						<SpeechBubble size={200} mood='sad' color='#fbdd98' />
					</div>
					<Alert
						state='warning'
						message={localizations['common.account.orderchangeddeliverydates.header.error.nodata']}
					/>
				</div>
			)}

			{orderinfoData && orderinfoData.lines.length > 0 && (
				<ul className='widget-order-lines'>
					{orderinfoData.lines.map((line: IChangedDeliveryDate, index) => {
						return <OrderInfoLine key={index} line={line} />;
					})}
				</ul>
			)}
		</div>
	);
};

const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(OrderInfoContainer);
