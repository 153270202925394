export interface IHTTPResult {
	json: object;
	status: number;
	error: boolean;
}

export interface History {
	push(path: string, state?: any): void;
	goBack(): any;
}

export interface IMessage {
	uuid: string;
	customerNo: string;
	id: string;
	title: string;
	shortDescription: string;
	longDescription: string;
	type: MessageType;
	status: MessageStatus;
	startDate: Date;
	endDate: Date;
	dateImported: Date;
}

export enum MessageType {
	MESSAGE = 'MESSAGE',
	ALERT = 'ALERT',
	UPDATE = 'UPDATE',
	WARNING = 'WARNING',
}

export enum MessageStatus {
	NEW = 'NEW',
	READ = 'READ',
	MARKED = 'MARKED',
	EXPIRED = 'EXPIRED',
	DELETED = 'DELETED',
}

export interface IMessageData {
	isFetching: boolean;
	messages: IMessage[];
	message: IMessage;
}

export enum MessageActions {
	REQUEST_MESSAGE_UPDATE = 'REQUEST_MESSAGE_UPDATE',
	RECEIVE_MESSAGE_UPDATE = 'RECEIVE_MESSAGE_UPDATE',
	RECEIVE_MESSAGE = 'RECEIVE_MESSAGE',
}

interface RequestMessageUpdateAction {
	type: typeof MessageActions.REQUEST_MESSAGE_UPDATE;
}

interface ReceiveMessageUpdateAction {
	type: typeof MessageActions.RECEIVE_MESSAGE_UPDATE;
	json: any;
}

interface ReceiveMessageAction {
	type: typeof MessageActions.RECEIVE_MESSAGE;
	id: string;
}

export type MessageActionTypes =
	| RequestMessageUpdateAction
	| ReceiveMessageUpdateAction
	| ReceiveMessageAction;
