import React, { Fragment, ReactNode, Component, createRef } from 'react';
import { IOption } from 'returnorders/types';

interface IProps {
	name: string;
	id: string;
	options: IOption[];
	defaultOption?: string;
	defaultValue?: string;
	onChange?: any;
	onBlur?: any;
	error?: ReactNode;
	selectedOption?: string;
	extraClass?: string;
}

interface IState {
	selectedValue?: string;
}

/**
 * @deprecated consider using formikelements instead
 */
export default class Select extends Component<IProps, IState> {
	private selectElement = createRef<HTMLSelectElement>();

	constructor(props: IProps) {
		super(props);

		//set initial value to first element
		this.state = {
			selectedValue: '',
		};
	}

	getValue = () => {
		return this.state.selectedValue;
	};

	setValue = (value: string) => {
		if (value && this.selectElement.current) {
			this.selectElement.current.value = value;
			this.setState({ selectedValue: value });
		}
	};

	getFirstValue = () => {
		let htmlselectElement: HTMLSelectElement | null = this.selectElement.current;

		if (htmlselectElement != null && htmlselectElement.options.length > 0) {
			return htmlselectElement.options[0].value;
		}
		return undefined;
	};

	componentDidMount = () => {
		let selected: string | undefined =
			this.props.defaultValue ? this.props.defaultValue :
			this.props.selectedOption && this.props.selectedOption !== ''
				? this.props.selectedOption
				: this.getFirstValue();

		this.setState({ selectedValue: selected });
	};

	componentDidUpdate = (prevProps: IProps) => {
		if (prevProps.selectedOption !== this.props.selectedOption) {
			let selected: string | undefined =
				this.props.selectedOption && this.props.selectedOption !== ''
					? this.props.selectedOption
					: this.getFirstValue();
			this.setState({ selectedValue: selected });
		}
	};

	handleChange = (event: any) => {
		event.persist();

		this.setState({ selectedValue: event.target.value });
		if (this.props.onChange) {
			this.props.onChange(event);
		}
	};

	render() {
		const { name, id, options, defaultOption, defaultValue, onBlur, error, extraClass } = this.props;
		const { selectedValue } = this.state;

		let errorClass = '';
		let errorElement: ReactNode = null;
		if (React.isValidElement(error)) {
			errorClass = ' field-invalid';
			//give error component a 'name' prop so they do not have to be specified
			errorElement = React.cloneElement(error as React.ReactElement<any>, {
				name: name,
			});
		}

		let formfieldClass = `form-field ${errorClass} ${extraClass} `;

		return (
			<Fragment>
				<div className={formfieldClass} data-castlecss-field>
					<select
						value={selectedValue}
						name={name}
						id={id}
						onChange={this.handleChange.bind(this)}
						onBlur={onBlur}
						ref={this.selectElement}
						data-castlecss-select
					>
						{defaultOption && <option value=''>{defaultOption}</option>}
						{options.map((option, index) => {
							if (option.disabled && option.label) {
								return (
									<optgroup key={index} label={option.value}>
										<option value={option.key} disabled={option.disabled}>
											{option.label}
										</option>
									</optgroup>
								);
							}
							return (
								<option
									data-identifier={option.identifier}
									value={option.key}
									key={index}
									disabled={option.disabled}
									label={option.label !== '' ? option.label : option.value}
								>
									{option.value}
								</option>
							);
						})}
					</select>
				</div>
				{errorElement}
			</Fragment>
		);
	}
}
