import {
	IDirectOrderState,
	DirectOrderActionTypes,
	DirectOrderActions,
} from '../container/types';
import DirectOrder from 'directorder/objects/DirectOrder';
import produce from 'immer';

import {
	pushDirectOrderOrdering,
	pushDirectOrderCompleted,
	pushDirectOrderCancelled,
} from 'shared/libs/gaevents';

const initialState: IDirectOrderState = {
	orders: [],
	isFetching: false,
	fetchProductsResult: undefined,
	addToBasketResult: undefined,
	finishOrderResult: undefined,
	doNotDeliverResult: undefined,
	fetchOrderRemarkResult: undefined,
	addOrderRemarkResult: undefined,
	addOrderRemarkProgress: undefined,
	deliverFromOwnSupplyResult: undefined,
};

const updateOrder = (currentOrder: DirectOrder, newOrder: DirectOrder) => {
	//do not update the whole order because of locally defined isLoaded property
	currentOrder.state = newOrder.state;
	currentOrder.progressStatus = newOrder.progressStatus;
	currentOrder.dealerDeliveryDate = newOrder.dealerDeliveryDate;
};

export const directorderReducer = produce(
	(draft: IDirectOrderState, action: DirectOrderActionTypes): IDirectOrderState => {
		if (!draft) {
			return initialState;
		}

		switch (action.type) {
			case DirectOrderActions.REQUEST_DIRECTORDERLINE: {
				draft.isFetching = true;
				return draft;
			}

			case DirectOrderActions.RECEIVE_DIRECTORDERLINE: {
				const { orderID, orders, result } = action;

				draft.isFetching = false;
				if (result && !result.error && orders.length == 1) {
					let order = orders[0];
					let index = draft.orders.findIndex((draftOrder) => draftOrder.id === orderID);
					if (index > -1) {
						updateOrder(draft.orders[index], order);
					}
				}

				return draft;
			}

			case DirectOrderActions.RECEIVE_DIRECTORDERS_INIT: {
				draft.fetchProductsResult = undefined;
				draft.addToBasketResult = undefined;
				draft.finishOrderResult = undefined;
				draft.doNotDeliverResult = undefined;
				draft.fetchOrderRemarkResult = undefined;
				draft.addOrderRemarkResult = undefined;
				draft.addOrderRemarkProgress = undefined;
				draft.deliverFromOwnSupplyResult = undefined;
				return draft;
			}

			case DirectOrderActions.REQUEST_FINISHDIRECTORDER:
			case DirectOrderActions.REQUEST_ADDORDERTOBASKET:
			case DirectOrderActions.REQUEST_DONOTDELIVER:
			case DirectOrderActions.REQUEST_DELIVERFROMOWNSUPPLY: {
				const { orderID } = action;

				draft.isFetching = false;
				draft.orders.map((item) => {
					if (item.id == orderID) {
						//update state
					}
					return item;
				});

				return draft;
			}

			case DirectOrderActions.RECEIVE_FINISHDIRECTORDER: {
				const { orderID, result, orders } = action;
				draft.isFetching = false;

				if (result && !result.error) {
					orders.forEach((order) => {
						let index = draft.orders.findIndex((draftOrder) => draftOrder.id === order.id);
						updateOrder(draft.orders[index], order);
						pushDirectOrderCompleted(
							order.orderNumber,
							order.date,
							order.dealerDeliveryDate,
							order.platform
						);
					});
				}

				draft.finishOrderResult = result;

				return draft;
			}

			case DirectOrderActions.RECEIVE_ADDORDERTOBASKET: {
				const { orderID, result, orders } = action;
				draft.isFetching = false;

				if (result && !result.error) {
					orders.forEach((order) => {
						let index = draft.orders.findIndex((draftOrder) => draftOrder.id === order.id);
						updateOrder(draft.orders[index], order);
						pushDirectOrderOrdering(order.orderNumber, order.date, order.platform);
					});
				}

				draft.addToBasketResult = result;

				return draft;
			}

			case DirectOrderActions.RECEIVE_DONOTDELIVER: {
				const { orderID, result, orders } = action;
				draft.isFetching = false;

				if (result && !result.error) {
					orders.forEach((order) => {
						let index = draft.orders.findIndex((draftOrder) => draftOrder.id === order.id);
						updateOrder(draft.orders[index], order);
						pushDirectOrderCancelled(order.orderNumber, order.date, order.platform);
					});
				}

				draft.doNotDeliverResult = result;

				return draft;
			}

			case DirectOrderActions.RECEIVE_DELIVERFROMOWNSUPPLY: {
				const { orderID, result, orders } = action;
				draft.isFetching = false;

				if (result && !result.error) {
					orders.forEach((order) => {
						let index = draft.orders.findIndex((draftOrder) => draftOrder.id === order.id);
						updateOrder(draft.orders[index], order);
						pushDirectOrderOrdering(order.orderNumber, order.date, order.platform);
					});
				}

				draft.deliverFromOwnSupplyResult = result;

				return draft;
			}

			case DirectOrderActions.REQUEST_FETCHPRODUCTS: {
				const { orderID, skus } = action;
				draft.isFetching = true;
				return draft;
			}

			case DirectOrderActions.RECEIVE_FETCHPRODUCTS: {
				const { orderID, skus, result, products } = action;

				if (result && !result.error) {
					draft.isFetching = false;
					draft.orders.forEach((order) => {
						if (order.id === orderID) {
							let isProductsLoaded = false;
							order.orderLines.forEach((orderLine) => {
								let product = products.find((product) => product.sku === orderLine.sku);
								if (product) {
									orderLine.product = product;
									orderLine.isLoaded = true;
									isProductsLoaded = true;
								} else {
									if (skus.indexOf(orderLine.sku) > -1) {
										orderLine.isLoaded = true;
									}
								}
							});
							//show error in case none of the products are available
							if (!isProductsLoaded) {
								result.json = {
									success: false,
									errorMessages: ['HANDLING_UNABLE_TO_ORDER'],
									data: {},
								};
							}
							order.isProductsLoaded = isProductsLoaded;
						}
					});
				}

				draft.fetchProductsResult = result;

				return draft;
			}

			case DirectOrderActions.REQUEST_FETCHREMARKS: {
				const { orderID } = action;
				draft.isFetching = true;
				return draft;
			}

			case DirectOrderActions.RECEIVE_FETCHREMARKS: {
				const { orderID, result, remarks } = action;

				if (result && !result.error) {
					let index = draft.orders.findIndex((draftOrder) => draftOrder.id === orderID);
					draft.orders[index].orderRemarks = remarks;
					draft.orders[index].isRemarksLoaded = true;
					draft.isFetching = false;
				}
				draft.fetchOrderRemarkResult = result;
				return draft;
			}

			case DirectOrderActions.REQUEST_ADDREMARK: {
				const {} = action;
				return draft;
			}

			case DirectOrderActions.UPDATE_ADDREMARK: {
				const { progress } = action;
				draft.addOrderRemarkProgress = progress;
				return draft;
			}

			case DirectOrderActions.RECEIVE_ADDREMARK: {
				const { orderID, result, remarks } = action;
				if (result && !result.error) {
					let index = draft.orders.findIndex((draftOrder) => draftOrder.id === orderID);
					draft.orders[index].orderRemarks = remarks;
					draft.orders[index].isRemarksLoaded = true;
				}
				draft.addOrderRemarkResult = result;
				return draft;
			}

			default:
				return draft;
		}
	}
);
