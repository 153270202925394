import { useEffect, useState } from 'react';

export function useKeyPress(targetKey, onPressDown = (e) => {}, onPressUp = (e) => {}) {
	// State for keeping track of whether key is pressed
	const [keyPressed, setKeyPressed] = useState(false);

	useEffect(() => {
		// If pressed key is our target key then set to true
		function downHandler(e) {
			if (e.key === targetKey) {
				setKeyPressed(true);
				onPressDown(e);
			}
		}

		// If released key is our target key then set to false
		const upHandler = (e) => {
			if (e.key === targetKey) {
				setKeyPressed(false);
				onPressUp(e);
			}
		};

		// Add event listeners
		window.addEventListener('keydown', downHandler);
		window.addEventListener('keyup', upHandler);

		// Remove event listeners on cleanup
		return () => {
			window.removeEventListener('keydown', downHandler);
			window.removeEventListener('keyup', upHandler);
		};
	});

	return keyPressed;
}