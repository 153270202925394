import React, { Fragment, Component, ReactNode } from 'react';

interface IProps {
	id: string;
	name: string;
	text?: string;
	className?: string;
	label?: string;
	onChange?: any;
	onBlur?: any;
	error?: ReactNode;
	maxLength?: number;
}

/**
 * @deprecated consider using formikelements instead
 */
export default class Textarea extends Component<IProps> {
	render() {
		const { className, text, id, name, label, onChange, onBlur, error, maxLength } = this.props;

		let errorClass = '';
		let errorElement: ReactNode = null;
		if (React.isValidElement(error)) {
			errorClass = ' field-invalid';
			//give error component a 'name' prop so they do not have to be specified
			errorElement = React.cloneElement(error as React.ReactElement<any>, {
				name: name,
			});
		}

		let formfieldClass = `form-field form-textarea ${errorClass} ${className ? className : ''}`;

		return (
			<Fragment>
				<div className={formfieldClass} data-castlecss-field>
					{label && <label htmlFor={id}>{label}</label>}
					<textarea
						id={id}
						name={name}
						onChange={onChange}
						onBlur={onBlur}
						defaultValue={text}
						maxLength={maxLength}
					></textarea>
				</div>
				{errorElement}
			</Fragment>
		);
	}
}
