import { IHTTPResult, ProgressStatus } from 'shared/types';
import { IOrderRemarks, OrderLine } from '../messages/messages';
import { Product } from 'directorder/objects/Product';
import DirectOrder from 'directorder/objects/DirectOrder';

export interface IDirectOrderState {
	orders: DirectOrder[];
	isFetching: boolean;
	fetchProductsResult?: IHTTPResult;
	addToBasketResult?: IHTTPResult;
	finishOrderResult?: IHTTPResult;
	doNotDeliverResult?: IHTTPResult;
	deliverFromOwnSupplyResult?: IHTTPResult;
	fetchOrderRemarkResult?: IHTTPResult;
	addOrderRemarkResult?: IHTTPResult;
	addOrderRemarkProgress?: ProgressStatus;
}

export interface IDirectOrderLineExtension extends OrderLine {
	isFetching?: boolean;
}

export enum EDirectOrderState {
	open = 'NEW',
	inprogress = 'INPROGRESS',
	completed = 'COMPLETED',
	canceled = 'CANCELLED',
}

export enum EProgressStatus {
	none = 'NONE',
	inprogress = 'INPROGRESS',
	ordering = 'ORDERING',
}

export enum EPlatform {
	cortina_nl = 'kruitbosch-cortina_nl',
	cortina_en = 'kruitbosch-cortina_en',
	cortina_de = 'kruitbosch-cortina_de',
	cortina_be = 'kruitbosch-cortina_be',
	alpina_nl = 'kruitbosch-alpina_nl',
	unknown = '',
}

export enum DirectOrderActions {
	RECEIVE_DIRECTORDERS_INIT = 'RECEIVE_DIRECTORDERS_INIT',
	REQUEST_DIRECTORDERLINE = 'REQUEST_DIRECTORDERLINE',
	RECEIVE_DIRECTORDERLINE = 'RECEIVE_DIRECTORDERLINE',
	REQUEST_ADDORDERTOBASKET = 'REQUEST_ADDORDERTOBASKET',
	RECEIVE_ADDORDERTOBASKET = 'RECEIVE_ADDORDERTOBASKET',
	REQUEST_DELIVERFROMOWNSUPPLY = 'REQUEST_DELIVERFROMOWNSUPPLY',
	RECEIVE_DELIVERFROMOWNSUPPLY = 'RECEIVE_DELIVERFROMOWNSUPPLY',
	REQUEST_FINISHDIRECTORDER = 'REQUEST_FINISHDIRECTORDER',
	RECEIVE_FINISHDIRECTORDER = 'RECEIVE_FINISHDIRECTORDER',
	REQUEST_DONOTDELIVER = 'REQUEST_DONOTDELIVER',
	RECEIVE_DONOTDELIVER = 'RECEIVE_DONOTDELIVER',
	REQUEST_FETCHPRODUCTS = 'REQUEST_FETCHPRODUCTS',
	RECEIVE_FETCHPRODUCTS = 'RECEIVE_FETCHPRODUCTS',
	REQUEST_FETCHREMARKS = 'REQUEST_FETCHREMARKS',
	RECEIVE_FETCHREMARKS = 'RECEIVE_FETCHREMARKS',
	REQUEST_ADDREMARK = 'REQUEST_ADDREMARK',
	RECEIVE_ADDREMARK = 'RECEIVE_ADDREMARK',
	UPDATE_ADDREMARK = 'UPDATE_ADDREMARK',
}

interface RecieveDirectOrdersInit {
	type: typeof DirectOrderActions.RECEIVE_DIRECTORDERS_INIT;
}

interface RequestDirectOrderLine {
	type: typeof DirectOrderActions.REQUEST_DIRECTORDERLINE;
	orderID: string;
}

interface RecieveDirectOrderLine {
	type: typeof DirectOrderActions.RECEIVE_DIRECTORDERLINE;
	result?: IHTTPResult;
	orderID: string;
	orders: DirectOrder[];
}

interface RequestAddOrderToBasket {
	type: typeof DirectOrderActions.REQUEST_ADDORDERTOBASKET;
	orderID: String;
}

interface RecieveAddOrderToBasket {
	type: typeof DirectOrderActions.RECEIVE_ADDORDERTOBASKET;
	result?: IHTTPResult;
	orderID: String;
	orders: DirectOrder[];
}

interface RequestDeliverFromOwnSupply {
	type: typeof DirectOrderActions.REQUEST_DELIVERFROMOWNSUPPLY;
	orderID: String;
}

interface RecieveDeliverFromOwnSupply {
	type: typeof DirectOrderActions.RECEIVE_DELIVERFROMOWNSUPPLY;
	result?: IHTTPResult;
	orderID: String;
	orders: DirectOrder[];
}

interface RequestFinishDirectOrder {
	type: typeof DirectOrderActions.REQUEST_FINISHDIRECTORDER;
	orderID: String;
}

interface RecieveFinishDirectOrder {
	type: typeof DirectOrderActions.RECEIVE_FINISHDIRECTORDER;
	result?: IHTTPResult;
	orderID: String;
	orders: DirectOrder[];
}

interface RequestDoNotDeliver {
	type: typeof DirectOrderActions.REQUEST_DONOTDELIVER;
	orderID: String;
}

interface RecieveDoNotDeliver {
	type: typeof DirectOrderActions.RECEIVE_DONOTDELIVER;
	result?: IHTTPResult;
	orderID: String;
	orders: DirectOrder[];
}

interface RequestFetchProducts {
	type: typeof DirectOrderActions.REQUEST_FETCHPRODUCTS;
	orderID: String;
	skus: String[];
}

interface RecieveFetchProducts {
	type: typeof DirectOrderActions.RECEIVE_FETCHPRODUCTS;
	result?: IHTTPResult;
	orderID: string;
	skus: string[];
	products: Product[];
}

interface RequestFetchRemarks {
	type: typeof DirectOrderActions.REQUEST_FETCHREMARKS;
	orderID: String;
}

interface RecieveFetchRemarks {
	type: typeof DirectOrderActions.RECEIVE_FETCHREMARKS;
	orderID: string;
	result?: IHTTPResult;
	remarks?: IOrderRemarks;
}

interface RequestAddRemark {
	type: typeof DirectOrderActions.REQUEST_ADDREMARK;
}

interface RecieveAddRemark {
	type: typeof DirectOrderActions.RECEIVE_ADDREMARK;
	orderID: string;
	result?: IHTTPResult;
	remarks?: IOrderRemarks;
}

interface UpdateAddRemark {
	type: typeof DirectOrderActions.UPDATE_ADDREMARK;
	progress: ProgressStatus;
}

export type DirectOrderActionTypes =
	| RecieveDirectOrdersInit
	| RequestDirectOrderLine
	| RecieveDirectOrderLine
	| RequestAddOrderToBasket
	| RecieveAddOrderToBasket
	| RequestDeliverFromOwnSupply
	| RecieveDeliverFromOwnSupply
	| RequestFinishDirectOrder
	| RecieveFinishDirectOrder
	| RequestDoNotDeliver
	| RecieveDoNotDeliver
	| RequestFetchProducts
	| RecieveFetchProducts
	| RequestFetchRemarks
	| RecieveFetchRemarks
	| RequestAddRemark
	| UpdateAddRemark
	| RecieveAddRemark;
