import React, { FC, useEffect } from 'react';
import createConnect from 'directorder/container/connect';
import { IDirectOrderState } from 'directorder/container/types';
import Notify from 'returnorders/forms/notify';
import useForm from 'shared/forms/useForm';
import * as validations from 'shared/forms/validations';
import TextField from 'shared/formelements/textfield';
import Textarea from 'shared/formelements/textarea';
import Loading from 'shared/components/Loading';
import DirectOrder from 'directorder/objects/DirectOrder';
import { Forms } from 'castlecss-forms';
import { toDate } from 'validator';
import moment from 'moment';

interface OwnProps {
	order: DirectOrder;
	closePopup: Function;
	popupType: EPopupType;
	icon: string;
}

interface StateProps {
	localizations: object;
	directorder: IDirectOrderState;
}

interface DispatchProps {
	addOrderRemark: Function;
	finishDirectOrder: Function;
	addOrderToBasket: Function;
	deliverFromOwnSupply: Function;
}

export enum EPopupType {
	ADD_REMARK = 'ADD_REMARK',
	ADD_TO_BASKET = 'ADD_TO_BASKET',
	DELIVER_FROM_OWN_SUPPLY = 'DELIVER_FROM_OWN_SUPPLY',
	FINISH = 'FINISH',
}

type IProps = StateProps & DispatchProps & OwnProps;

export const DirectOrderRemarkPopup: FC<IProps> = (props) => {
	const { popupType, order, closePopup, localizations, directorder, icon } = props;
	const { deliverFromOwnSupply, addOrderToBasket, finishDirectOrder, addOrderRemark } = props;
	const { addOrderRemarkResult, addOrderRemarkProgress } = directorder;

	useEffect(() => {
		Forms();
	}, []);

	useEffect(() => {
		if (addOrderRemarkProgress && addOrderRemarkProgress === 'success') {
			//a 'succes' message is not required
			closePopup();
		}
	}, [addOrderRemarkProgress]);

	const onClosePopup = (e) => {
		e.preventDefault();
		closePopup();
	};

	const platform = order.getChannel();

	let localStorageName: string | null = localStorage.getItem('PostRequestUser');
	let initialUserName: string = localStorageName ? localStorageName : '';

	//form logic
	let validate: any = (currentValues: any) => {
		let errors: any = {};

		if (popupType === EPopupType.FINISH) {
			if (!validations.required(currentValues.deliverydate)) {
				errors.deliverydate = (
					<Notify
						message={
							localizations[
								'common.account.directorder.remarks.popup.error.deliverydate.required.text'
							]
						}
					/>
				);
			} else if (!validations.date(currentValues.deliverydate, 'YYYY-MM-DD')) {
				errors.deliverydate = (
					<Notify
						message={
							localizations[
								'common.account.directorder.remarks.popup.error.deliverydate.invalid.text'
							]
						}
					/>
				);
			} else {
				let deliveryDate = toDate(currentValues.deliverydate);
				let currentDate = new Date();
				if (deliveryDate >= currentDate) {
					errors.deliverydate = (
						<Notify
							message={
								localizations[
									'common.account.directorder.remarks.popup.error.deliverydate.isnotpast.text'
								]
							}
						/>
					);
				}
			}
		}

		if (!validations.required(currentValues.username)) {
			errors.username = (
				<Notify
					message={
						localizations['common.account.directorder.remarks.popup.error.username.required.text']
					}
				/>
			);
		}
		if (!validations.required(currentValues.remark)) {
			errors.remark = (
				<Notify
					message={
						localizations['common.account.directorder.remarks.popup.error.remark.required.text']
					}
				/>
			);
		}

		return errors;
	};

	let handleServerSubmit = (e) => {
		let username: string = e?.target?.username?.value ?? '';
		let remark: string = e?.target?.remark?.value ?? '';
		let deliverydate: string = e?.target?.deliverydate?.value ?? '';
		let orderID = order.id;
		let domainName = order.platform;

		localStorage.setItem('PostRequestUser', username);

		switch (popupType) {
			case EPopupType.ADD_TO_BASKET:
				addOrderToBasket(orderID, domainName, username, remark);
				break;
			case EPopupType.DELIVER_FROM_OWN_SUPPLY:
				deliverFromOwnSupply(orderID, domainName, username, remark);
				break;
			case EPopupType.ADD_REMARK:
				addOrderRemark(orderID, domainName, username, remark);
				break;
			case EPopupType.FINISH:
				let deliverydateFormatted = moment(deliverydate, 'YYYY-MM-DD').format('DD-MM-YYYY');
				finishDirectOrder(orderID, domainName, username, remark, deliverydateFormatted);
				break;
			default:
				break;
		}
	};

	let initialValues = {
		username: initialUserName,
	};

	const { values, errors, handleChange, handleBlur, handleClientSubmit } = useForm(
		handleServerSubmit,
		validate,
		initialValues
	);

	const styleBlock = { display: 'block' };

	const renderDescription = () => {
		switch (popupType) {
			case EPopupType.ADD_TO_BASKET:
				return localizations['common.account.directorder.remarks.popup.tobasket.desc.text'];
			case EPopupType.DELIVER_FROM_OWN_SUPPLY:
				return localizations[
					'common.account.directorder.remarks.popup.supplyfrominventory.desc.text'
				];
			case EPopupType.ADD_REMARK:
				return localizations['common.account.directorder.remarks.popup.addremark.desc.text'];
			case EPopupType.FINISH:
				return localizations['common.account.directorder.remarks.popup.delivered.desc.text'];
			default:
				return <></>;
		}
	};

	//set current date as max value
	const deliveryDateMax = new Date().toISOString().split('T')[0];

	return (
		<div className='modal-base basket-config directorders-popup'>
			<div className='modal-wrapper' style={styleBlock}>
				<div className='modal-content'>
					<div className='modal-heading'>
						<h2 className='head head2 color-color01'>
							{localizations['common.account.directorder.remarks.popup.header.text']}
						</h2>
						<button type='button' className='modal-heading-close' onClick={onClosePopup}>
							<i className='icon-cancel'></i>
						</button>
					</div>

					<div className='modal-content-container-nopadding bgcolor-color05'>
						{/* form */}
						<form className='form' onSubmit={handleClientSubmit}>
							{/* order details */}
							<div className='directorders-popup-order'>
								<i className={`${icon}`}></i>
								<div className='directorders-popup-wrap'>
									<div>
										<h5 className='head head5 mb-0'>
											{localizations['common.account.directorder.remarks.popup.ordernumber.label']}:{' '}
											{order.orderNumber}
										</h5>
										<small className='color-color06'>
											{`${localizations['common.account.directorder.platform.from.text']} ${
												localizations[`common.account.directorder.platform.from.${platform}.text`]
											}`}
										</small>
									</div>
									<div className='directorders-popup-date'>{order.date}</div>
								</div>
							</div>

							<div className='form-fields p-2x'>
								{/* content loading */}
								{addOrderRemarkProgress && addOrderRemarkProgress === 'waiting' && (
									<Loading overlay={true} closeButton={true} />
								)}

								{popupType === EPopupType.FINISH && (
									<div className='mb '>
										<TextField
											attributes={{
												type: 'date',
												id: 'deliverydate',
												name: 'deliverydate',
												max: deliveryDateMax,
											}}
											extraClass={'date label-top'}
											label={`${localizations['common.account.directorder.remarks.popup.date.label']}*`}
											error={errors.deliverydate}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
									</div>
								)}

								<TextField
									attributes={{
										type: 'text',
										id: 'username',
										name: 'username',
										defaultValue: initialValues.username,
										maxLength: 256,
									}}
									label={`${localizations['common.account.directorder.remarks.popup.username.label']}*`}
									error={errors.username}
									onChange={handleChange}
									onBlur={handleBlur}
								/>

								{/* the description differs for each popupType */}
								<p className='mt-2x mb'>{renderDescription()}</p>

								<Textarea
									id='remark'
									name='remark'
									className='pb'
									label={`${localizations['common.account.directorder.remarks.popup.remark.label']}*`}
									error={errors.remark}
									onChange={handleChange}
									onBlur={handleBlur}
									maxLength={1000}
								/>

								{/* server errors */}
								{addOrderRemarkProgress && addOrderRemarkProgress === 'error' && (
									<p className='mt-0 mb alert-negative'>
										<Notify
											message={localizations['common.account.directorder.remarks.popup.error.text']}
										/>
									</p>
								)}

								{/* calltoactions */}
								<div className='modal-content-actions'>
									<button
										type='button'
										className='btn-theme03 hide show-inline-b3'
										onClick={onClosePopup}
									>
										{localizations['common.account.directorder.remarks.popup.cancel.text']}
									</button>
									<button type='submit' data-sl='addremark-send' className='btn btn-submit'>
										{localizations['common.account.directorder.remarks.popup.save.text']}
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default createConnect<StateProps, DispatchProps, OwnProps>()(DirectOrderRemarkPopup);
