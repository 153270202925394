import fetch from 'isomorphic-fetch';
import { getJsonOrRedirect, getResultOrRedirect } from 'shared/libs/urlhandling';
import { pushUpdateToFavorite } from 'shared/libs/gaevents';

/**
 * PRODUCTLISTER ACTIONS CONSTANTS
 */
export const REQUEST_TOGGLEFILTERCHECKBOX = 'REQUEST_TOGGLEFILTERCHECKBOX';
export const REQUEST_PRODUCTLISTER = 'REQUEST_PRODUCTLISTER';
export const RECEIVE_PRODUCTLISTER = 'RECEIVE_PRODUCTLISTER';

/**
 * PAGELISTER ACTIONS CONSTANTS
 */
export const REQUEST_PAGELISTER = 'REQUEST_PAGELISTER';
export const RECEIVE_PAGELISTER = 'RECEIVE_PAGELISTER';

/**
 * PRODUCT ACTION CONSTANTS
 */
export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT';

export const REQUEST_FAMILYPRODUCT = 'REQUEST_FAMILYPRODUCT';
export const RECEIVE_FAMILYPRODUCT = 'RECEIVE_FAMILYPRODUCT';

/**
 * PRODUCTLISTER VIEW ACTION CONSTANTS
 */
export const CHANGE_VIEW = 'CHANGE_VIEW';

/**
 * BASKET ACTION CONSTANTS
 */
export const REQUEST_ADDPRODUCTTOBASKET = 'REQUEST_ADDPRODUCTTOBASKET';
export const RECEIVE_ADDPRODUCTTOBASKET = 'RECEIVE_ADDPRODUCTTOBASKET';
export const RECEIVE_ADDPRODUCTTOBASKET_TOLARGE = 'RECEIVE_ADDPRODUCTTOBASKET_TOLARGE';

export const REQUEST_ADDPRODUCTTODROPSHIPMENTBASKET = 'REQUEST_ADDPRODUCTTODROPSHIPMENTBASKET';
export const RECEIVE_ADDPRODUCTTODROPSHIPMENTBASKET = 'RECEIVE_ADDPRODUCTTODROPSHIPMENTBASKET';

export const REQUEST_CLOSEBASKETITEMCONFIGURATION = 'REQUEST_CLOSEBASKETITEMCONFIGURATION';
export const REQUEST_ADDBASKETITEMCONFIGURATION = 'REQUEST_ADDBASKETITEMCONFIGURATION';
export const RECEIVE_ADDBASKETITEMCONFIGURATION = 'RECEIVE_ADDBASKETITEMCONFIGURATION';

export const REQUEST_ADDRELATEDPRODUCTTOBASKET = 'REQUEST_ADDRELATEDPRODUCTTOBASKET';
export const RECEIVE_ADDRELATEDPRODUCTTOBASKET = 'RECEIVE_ADDRELATEDPRODUCTTOBASKET';

export const REQUEST_CONFIGURATION_CONTEXT = 'REQUEST_CONFIGURATION_CONTEXT';
export const RECEIVE_CONFIGURATION_CONTEXT = 'RECEIVE_CONFIGURATION_CONTEXT';
export const REQUEST_CONFIGURATION_CLOSE = 'REQUEST_CONFIGURATION_CLOSE';

export const REQUEST_CONFIGURATION_DATA = 'REQUEST_CONFIGURATION_DATA';
export const RECEIVE_CONFIGURATION_DATA = 'RECEIVE_CONFIGURATION_DATA';

/**
 * PRODUCTDETAIL ACTION CONSTANTS
 */
export const REQUEST_GETPRODUCTDETAIL_DATA = 'REQUEST_GETPRODUCTDETAIL_DATA';
export const RECEIVE_GETPRODUCTDETAIL_DATA = 'RECEIVE_GETPRODUCTDETAIL_DATA';
export const CLEAR_PRODUCTDETAIL_DATA = 'CLEAR_PRODUCTDETAIL_DATA';

export const REQUEST_UPDATEFAVORITEPRODUCT = 'REQUEST_UPDATEFAVORITEPRODUCT';
export const RECEIVE_UPDATEFAVORITEPRODUCT = 'RECEIVE_UPDATEFAVORITEPRODUCT';

export const REQUEST_ADDPRODUCTCOMMENT = 'REQUEST_ADDPRODUCTCOMMENT';
export const RECEIVE_ADDPRODUCTCOMMENT = 'RECEIVE_ADDPRODUCTCOMMENT';

export const REQUEST_ADDSEARCHCOMMENT = 'REQUEST_ADDSEARCHCOMMENT';
export const RECEIVE_ADDSEARCHCOMMENT = 'RECEIVE_ADDSEARCHCOMMENT';

export const REQUEST_UPDATE = 'REQUEST_UPDATE';
export const INIT = 'INIT';

//helper function
function getList(productLister, extensions) {
	let list = productLister.currentProductLister.categoryName; //if (extensions.Lister || extensions.Detail)
	if (extensions.Search) {
		list = 'SearchResult';
	} else if (extensions.Sale) {
		list = 'Sale';
	}
	return list;
}

export function receiveProduct(productBase, data) {
	return {
		type: RECEIVE_PRODUCT,
		productBase,
		data,
	};
}

export function requestProduct(productBase) {
	return {
		type: REQUEST_PRODUCT,
		productBase,
	};
}

export function fetchProduct(productBase) {
	return (dispatch) => {
		var url = productBase.ajaxUrl;
		dispatch(requestProduct(productBase));
		return fetch(`${url}&IsReplaced=` + productBase.isReplacedProduct, {
			method: 'get',
			credentials: 'same-origin',
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveProduct(productBase, json)));
	};
}

export function receiveFamilyProduct(productBase, parentSku, data) {
	return {
		type: RECEIVE_FAMILYPRODUCT,
		productBase,
		parentSku,
		data,
	};
}

export function requestFamilyProduct(productBase) {
	return {
		type: REQUEST_FAMILYPRODUCT,
		productBase,
	};
}

export function fetchFamilyProduct(productBase, parentSku) {
	return (dispatch) => {
		let url = productBase.ajaxUrl;
		dispatch(requestFamilyProduct(productBase));
		return fetch(`${url}`, {
			method: 'get',
			credentials: 'same-origin',
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveFamilyProduct(productBase, parentSku, json)));
	};
}

/**
 *  PRODUCTLISTER ACTIONS
 */
function requestProductLister(url) {
	return {
		type: REQUEST_PRODUCTLISTER,
		url,
	};
}

function requestToggleFilterCB(url, selectedFilterElement) {
	return {
		type: REQUEST_TOGGLEFILTERCHECKBOX,
		url,
		selectedFilterElement,
	};
}

export function receiveProductLister(url, json, cached, scrollToTop) {
	return {
		type: RECEIVE_PRODUCTLISTER,
		url,
		data: json,
		cached,
		scrollToTop,
	};
}

function fetchProductLister(url) {
	return (dispatch) => {
		dispatch(requestProductLister(url));
		return fetch(`${url}`, {
			method: 'get',
			credentials: 'same-origin',
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveProductLister(url, json, false, false)));
	};
}

function requestToggleFilterEndOfLife(url) {
	return {
		type: REQUEST_TOGGLEFILTERCHECKBOX,
		url,
	};
}

function fetchFirstPage(url) {
	return (dispatch) => {
		dispatch(requestToggleFilterEndOfLife(url));
		return fetch(`${url}`, {
			method: 'get',
			credentials: 'same-origin',
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveProductLister(url, json, false, true)));
	};
}

export function fetchToggleEndOfLifeIfNeeded(showEndOfLife) {
	return (dispatch, getState) => {
		const { productLister } = getState();

		//update the showEndOfLife parameter in the lister url
		var url = productLister.currentProductListerUrl;

		let eolQueryParamRegex = /(\&EOL\=)\w+/;
		let eolQueryParam = '&EOL=';
		let eolQueryParamNew = eolQueryParam + showEndOfLife;
		if (url.includes(eolQueryParam)) {
			url = url.replace(eolQueryParamRegex, eolQueryParamNew);
		} else {
			url += eolQueryParamNew;
		}

		//update the PageNumber parameter in the lister url
		let pageQueryParamRegex = /(\&PageNumber\=)\d+/;
		let pageQueryParam = '&PageNumber=';
		let pageQueryParamNew = pageQueryParam + '0';
		if (url.includes(pageQueryParam)) {
			url = url.replace(pageQueryParamRegex, pageQueryParamNew);
		} else {
			url += pageQueryParamNew;
		}

		const { productListers } = productLister;

		if (!productListers[url]) {
			return dispatch(fetchFirstPage(url));
		} else {
			return dispatch(receiveProductLister(url, productListers[url], true, true));
		}
	};
}

function fetchCheckBox(url, selectedFilterElement) {
	return (dispatch) => {
		dispatch(requestToggleFilterCB(url, selectedFilterElement));
		return fetch(`${url}`, {
			method: 'get',
			credentials: 'same-origin',
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveProductLister(url, json, false, true)));
	};
}

export function fetchToggleChecBoxIfNeeded(url, selectedFilterElement) {
	return (dispatch, getState) => {
		const { productLister } = getState();
		const { productListers } = productLister;

		if (!productListers[url]) {
			return dispatch(fetchCheckBox(url, selectedFilterElement));
		} else {
			if (url == 'initial') {
				var initialLister = productListers[url];
				var firstPageLister = productListers[initialLister.initialPageUrl];
				if (firstPageLister != null) {
					return dispatch(
						receiveProductLister(initialLister.initialPageUrl, firstPageLister, true, true)
					);
				} else {
					return dispatch(fetchCheckBox(initialLister.initialPageUrl, selectedFilterElement));
				}
			}
			return dispatch(receiveProductLister(url, productListers[url], true, true));
		}
	};
}

export function fetchProductListerIfNeeded(url) {
	return (dispatch, getState) => {
		const { productLister } = getState();
		const { productListers } = productLister;

		if (!productListers[url]) {
			return dispatch(fetchProductLister(url));
		} else {
			if (url == 'initial') {
				var initialLister = productListers[url];
				if (initialLister.initialPageUrl == null) {
					return dispatch(receiveProductLister(url, initialLister, true));
				}

				var firstPageLister = productListers[initialLister.initialPageUrl];
				if (firstPageLister != null) {
					return dispatch(
						receiveProductLister(initialLister.initialPageUrl, firstPageLister, true)
					);
				} else {
					return dispatch(fetchProductLister(initialLister.initialPageUrl));
				}
			}
			return dispatch(receiveProductLister(url, productListers[url], true));
		}
	};
}

/**
 *  PAGELISTER ACTIONS
 */
function requestPageLister(url) {
	return {
		type: REQUEST_PAGELISTER,
		url,
	};
}

export function receivePageLister(url, json, cached) {
	return {
		type: RECEIVE_PAGELISTER,
		url,
		data: json,
		cached,
	};
}

function fetchPageLister(url) {
	return (dispatch) => {
		dispatch(requestPageLister(url));
		return fetch(`${url}`, {
			method: 'get',
			credentials: 'same-origin',
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receivePageLister(url, json, false)));
	};
}

export function fetchPageListerIfNeeded(url) {
	return (dispatch, getState) => {
		const { productLister } = getState();
		const { productListers } = productLister;

		if (!productListers[url]) {
			return dispatch(fetchPageLister(url));
		} else {
			return dispatch(receivePageLister(url, productListers[url], true));
		}
	};
}

export function fetchFirstPageListerIfNeeded(url) {
	return (dispatch, getState) => {
		const { productLister } = getState();
		const { productListers } = productLister;

		let firstPageUrl = url;

		if (!productListers[url]) {
			return dispatch(fetchPageLister(firstPageUrl));
		} else {
			return dispatch(receivePageLister(url, productListers[url], true));
		}
	};
}

/**
/* PRODUCTLISTER VIEW ACTIONS
*/
export function changeProductListerView(view) {
	return {
		type: CHANGE_VIEW,
		view: view,
	};
}

/**
 *  PRODUCTLISTER BASKET ACTIONS
 */

function requestAddProductToBasket(familyProduct, productLoading) {
	return {
		type: REQUEST_ADDPRODUCTTOBASKET,
		familyProduct,
		productLoading,
	};
}

export function receiveAddProductToBasket(
	dispatchUrl,
	familyProduct,
	productLoading,
	json,
	list,
	quantity
) {
	return {
		type: RECEIVE_ADDPRODUCTTOBASKET,
		dispatchUrl,
		familyProduct,
		productLoading,
		data: json,
		list,
		quantity,
	};
}

function requestAddProductToDropshipmentBasket(index) {
	return {
		type: REQUEST_ADDPRODUCTTODROPSHIPMENTBASKET,
		index,
	};
}

export function receiveAddProductToDropshipmentBasket(dispatchUrl, index, json) {
	return {
		type: RECEIVE_ADDPRODUCTTODROPSHIPMENTBASKET,
		dispatchUrl,
		index,
		data: json,
	};
}

function requestAddRelatedProductToBasket() {
	return {
		type: REQUEST_ADDRELATEDPRODUCTTOBASKET,
	};
}

function receiveAddRelatedProductToBasket(product, json, list) {
	return {
		type: RECEIVE_ADDRELATEDPRODUCTTOBASKET,
		data: json,
		product,
		list,
	};
}

/**
 * CONFIGURATION CONTEXT
 */

function requestConfigurationContext(popupState) {
	return {
		type: REQUEST_CONFIGURATION_CONTEXT,
		popupState,
	};
}

export function receiveConfigurationContext(data, popupState, configuration) {
	return {
		type: RECEIVE_CONFIGURATION_CONTEXT,
		data,
		popupState,
		configuration,
	};
}

export function getConfigurationContext(popupState, configuration) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl == null) {
			console.error("Can't find BasketDispatchUrl in extension!");
			return;
		}

		return dispatch((dispatch) => {
			dispatch(requestConfigurationContext(popupState));

			let body = 'getConfigurationContext=getConfigurationContext';
			body += '&SynchronizerToken=' + synchronizerToken;
			body += '&ViewBasketConfiguration_SKU=' + popupState.sku;

			return fetch(`${basketDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
				.then(getJsonOrRedirect)
				.then((data) => dispatch(receiveConfigurationContext(data, popupState, configuration)));
		});
	};
}

/**
 *  REQUEST_ADDBASKETITEMCONFIGURATION
 */

function requestAddBasketItemConfiguration(popupState) {
	return {
		type: REQUEST_ADDBASKETITEMCONFIGURATION,
		popupState,
	};
}

export function receiveAddBasketItemConfiguration(data, popupState) {
	return {
		type: RECEIVE_ADDBASKETITEMCONFIGURATION,
		data,
		popupState,
	};
}

export function addBasketItemConfiguration(
	popupState,
	addressID,
	deliveryDate,
	reference,
	expressDelivery,
	configurationQuantity
) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl == null) {
			console.error("Can't find BasketDispatchUrl in extension!");
			return;
		}

		return dispatch((dispatch) => {
			dispatch(requestAddBasketItemConfiguration(popupState));

			let body = 'addConfiguration=addConfiguration';
			body += '&SynchronizerToken=' + synchronizerToken;
			body += '&AddBasketConfiguration_LineID=' + popupState.id;

			if (addressID) {
				body += '&AddBasketConfiguration_AddressID=' + addressID;
			}
			if (deliveryDate) {
				body += '&AddBasketConfiguration_Date=' + deliveryDate;
			}
			if (reference) {
				body += '&AddBasketConfiguration_Reference=' + reference;
			}
			if (expressDelivery) {
				body += '&AddBasketConfiguration_ExpressDelivery=' + expressDelivery;
			}
			if (configurationQuantity) {
				body += '&AddBasketConfiguration_ConfigurationQuantity=' + configurationQuantity;
			}

			return fetch(`${basketDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
				.then(getJsonOrRedirect)
				.then((data) => dispatch(receiveAddBasketItemConfiguration(data, popupState)));
		});
	};
}

/**
 *  CONFIGURATION DATA
 */

function requestConfigurationData() {
	return {
		type: REQUEST_CONFIGURATION_DATA,
	};
}

export function receiveConfigurationData(data) {
	return {
		type: RECEIVE_CONFIGURATION_DATA,
		data,
	};
}

export function getConfigurationData(sku) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl == null) {
			console.error("Can't find BasketDispatchUrl in extension!");
			return;
		}

		let body = 'getConfigurationData=getConfigurationData';
		body += '&SynchronizerToken=' + synchronizerToken;
		body += '&ViewBasketConfiguration_SKU=' + sku;

		return dispatch((dispatch) => {
			dispatch(requestConfigurationData());
			return fetch(`${basketDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
				.then(getJsonOrRedirect)
				.then((data) => dispatch(receiveConfigurationData(data)));
		});
	};
}

/**
 *  PRODUCT COMMENT
 */
function requestAddProductComment() {
	return {
		type: REQUEST_ADDPRODUCTCOMMENT,
	};
}

export function receiveAddProductComment(dispatchUrl, json) {
	return {
		type: RECEIVE_ADDPRODUCTCOMMENT,
		dispatchUrl,
		data: json,
	};
}

/**
 *  PRODUCT FAVORITE
 */
function requestUpdateFavoriteProduct(index) {
	return {
		type: REQUEST_UPDATEFAVORITEPRODUCT,
		index,
	};
}

export function receiveUpdateFavoriteProduct(json, sku, favorite) {
	return {
		type: RECEIVE_UPDATEFAVORITEPRODUCT,
		data: json,
		sku,
		favorite,
	};
}

/**
 *  Search COMMENT
 */
function requestAddSearchComment() {
	return {
		type: REQUEST_ADDSEARCHCOMMENT,
	};
}

function receiveAddSearchComment(dispatchUrl, json) {
	return {
		type: RECEIVE_ADDSEARCHCOMMENT,
		dispatchUrl,
		data: json,
	};
}

export function addSearchComment(subject, message, mailTo, searchTerm) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const dispatchUrl = extensions.ContactDispatchUrl;

		dispatch(requestAddSearchComment());

		const body =
			'addSearchComment=addSearchComment&Contact_Subject=' +
			subject +
			'&Contact_Msg=' +
			message +
			'&SynchronizerToken=' +
			synchronizerToken +
			'&Contact_MailTo=' +
			mailTo +
			'&SearchTerm=' +
			searchTerm;

		return fetch(`${dispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveAddSearchComment(dispatchUrl, json)));
	};
}

function addProductToBasket(
	sku,
	familyProduct,
	productLoading,
	quantity,
	dispatchUrl,
	synchronizerToken
) {
	return (dispatch, getState) => {
		dispatch(requestAddProductToBasket(familyProduct, productLoading));

		const { productLister, extensions } = getState();
		let list = getList(productLister, extensions);
		const body =
			'addProduct=addProduct&SKU=' +
			sku +
			'&Quantity_' +
			sku +
			'=' +
			quantity +
			'&SynchronizerToken=' +
			synchronizerToken;

		return fetch(`${dispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getJsonOrRedirect)
			.then((json) =>
				dispatch(
					receiveAddProductToBasket(
						dispatchUrl,
						familyProduct,
						productLoading,
						json,
						list,
						quantity
					)
				)
			);
	};
}

function addProductToDropshipmentBasket(sku, index, quantity, dispatchUrl, synchronizerToken) {
	return (dispatch) => {
		dispatch(requestAddProductToDropshipmentBasket(index));

		const body =
			'addDropshipmentProduct=addDropshipmentProduct&SKU=' +
			sku +
			'&Quantity_' +
			sku +
			'=' +
			quantity +
			'&SynchronizerToken=' +
			synchronizerToken;

		return fetch(`${dispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveAddProductToDropshipmentBasket(dispatchUrl, index, json)));
	};
}

export function addRelatedProductToBasket(product, quantity, listType) {
	return (dispatch, getState) => {
		const { productLister, extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;
		let list = getList(productLister, extensions);
		list += ' - Related';
		//add detail-followuparticles /  detail-accessoryarticles / detail-alternativearticles
		list += ' - ' + listType;

		dispatch(requestAddRelatedProductToBasket());

		const body =
			'addProduct=addProduct&SKU=' +
			product.sku +
			'&Quantity_' +
			product.sku +
			'=' +
			quantity +
			'&SynchronizerToken=' +
			synchronizerToken;

		return fetch(`${basketDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveAddRelatedProductToBasket(product, json, list)));
	};
}

export function addProductComment(subject, message, mailTo) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const dispatchUrl = extensions.ContactDispatchUrl;

		dispatch(requestAddProductComment());

		const body =
			'addProductComment=addProductComment&Contact_Subject=' +
			subject +
			'&Contact_Msg=' +
			message +
			'&SynchronizerToken=' +
			synchronizerToken +
			'&Contact_MailTo=' +
			mailTo;

		return fetch(`${dispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveAddProductComment(dispatchUrl, json)));
	};
}

function receiveAddProductToBasketToLarge(sku, familyProduct, productLoading, quantity) {
	return {
		type: RECEIVE_ADDPRODUCTTOBASKET_TOLARGE,
		sku,
		familyProduct,
		productLoading,
		quantity,
	};
}

export function addProductToBasketToLarge(sku, familyProduct, productLoading, quantity) {
	return (dispatch) => {
		return dispatch(receiveAddProductToBasketToLarge(sku, familyProduct, productLoading, quantity));
	};
}

export function addProductToBasketBySku(sku, familyProduct, productLoading, quantity) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl) {
			return dispatch(
				addProductToBasket(
					sku,
					familyProduct,
					productLoading,
					quantity,
					basketDispatchUrl,
					synchronizerToken
				)
			);
		} else {
			console.error("Can't find BasketDispatchUrl in extension!");
		}
	};
}

export function addProductToDropshipmentBasketBySku(sku, index, quantity, groupindex) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl) {
			return dispatch(
				addProductToDropshipmentBasket(sku, index, quantity, basketDispatchUrl, synchronizerToken)
			);
		} else {
			console.error("Can't find BasketDispatchUrl in extension!");
		}
	};
}

export function updateFavoriteProduct(sku, favorite, index) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken, productLister } = getState();
		const listerDispatchUrl = extensions.ListerDispatchUrl;

		var product = productLister.currentProductLister.products.find(function (i) {
			return i.sku === sku;
		});

		dispatch(requestUpdateFavoriteProduct());

		const body =
			'updateFavoriteProduct=updateFavoriteProduct&SKU=' +
			sku +
			'&favorite=' +
			favorite +
			'&SynchronizerToken=' +
			synchronizerToken;

		return fetch(`${listerDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => dispatch(receiveUpdateFavoriteProduct(json, sku, favorite)))
			.then((json) => pushUpdateToFavorite(product, favorite));
	};
}

/**
 *  PRODUCTDETAIL ACTION
 */

export function requestGetProductDetailData() {
	return {
		type: REQUEST_GETPRODUCTDETAIL_DATA,
	};
}

export function receiveGetProductDetailData(json) {
	return {
		type: RECEIVE_GETPRODUCTDETAIL_DATA,
		data: json,
	};
}
export const setSelectedSku = (sku) => ({
	type: 'SET_SELECTED_SKU',
	payload: sku,
});

export function getProductDetailData(sku) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const dispatchUrl = extensions.ListerDispatchUrl;

		if (dispatchUrl == null) {
			console.error("Can't find ListerDispatchUrl in extension!");
			return;
		}

		dispatch(requestGetProductDetailData());

		const params =
			'&getProductDetailData=getProductDetailData&SKU=' +
			sku +
			'&SynchronizerToken=' +
			synchronizerToken;
		const url = dispatchUrl + params;
		return fetch(`${url}`, {
			method: 'get',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		})
			.then(getJsonOrRedirect)
			.then((json) => dispatch(receiveGetProductDetailData(json)));
	};
}

export function receiveClearProductDetailData() {
	return {
		type: CLEAR_PRODUCTDETAIL_DATA,
	};
}

export function clearProductDetailData() {
	return (dispatch, getState) => {
		dispatch(receiveClearProductDetailData());
	};
}

/**
 *  REQUEST_CONFIGURATION_CLOSE
 */

export function requestCloseBasketItemConfiguration() {
	return {
		type: REQUEST_CLOSEBASKETITEMCONFIGURATION,
	};
}

export function closeBasketItemConfiguration() {
	return (dispatch) => {
		return dispatch((dispatch) => {
			dispatch(requestCloseBasketItemConfiguration());
		});
	};
}

/**
 *  REQUEST_UPDATE
 */
function requestUpdate(index, action, showTileView) {
	return {
		type: REQUEST_UPDATE,
		index,
		action,
		showTileView,
	};
}

export function doUp(index, showTileView) {
	return (dispatch) => {
		return dispatch((dispatch) => {
			dispatch(requestUpdate(index, 'up', showTileView));
		});
	};
}

export function doDown(index, showTileView) {
	return (dispatch) => {
		return dispatch((dispatch) => {
			dispatch(requestUpdate(index, 'down', showTileView));
		});
	};
}

export function doOpen(index, showTileView) {
	return (dispatch) => {
		return dispatch((dispatch) => {
			dispatch(requestUpdate(index, 'open'));
		});
	};
}

export function doClose(index) {
	return (dispatch) => {
		return dispatch((dispatch) => {
			dispatch(requestUpdate(index, 'close'));
		});
	};
}

export function doClick(index) {
	return (dispatch) => {
		return dispatch((dispatch) => {
			dispatch(requestUpdate(index, 'click'));
		});
	};
}

/**
 *  REQUEST_INIT
 */
export function init() {
	return {
		type: INIT,
	};
}
