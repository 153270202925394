import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as menuActions from 'shared/general/headermainmenu/actions';

function createConnect<StateProps, DispatchProps, OwnProps>() {
	const mapStateToProps = (state: any, ownProps: any): any => {
		let initialState = {
			localizations: state.localizations,
			extensions: state.extensions,
			general: state.general,
			headermainmenu: state.headermainmenu,
			data: undefined,
		};

		if (ownProps.reactId) {
			//data-react-data attributes are gathered in a single object for all components, map the state back to this specific component
			let menuData = state.data[ownProps.reactId];
			initialState = Object.assign(initialState, {
				data: menuData,
				synchronizerToken: state.synchronizer[ownProps.reactId],
				headermainmenu: state[ownProps.reactId],
			});
		}
		return initialState;
	};

	const mapDispatchProps = (dispatch: any): any => {
		const actions = {
			...menuActions,
		};
		return bindActionCreators(actions, dispatch);
	};

	const connectContext = connect<StateProps, DispatchProps, OwnProps>(
		mapStateToProps,
		mapDispatchProps
	);
	return connectContext;
}

export default createConnect;
