import fetch from 'isomorphic-fetch';
import { pushUpdateToFavorite } from 'shared/libs/gaevents';

/**
 * BASKET ACTION CONSTANTS
 */
export const RECEIVE_CLEARBASKETITEMPROGRESS = 'RECEIVE_CLEARBASKETITEMPROGRESS';

export const REQUEST_REMOVEPRODUCTFROMBASKET = 'REQUEST_REMOVEPRODUCTFROMBASKET';
export const RECEIVE_REMOVEPRODUCTFROMBASKET = 'RECEIVE_REMOVEPRODUCTFROMBASKET';

export const REQUEST_UPDATEPRODUCTQUANTITY = 'REQUEST_UPDATEPRODUCTQUANTITY';
export const RECEIVE_UPDATEPRODUCTQUANTITY = 'RECEIVE_UPDATEPRODUCTQUANTITY';

export const REQUEST_GETBASKETITEMCONFIGURATION = 'REQUEST_GETBASKETITEMCONFIGURATION';
export const RECEIVE_GETBASKETITEMCONFIGURATION = 'RECEIVE_GETBASKETITEMCONFIGURATION';

export const REQUEST_ADDBASKETITEMCONFIGURATION = 'REQUEST_ADDBASKETITEMCONFIGURATION';
export const RECEIVE_ADDBASKETITEMCONFIGURATION = 'RECEIVE_ADDBASKETITEMCONFIGURATION';

export const REQUEST_REMOVEBASKETITEMCONFIGURATION = 'REQUEST_REMOVEBASKETITEMCONFIGURATION';
export const RECEIVE_REMOVEBASKETITEMCONFIGURATION = 'RECEIVE_REMOVEBASKETITEMCONFIGURATION';

export const REQUEST_REMOVEBASKETITEMCONFIGURATIONS = 'REQUEST_REMOVEBASKETITEMCONFIGURATIONS';
export const RECEIVE_REMOVEBASKETITEMCONFIGURATIONS = 'RECEIVE_REMOVEBASKETITEMCONFIGURATIONS';

export const REQUEST_UPDATEPARTIALBASKET = 'REQUEST_UPDATEPARTIALBASKET';
export const RECEIVE_UPDATEPARTIALBASKET = 'RECEIVE_UPDATEPARTIALBASKET';

export const REQUEST_UPDATEBASKETITEMCONFIGURATION = 'REQUEST_UPDATEBASKETITEMCONFIGURATION';
export const RECEIVE_UPDATEBASKETITEMCONFIGURATION = 'RECEIVE_UPDATEBASKETITEMCONFIGURATION';

export const REQUEST_UPDATEBASKETITEMCONFIGURATIONS = 'REQUEST_UPDATEBASKETITEMCONFIGURATIONS';
export const RECEIVE_UPDATEBASKETITEMCONFIGURATIONS = 'RECEIVE_UPDATEBASKETITEMCONFIGURATIONS';

export const REQUEST_CLOSEBASKETITEMCONFIGURATION = 'REQUEST_CLOSEBASKETITEMCONFIGURATION';

export const REQUEST_GETBASKETITEMSALTERNATIVES = 'REQUEST_GETBASKETITEMSALTERNATIVES';
export const RECEIVE_GETBASKETITEMSALTERNATIVES = 'RECEIVE_GETBASKETITEMSALTERNATIVES';

export const REQUEST_GETBASKETITEM_BUNDLE = 'REQUEST_GETBASKETITEM_BUNDLE';
export const RECEIVE_GETBASKETITEM_BUNDLE = 'RECEIVE_GETBASKETITEM_BUNDLE';

export const REQUEST_SWITCHBASKETITEM = 'REQUEST_SWITCHBASKETITEM';
export const RECEIVE_SWITCHBASKETITEM = 'RECEIVE_SWITCHBASKETITEM';

export const REQUEST_MOVETODROPSHIPMENT = 'REQUEST_MOVETODROPSHIPMENT';
export const RECEIVE_MOVETODROPSHIPMENT = 'RECEIVE_MOVETODROPSHIPMENT';

export const REQUEST_UPDATEFAVORITEPRODUCT = 'REQUEST_UPDATEFAVORITEPRODUCT';
export const RECEIVE_UPDATEFAVORITEPRODUCT = 'RECEIVE_UPDATEFAVORITEPRODUCT';

let basketItemProgressHandle = null;
function receiveClearBasketItemProgress() {
	return {
		type: RECEIVE_CLEARBASKETITEMPROGRESS,
	};
}

/**
 *  BASKET UPDATE PRODUCT ACTIONS
 */
function requestUpdateProductQuantity(sku, id) {
	return {
		type: REQUEST_UPDATEPRODUCTQUANTITY,
		sku,
		id,
	};
}

function receiveUpdateProductQuantity(sku, id, json) {
	return {
		type: RECEIVE_UPDATEPRODUCTQUANTITY,
		sku,
		id,
		data: json,
	};
}

export function updateProductQuantity(sku, id, quantity) {
	return (dispatch, getState) => {
		dispatch(requestUpdateProductQuantity(sku, id));

		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		const body =
			'updateProductQuantity=updateProductQuantity&SKU=' +
			sku +
			'&Quantity_' +
			sku +
			'=' +
			quantity +
			'&SynchronizerToken=' +
			synchronizerToken;
		return fetch(`${basketDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => {
				dispatch(receiveUpdateProductQuantity(sku, id, json));

				clearTimeout(basketItemProgressHandle);
				basketItemProgressHandle = setTimeout(() => {
					dispatch(receiveClearBasketItemProgress());
				}, 3000);
			});
	};
}

/**
 *  BASKET REMOVE PRODUCT ACTIONS
 */
function requestRemoveProductFromBasket(index) {
	return {
		type: REQUEST_REMOVEPRODUCTFROMBASKET,
		index,
	};
}

function receiveRemoveProductFromBasket(dispatchUrl, index, json) {
	return {
		type: RECEIVE_REMOVEPRODUCTFROMBASKET,
		dispatchUrl,
		index,
		data: json,
	};
}

function removeProductFromBasket(id, index, dispatchUrl, synchronizerToken) {
	return (dispatch) => {
		dispatch(requestRemoveProductFromBasket(index));

		const body = 'removeProduct=removeProduct&id=' + id + '&SynchronizerToken=' + synchronizerToken;
		return fetch(`${dispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => dispatch(receiveRemoveProductFromBasket(dispatchUrl, index, json)));
	};
}

export function removeProductFromBasketById(id, index) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;
		if (basketDispatchUrl) {
			return dispatch(removeProductFromBasket(id, index, basketDispatchUrl, synchronizerToken));
		} else {
			console.error("Can't find BasketDispatchUrl in extension!");
		}
	};
}

/**
 *  REQUEST_UPDATEBASKETITEM
 */

function requestUpdatePartialBasket(lineIDs) {
	return {
		type: REQUEST_UPDATEPARTIALBASKET,
		lineIDs,
	};
}

function receiveUpdatePartialBasket(data) {
	return {
		type: RECEIVE_UPDATEPARTIALBASKET,
		data,
	};
}

export function updatePartialBasketItem(lineID, selected) {
	let lineIDs = [lineID];
	return (dispatch, getState) => {
		updatePartialBasketItems(dispatch, getState, lineIDs, selected);
	};
}

export function updatePartialBasket(selected) {
	return (dispatch, getState) => {
		const state = getState();
		const currentBasketReducer = state.basketreducer;
		let lineIDs = [];
		lineIDs = lineIDs.concat(currentBasketReducer.basket.bikes.map((item) => item.id));
		lineIDs = lineIDs.concat(currentBasketReducer.basket.parts.map((item) => item.id));
		updatePartialBasketItems(dispatch, getState, lineIDs, selected);
	};
}

export function updatePartialBasketBikes(selected) {
	return (dispatch, getState) => {
		const state = getState();
		const currentBasketReducer = state.basketreducer;
		let lineIDs = [];
		lineIDs = lineIDs.concat(currentBasketReducer.basket.bikes.map((item) => item.id));
		updatePartialBasketItems(dispatch, getState, lineIDs, selected);
	};
}

export function updatePartialBasketParts(selected) {
	return (dispatch, getState) => {
		const state = getState();
		const currentBasketReducer = state.basketreducer;
		let lineIDs = [];
		lineIDs = lineIDs.concat(currentBasketReducer.basket.parts.map((item) => item.id));
		updatePartialBasketItems(dispatch, getState, lineIDs, selected);
	};
}

function updatePartialBasketItems(dispatch, getState, lineIDs, selected) {
	dispatch(requestUpdatePartialBasket(lineIDs));

	const state = getState();
	const { extensions, synchronizerToken } = state;
	//const currentBasketReducer = state.basketreducer;
	//const basketconfiguration = currentBasketReducer.basketconfiguration;
	const basketDispatchUrl = extensions.BasketDispatchUrl;

	if (basketDispatchUrl == null) {
		console.error("Can't find BasketDispatchUrl in extension!");
		return;
	}

	let body = 'updateBasketItem=updateBasketItem';
	body += '&SynchronizerToken=' + synchronizerToken;

	if (selected) {
		body += '&UpdateBasketItem_PartialBasket=true';
	} else {
		body += '&UpdateBasketItem_PartialBasket=false';
	}

	lineIDs.forEach(function (LineID) {
		body += `&LineID=${LineID}`;
	});

	return fetch(`${basketDispatchUrl}`, {
		method: 'post',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: body,
	})
		.then((response) => response.json())
		.then((data) => dispatch(receiveUpdatePartialBasket(data)));
}

/**
 *  REQUEST_GETBASKETITEMCONFIGURATION
 */

function requestGetBasketItemConfiguration(popupState) {
	return {
		type: REQUEST_GETBASKETITEMCONFIGURATION,
		popupState,
	};
}

export function receiveGetBasketItemConfiguration(data, popupState, configuration) {
	return {
		type: RECEIVE_GETBASKETITEMCONFIGURATION,
		data,
		popupState,
		configuration,
	};
}

export function getBasketItemConfigurationContext(id, popupState, configuration) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl == null) {
			console.error("Can't find BasketDispatchUrl in extension!");
			return;
		}

		return dispatch((dispatch) => {
			dispatch(requestGetBasketItemConfiguration(popupState));

			let body = 'getConfigurationContext=getConfigurationContext';
			body += '&SynchronizerToken=' + synchronizerToken;
			body += '&ViewBasketConfiguration_LineID=' + id;

			return fetch(`${basketDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
				.then((response) => response.json())
				.then((data) =>
					dispatch(receiveGetBasketItemConfiguration(data, popupState, configuration))
				);
		});
	};
}

/**
 *  REQUEST_ADDBASKETITEMCONFIGURATION
 */

function requestAddBasketItemConfiguration(popupState) {
	return {
		type: REQUEST_ADDBASKETITEMCONFIGURATION,
		popupState,
	};
}

export function receiveAddBasketItemConfiguration(data, popupState) {
	return {
		type: RECEIVE_ADDBASKETITEMCONFIGURATION,
		data,
		popupState,
	};
}

export function addBasketItemConfiguration(
	popupState,
	addressID,
	deliveryDate,
	reference,
	expressDelivery,
	configurationQuantity
) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl == null) {
			console.error("Can't find BasketDispatchUrl in extension!");
			return;
		}

		return dispatch((dispatch) => {
			dispatch(requestAddBasketItemConfiguration(popupState));

			let body = 'addConfiguration=addConfiguration';
			body += '&SynchronizerToken=' + synchronizerToken;
			body += '&AddBasketConfiguration_LineID=' + popupState.id;

			if (addressID) {
				body += '&AddBasketConfiguration_AddressID=' + addressID;
			}
			if (deliveryDate) {
				body += '&AddBasketConfiguration_Date=' + deliveryDate;
			}
			if (reference) {
				body += '&AddBasketConfiguration_Reference=' + reference;
			}
			if (expressDelivery) {
				body += '&AddBasketConfiguration_ExpressDelivery=' + expressDelivery;
			}
			if (configurationQuantity) {
				body += '&AddBasketConfiguration_ConfigurationQuantity=' + configurationQuantity;
			}

			return fetch(`${basketDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
				.then((response) => response.json())
				.then((data) => dispatch(receiveAddBasketItemConfiguration(data, popupState)));
		});
	};
}

/**
 *  REQUEST_UPDATEBASKETITEMCONFIGURATION
 */

function requestUpdateBasketItemConfiguration(popupState) {
	return {
		type: REQUEST_UPDATEBASKETITEMCONFIGURATION,
		popupState,
	};
}

export function receiveUpdateBasketItemConfiguration(data, basketconfiguration) {
	return {
		type: RECEIVE_UPDATEBASKETITEMCONFIGURATION,
		data,
		basketconfiguration,
	};
}

export function updateBasketItemConfiguration(
	popupState,
	configurationID,
	addressID,
	deliveryDate,
	reference,
	expressDelivery
) {
	return (dispatch, getState) => {
		const state = getState();
		const { extensions, synchronizerToken } = state;
		const currentBasketReducer = state.basketreducer; //prevent basketreducer naming conflicts
		const basketconfiguration = currentBasketReducer.basketconfiguration;
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl == null) {
			console.error("Can't find BasketDispatchUrl in extension!");
			return;
		}

		return dispatch((dispatch) => {
			dispatch(requestUpdateBasketItemConfiguration(popupState));

			let body = 'updateConfiguration=updateConfiguration';
			body += '&SynchronizerToken=' + synchronizerToken;
			body += '&UpdateBasketConfiguration_LineID=' + popupState.id;

			if (addressID) {
				body += '&UpdateBasketConfiguration_AddressID=' + addressID;
			}
			if (deliveryDate) {
				body += '&UpdateBasketConfiguration_Date=' + deliveryDate;
			}
			if (reference) {
				body += '&UpdateBasketConfiguration_Reference=' + reference;
			}

			body += '&UpdateBasketConfiguration_ExpressDelivery=' + expressDelivery;

			body += '&UpdateBasketConfiguration_ConfigurationID=' + configurationID;

			return fetch(`${basketDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
				.then((response) => response.json())
				.then((data) => dispatch(receiveUpdateBasketItemConfiguration(data, basketconfiguration)));
		});
	};
}

/**
 *  REQUEST_REMOVEBASKETITEMCONFIGURATION
 */

function requestRemoveBasketItemConfiguration(configurationID) {
	return {
		type: REQUEST_REMOVEBASKETITEMCONFIGURATION,
		configurationID,
	};
}

export function receiveRemoveBasketItemConfiguration(data, configurationID) {
	return {
		type: RECEIVE_REMOVEBASKETITEMCONFIGURATION,
		data,
		configurationID,
	};
}

export function removeBasketItemConfiguration(lineID, configurationID) {
	return (dispatch, getState) => {
		const state = getState();
		const { extensions, synchronizerToken } = state;
		//const currentBasketReducer = state.basketreducer; //prevent basketreducer naming conflicts
		//const basketconfiguration = currentBasketReducer.basketconfiguration;
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl == null) {
			console.error("Can't find BasketDispatchUrl in extension!");
			return;
		}

		return dispatch((dispatch) => {
			dispatch(requestRemoveBasketItemConfiguration(configurationID));

			let body = 'removeConfiguration=removeConfiguration';
			body += '&SynchronizerToken=' + synchronizerToken;
			body += '&RemoveBasketConfiguration_LineID=' + lineID;
			body += '&RemoveBasketConfiguration_ConfigurationID=' + configurationID;

			return fetch(`${basketDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
				.then((response) => response.json())
				.then((data) => dispatch(receiveRemoveBasketItemConfiguration(data, configurationID)));
		});
	};
}

/**
 *  REQUEST_UPDATEBASKETITEMCONFIGURATIONS
 */

function requestUpdateBasketItemConfigurations(popupState) {
	return {
		type: REQUEST_UPDATEBASKETITEMCONFIGURATIONS,
		popupState,
	};
}

export function receiveUpdateBasketItemConfigurations(data, basketconfiguration) {
	return {
		type: RECEIVE_UPDATEBASKETITEMCONFIGURATIONS,
		data,
		basketconfiguration,
	};
}

export function updateBasketItemConfigurations(
	popupState,
	configurationIDs,
	addressID,
	deliveryDate,
	reference,
	expressDelivery
) {
	return (dispatch, getState) => {
		const state = getState();
		const { extensions, synchronizerToken } = state;
		const currentBasketReducer = state.basketreducer; //prevent basketreducer naming conflicts
		const basketconfiguration = currentBasketReducer.basketconfiguration;
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl == null) {
			console.error("Can't find BasketDispatchUrl in extension!");
			return;
		}

		return dispatch((dispatch) => {
			dispatch(requestUpdateBasketItemConfigurations(popupState));

			let body = 'updateConfigurations=updateConfigurations';
			body += '&SynchronizerToken=' + synchronizerToken;
			body += '&UpdateBasketConfiguration_LineID=' + popupState.id;

			if (addressID) {
				body += '&UpdateBasketConfiguration_AddressID=' + addressID;
			}
			if (deliveryDate) {
				body += '&UpdateBasketConfiguration_Date=' + deliveryDate;
			}
			if (reference) {
				body += '&UpdateBasketConfiguration_Reference=' + reference;
			}

			body += '&UpdateBasketConfiguration_ExpressDelivery=' + expressDelivery;

			configurationIDs.forEach(function (configurationID) {
				body += `&UpdateBasketConfiguration_ConfigurationID=${configurationID}`;
			});

			return fetch(`${basketDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
				.then((response) => response.json())
				.then((data) => dispatch(receiveUpdateBasketItemConfigurations(data, basketconfiguration)));
		});
	};
}

/**
 *  REQUEST_REMOVEBASKETITEMCONFIGURATIONS
 */

function requestRemoveBasketItemConfigurations(configurationIDs) {
	return {
		type: REQUEST_REMOVEBASKETITEMCONFIGURATIONS,
		configurationIDs,
	};
}

export function receiveRemoveBasketItemConfigurations(data, configurationIDs) {
	return {
		type: RECEIVE_REMOVEBASKETITEMCONFIGURATIONS,
		data,
		configurationIDs,
	};
}

export function removeBasketItemConfigurations(lineID, configurationIDs) {
	return (dispatch, getState) => {
		const state = getState();
		const { extensions, synchronizerToken } = state;
		//const currentBasketReducer = state.basketreducer; //prevent basketreducer naming conflicts
		//const basketconfiguration = currentBasketReducer.basketconfiguration;
		const basketDispatchUrl = extensions.BasketDispatchUrl;

		if (basketDispatchUrl == null) {
			console.error("Can't find BasketDispatchUrl in extension!");
			return;
		}

		return dispatch((dispatch) => {
			dispatch(requestRemoveBasketItemConfigurations(configurationIDs));

			let body = 'removeConfigurations=removeConfigurations';
			body += '&SynchronizerToken=' + synchronizerToken;
			body += '&RemoveBasketConfiguration_LineID=' + lineID;
			configurationIDs.forEach(function (configurationID) {
				body += `&RemoveBasketConfiguration_ConfigurationID=${configurationID}`;
			});

			return fetch(`${basketDispatchUrl}`, {
				method: 'post',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: body,
			})
				.then((response) => response.json())
				.then((data) => dispatch(receiveRemoveBasketItemConfigurations(data, configurationIDs)));
		});
	};
}

/**
 *  REQUEST_CLOSEBASKETITEMCONFIGURATION
 */

export function requestCloseBasketItemConfiguration() {
	return {
		type: REQUEST_CLOSEBASKETITEMCONFIGURATION,
	};
}

export function closeBasketItemConfiguration() {
	return (dispatch) => {
		return dispatch((dispatch) => {
			dispatch(requestCloseBasketItemConfiguration());
		});
	};
}

/**
 *  REQUEST_GETBASKETITEMSALTERNATIVES
 */
function requestGetBasketItemAlternatives(lineID) {
	return {
		type: REQUEST_GETBASKETITEMSALTERNATIVES,
		lineID,
	};
}

function receiveGetBasketItemAlternatives(lineID, json) {
	return {
		type: RECEIVE_GETBASKETITEMSALTERNATIVES,
		lineID: lineID,
		data: json,
	};
}

export function getBasketItemAlternatives(lineID) {
	return (dispatch, getState) => {
		dispatch(requestGetBasketItemAlternatives(lineID));

		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;
		const body =
			'getAlternatives=getAlternatives&LineID=' +
			lineID +
			'&SynchronizerToken=' +
			synchronizerToken;
		return fetch(`${basketDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => {
				dispatch(receiveGetBasketItemAlternatives(lineID, json));
			});
	};
}

/**
 *  REQUEST_GETBASKETITEM_BUNDLE
 */
function requestGetBasketItemBundle(lineID) {
	return {
		type: REQUEST_GETBASKETITEM_BUNDLE,
		lineID,
	};
}

function receiveGetBasketItemBundle(lineID, json) {
	return {
		type: RECEIVE_GETBASKETITEM_BUNDLE,
		lineID: lineID,
		data: json,
	};
}

export function getBasketItemBundle(lineID) {
	return (dispatch, getState) => {
		dispatch(requestGetBasketItemBundle(lineID));

		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;
		const body = 'getBundle=getBundle&LineID=' + lineID + '&SynchronizerToken=' + synchronizerToken;
		return fetch(`${basketDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => {
				dispatch(receiveGetBasketItemBundle(lineID, json));
			});
	};
}

/**
 *  REQUEST_SWITCHBASKETITEM
 */
function requestSwitchBasketItem(lineID, newSKU) {
	return {
		type: REQUEST_SWITCHBASKETITEM,
		lineID: lineID,
		newSKU: newSKU,
	};
}

function receiveSwitchBasketItem(lineID, newSKU, json) {
	return {
		type: RECEIVE_SWITCHBASKETITEM,
		lineID: lineID,
		newSKU: newSKU,
		data: json,
	};
}

export function switchBasketItem(lineID, newSKU) {
	return (dispatch, getState) => {
		dispatch(requestSwitchBasketItem(lineID, newSKU));

		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;
		const body =
			'switchBasketItem=switchBasketItem&SwitchBasketItem_LineID=' +
			lineID +
			'&SwitchBasketItem_SKU=' +
			newSKU +
			'&SynchronizerToken=' +
			synchronizerToken;
		return fetch(`${basketDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => {
				dispatch(receiveSwitchBasketItem(lineID, newSKU, json));
			});
	};
}

/**
 *  REQUEST_MOVETODROPSHIPMENT
 */
function requestMoveToDropshipment(sku, lineID) {
	return {
		type: REQUEST_MOVETODROPSHIPMENT,
		id: sku,
		lineID: lineID,
	};
}

function receiveMoveToDropshipment(sku, lineID, removed, json) {
	return {
		type: RECEIVE_MOVETODROPSHIPMENT,
		id: sku,
		lineID: lineID,
		removed: removed,
		data: json,
	};
}

export function moveToDropshipment(sku, lineID, removed = false) {
	return (dispatch, getState) => {
		// if (removed) {
		//     return dispatch(receiveMoveToDropshipment(sku, lineID, removed, null));
		// }

		dispatch(requestMoveToDropshipment(sku, lineID));

		const { extensions, synchronizerToken } = getState();
		const basketDispatchUrl = extensions.BasketDispatchUrl;
		const body =
			'moveToDropshipment=moveToDropshipment&LineID=' +
			lineID +
			'&SynchronizerToken=' +
			synchronizerToken;
		return fetch(`${basketDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => {
				dispatch(receiveMoveToDropshipment(sku, lineID, removed, json));
				setTimeout(() => {
					dispatch(receiveMoveToDropshipment(sku, lineID, true, null));
				}, 500);
			});
	};
}

/**
 *  PRODUCT FAVORITE
 */
function requestUpdateFavoriteProduct() {
	return {
		type: REQUEST_UPDATEFAVORITEPRODUCT,
	};
}

export function receiveUpdateFavoriteProduct(lineID, json, favorite) {
	return {
		type: RECEIVE_UPDATEFAVORITEPRODUCT,
		lineID,
		data: json,
		favorite,
	};
}

export function updateFavoriteProduct(productLine, favorite) {
	return (dispatch, getState) => {
		const { id, sku } = productLine;
		const { extensions, synchronizerToken } = getState();
		const listerDispatchUrl = extensions.ListerDispatchUrl;

		dispatch(requestUpdateFavoriteProduct());

		const body =
			'updateFavoriteProduct=updateFavoriteProduct&SKU=' +
			sku +
			'&favorite=' +
			favorite +
			'&SynchronizerToken=' +
			synchronizerToken;

		return fetch(`${listerDispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response) => response.json())
			.then((json) => dispatch(receiveUpdateFavoriteProduct(id, json, favorite)))
			.then((json) => pushUpdateToFavorite(productLine, favorite));
	};
}
