/**
 * REPORTS ACTIONS CONSTANTS
 */
import { ProductActions } from 'productblock/container/types';
import { IProduct, IAddToBasketResult } from 'productblock/messages/messages';
import { IHTTPResult, ProgressStatus } from 'productblock/types';
import { getJsonOrRedirect } from 'shared/libs/urlhandling';
import $ from 'jquery';
import fetch from 'isomorphic-fetch';
import { pushUpdateToFavorite } from 'shared/libs/gaevents';

function assignReactId(obj: object, reactId: string) {
	return Object.assign(obj, {
		reactId: reactId,
	});
}

/* GET PRODUCTS */

function requestProduct(skus: string[]) {
	return {
		type: ProductActions.REQUEST_PRODUCT,
		skus,
	};
}

function receiveProduct(skus: string[], postResult: IHTTPResult, products: IProduct[]) {
	return {
		type: ProductActions.RECEIVE_PRODUCT,
		skus,
		postResult,
		products,
	};
}

export function fetchProduct(reactId, skus: string[]) {
	return (dispatch: any, getState: any) => {
		dispatch(assignReactId(requestProduct(skus), reactId));

		const { extensions, synchronizerToken } = getState();
		let url = extensions.ProductUrl;
		let skuData = skus.reduce((accumulator, currentValue) => {
			if (currentValue != '') {
				return accumulator + ',' + currentValue;
			}
			return accumulator;
		});

		let body = '';
		body += 'SKUS=' + skuData;
		body += '&SynchronizerToken=' + synchronizerToken;

		body += '&DataFlag=AddBasketQuantity';
		//body += '&DataFlag=AddDropshipmentQuantity';
		//body += '&DataFlag=AddVariantProducts';
		body += '&DataFlag=AddPermissions';
		body += '&DataFlag=AddFavorites';
		body += '&DataFlag=AddStock';
		//body += '&DataFlag=AddBackorder';
		body += '&DataFlag=AddPrice';
		//body += '&DataFlag=AddExtraImages';

		let postResult: IHTTPResult = {
			json: {},
			status: 0,
			error: true,
		};

		return fetch(`${url}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response: Response) => {
				if (response.status != 200) {
					throw new Error('unable to fetch products');
				}
				let json = response.json();
				postResult.json = json;
				postResult.status = response.status;
				postResult.error = false;
				return json;
			})
			.then((data: object) =>
				dispatch(assignReactId(receiveProduct(skus, postResult, data as IProduct[]), reactId))
			)
			.catch((error: any) =>
				dispatch(assignReactId(receiveProduct(skus, postResult, []), reactId))
			);
	};
}

/* ADD TO BASKET */

function requestAddToBasket(sku: string, quantity: number, status: ProgressStatus) {
	return {
		type: ProductActions.REQUEST_ADDTOBASKET,
		sku,
		quantity,
		status,
	};
}

function updateAddToBasket(sku: string, status: ProgressStatus) {
	return {
		type: ProductActions.UPDATE_ADDTOBASKET,
		sku,
		status,
	};
}

function receiveAddToBasket(sku: string, quantity: number, status: ProgressStatus) {
	return {
		type: ProductActions.RECEIVE_ADDTOBASKET,
		sku,
		quantity,
		status,
	};
}

export function addToBasket(reactId: string, sku: string, quantity: number) {
	return (dispatch: any, getState: any) => {
		dispatch(assignReactId(requestAddToBasket(sku, quantity, 'waiting'), reactId));

		const { extensions, synchronizerToken } = getState();
		let dispatchUrl = extensions.PostUrl;

		const delayAsync = (time) => {
			return new Promise((resolve) => {
				setTimeout(resolve, time);
			});
		};

		let newQuantity = quantity;

		const body =
			'addProduct=addProduct&SKU=' +
			sku +
			'&Quantity_' +
			sku +
			'=' +
			newQuantity +
			'&SynchronizerToken=' +
			synchronizerToken;
		return fetch(`${dispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getJsonOrRedirect)
			.then((json: object) => json as IAddToBasketResult)
			.then((result: IAddToBasketResult) => {
				//update newQuantity in case the quantity was changed serverside
				newQuantity = result.data.value;
				dispatch(assignReactId(receiveAddToBasket(sku, newQuantity, 'success'), reactId));
				//$(document).trigger('basket:updated', { quantity: result.data.totalProductQuantity });
			})
			.then(() => delayAsync(2000))
			.then(() => dispatch(assignReactId(updateAddToBasket(sku, 'none'), reactId)))
			.catch((error: any) => {
				Promise.resolve()
					.then(() =>
						dispatch(assignReactId(receiveAddToBasket(sku, newQuantity, 'error'), reactId))
					)
					.then(() => delayAsync(2000))
					.then(() => dispatch(assignReactId(updateAddToBasket(sku, 'none'), reactId)));
			});
	};
}

/**
 *  PRODUCT FAVORITE
 */
function requestFavoriteProduct(sku: string) {
	return {
		type: ProductActions.REQUEST_FAVORITEPRODUCT,
		sku,
	};
}

export function receiveFavoriteProduct(sku: string, favorite: boolean, postResult: IHTTPResult) {
	return {
		type: ProductActions.RECEIVE_FAVORITEPRODUCT,
		sku,
		favorite,
		postResult,
	};
}

export function updateFavoriteProduct(reactId: string, product: IProduct, favorite: boolean) {
	return (dispatch, getState) => {
		const { extensions, synchronizerToken } = getState();
		let dispatchUrl = extensions.DispatchUrl;
		let sku = product.sku;

		dispatch(assignReactId(requestFavoriteProduct(sku), reactId));

		const body =
			'updateFavoriteProduct=updateFavoriteProduct&SKU=' +
			sku +
			'&favorite=' +
			favorite +
			'&SynchronizerToken=' +
			synchronizerToken;

		let postResult: IHTTPResult = {
			json: {},
			status: 0,
			error: true,
		};

		return fetch(`${dispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response: Response) => {
				if (response.status != 200) {
					throw new Error('unable to update favorite product');
				}
				let json = response.json();
				postResult.json = json;
				postResult.status = response.status;
				postResult.error = false;
				return json;
			})
			.then((json) =>
				dispatch(assignReactId(receiveFavoriteProduct(sku, favorite, postResult), reactId))
			)
			.then((json) => pushUpdateToFavorite(product, favorite))
			.catch((error: any) =>
				dispatch(assignReactId(receiveFavoriteProduct(sku, favorite, postResult), reactId))
			);
	};
}
