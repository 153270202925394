import React, { FC } from 'react';

interface IDirectOrderPrice {
	discountPercentage: number;
	fraction: string;
	name: string;
	quantityFrom: number;
	whole: string;
}

interface OwnProps {
	price: IDirectOrderPrice;
}

type IProps = OwnProps;

export const DirectOrderPrice: FC<IProps> = (props) => {
	const { price } = props;

	if (!price) {
		return null;
	}

	return (
		<ins className='price'>
			€&nbsp;{price.whole},<span className='sup'>{price.fraction}</span>
		</ins>
	);
};

export default DirectOrderPrice;
