import React, { FC } from 'react';

interface OwnProps {
	sale: boolean;

	showMsrPrices: boolean;
}

interface StateProps {
	localizations: object;
}

type IProps = StateProps & OwnProps;

const ProductSale: FC<IProps> = (props) => {
	const { sale, showMsrPrices, localizations } = props;

	if (sale && !showMsrPrices) {
		return <span className='list-item-sale'>{localizations['shared.listeritem.sale.label']}</span>;
	}

	return null;
};

export default ProductSale;
