import { IHTTPResult, IProgressResult, ProgressStatus } from 'shared/types';
import {
	getResultOrRedirect,
	getUnwrappedResultOrRedirect,
	errorResult,
} from 'shared/libs/urlhandling';
import { IApplicationData, IApplicationState } from './types';
import {
	IConfigurationData,
	IAddToBasketResult,
	IConfigurationContext,
} from 'shared/messages/Messages';

export enum BasketActions {
	REQUEST_BASKET_ADDTOBASKET = 'REQUEST_BASKET_ADDTOBASKET',
	UPDATE_BASKET_ADDTOBASKET = 'UPDATE_BASKET_ADDTOBASKET',
	RECEIVE_BASKET_ADDTOBASKET = 'RECEIVE_BASKET_ADDTOBASKET',

	REQUEST_BASKET_GETCONFIGURATIONDATA = 'REQUEST_BASKET_GETCONFIGURATIONDATA',
	RECEIVE_BASKET_GETCONFIGURATIONDATA = 'RECEIVE_BASKET_GETCONFIGURATIONDATA',

	REQUEST_BASKET_GETCONFIGURATIONCONTEXT = 'REQUEST_BASKET_GETCONFIGURATIONCONTEXT',
	RECEIVE_BASKET_GETCONFIGURATIONCONTEXT = 'RECEIVE_BASKET_GETCONFIGURATIONCONTEXT',

	REQUEST_BASKET_ADDCONFIGURATION = 'REQUEST_BASKET_ADDCONFIGURATION',
	RECEIVE_BASKET_ADDCONFIGURATION = 'RECEIVE_BASKET_ADDCONFIGURATION',

	REQUEST_BASKET_CLOSECONFIGURATION = 'REQUEST_BASKET_CLOSECONFIGURATION',

	REQUEST_BASKET_ADDBUNDLETOBASKET = 'REQUEST_BASKET_ADDBUNDLETOBASKET',
	RECEIVE_BASKET_ADDBUNDLETOBASKET = 'RECEIVE_BASKET_ADDBUNDLETOBASKET',

	REQUEST_BASKET_GETBUNDLEDATA = 'REQUEST_BASKET_GETBUNDLEDATA',
	RECEIVE_BASKET_GETBUNDLEDATA = 'RECEIVE_BASKET_GETBUNDLEDATA',
}

interface IConfiguration {
	reference: string;
	suggestedDate?: string;
}

/* ADDTOBASKET */
interface RequestAddToBasket {
	type: typeof BasketActions.REQUEST_BASKET_ADDTOBASKET;
	sku: string;
	quantity: number;
	status: ProgressStatus;
}

const requestAddToBasket = (
	sku: string,
	quantity: number,
	status: ProgressStatus
): RequestAddToBasket => {
	return {
		type: BasketActions.REQUEST_BASKET_ADDTOBASKET,
		sku,
		quantity,
		status,
	};
};

interface UpdateAddToBasket {
	type: typeof BasketActions.UPDATE_BASKET_ADDTOBASKET;
	sku: string;
	status: ProgressStatus;
}

const updateAddToBasket = (sku: string, status: ProgressStatus): UpdateAddToBasket => {
	return {
		type: BasketActions.UPDATE_BASKET_ADDTOBASKET,
		sku,
		status,
	};
};

interface ReceiveAddToBasket {
	type: typeof BasketActions.RECEIVE_BASKET_ADDTOBASKET;
	sku: string;
	quantity: number;
	status: ProgressStatus;
	postResult?: IHTTPResult;
}

const receiveAddToBasket = (
	sku: string,
	quantity: number,
	status: ProgressStatus,
	postResult: IHTTPResult
): ReceiveAddToBasket => {
	return {
		type: BasketActions.RECEIVE_BASKET_ADDTOBASKET,
		sku,
		quantity,
		status,
		postResult,
	};
};

/* ADDBUNDLETOBASKET */

interface IBundleItemQuantity {
	group: string;
	quantity: number;
}

interface RequestAddBundleToBasket {
	type: typeof BasketActions.REQUEST_BASKET_ADDBUNDLETOBASKET;
	sku: string;
	quantities: IBundleItemQuantity[];
	combinedShipping: boolean;
	status: ProgressStatus;
}

const requestAddBundleToBasket = (
	sku: string,
	quantities: IBundleItemQuantity[],
	combinedShipping: boolean,
	status: ProgressStatus
): RequestAddBundleToBasket => {
	return {
		type: BasketActions.REQUEST_BASKET_ADDBUNDLETOBASKET,
		sku,
		quantities,
		combinedShipping,
		status
	};
};

interface ReceiveAddBundleToBasket {
	type: typeof BasketActions.RECEIVE_BASKET_ADDBUNDLETOBASKET;
	sku: string;
	quantities: IBundleItemQuantity[];
	status: ProgressStatus;
	postResult?: IHTTPResult;
}

const receiveAddBundleToBasket = (
	sku: string,
	quantities: IBundleItemQuantity[],
	status: ProgressStatus,
	postResult: IHTTPResult
): ReceiveAddBundleToBasket => {
	return {
		type: BasketActions.RECEIVE_BASKET_ADDBUNDLETOBASKET,
		sku,
		quantities,
		status,
		postResult
	};
};

/* GETCONFIGURATIONDATA */

interface RequestConfigurationData {
	type: typeof BasketActions.REQUEST_BASKET_GETCONFIGURATIONDATA;
	sku: string;
	status: ProgressStatus;
}

const requestConfigurationData = (
	sku: string,
	status: ProgressStatus
): RequestConfigurationData => {
	return {
		type: BasketActions.REQUEST_BASKET_GETCONFIGURATIONDATA,
		sku,
		status,
	};
};

interface ReceiveConfigurationData {
	type: typeof BasketActions.RECEIVE_BASKET_GETCONFIGURATIONDATA;
	sku: string;
	status: ProgressStatus;
	postResult: IHTTPResult;
	data?: IConfigurationData;
}

const receiveConfigurationData = (
	sku: string,
	status: ProgressStatus,
	postResult: IHTTPResult,
	data?: IConfigurationData
): ReceiveConfigurationData => {
	return {
		type: BasketActions.RECEIVE_BASKET_GETCONFIGURATIONDATA,
		sku,
		status,
		postResult,
		data,
	};
};

/* GETCONFIGURATIONCONTEXT */

interface RequestConfigurationContext {
	type: typeof BasketActions.REQUEST_BASKET_GETCONFIGURATIONCONTEXT;
	popupState: any;
	sku: string;
	status: ProgressStatus;
}

const requestConfigurationContext = (
	popupState: any,
	sku: string,
	status: ProgressStatus
): RequestConfigurationContext => {
	return {
		type: BasketActions.REQUEST_BASKET_GETCONFIGURATIONCONTEXT,
		popupState,
		sku,
		status,
	};
};

interface ReceiveConfigurationContext {
	type: typeof BasketActions.RECEIVE_BASKET_GETCONFIGURATIONCONTEXT;
	popupState: any;
	sku: string;
	status: ProgressStatus;
	postResult: IHTTPResult;
	data?: IConfigurationContext;
}

const receiveConfigurationContext = (
	popupState: any,
	sku: string,
	status: ProgressStatus,
	postResult: IHTTPResult,
	data?: IConfigurationContext
): ReceiveConfigurationContext => {
	return {
		type: BasketActions.RECEIVE_BASKET_GETCONFIGURATIONCONTEXT,
		popupState,
		sku,
		status,
		postResult,
		data,
	};
};

/* ADDCONFIGURAION */

interface RequestAddConfiguration {
	type: typeof BasketActions.REQUEST_BASKET_ADDCONFIGURATION;
	popupState: any;
}

const requestAddConfiguration = (popupState: any): RequestAddConfiguration => {
	return {
		type: BasketActions.REQUEST_BASKET_ADDCONFIGURATION,
		popupState,
	};
};

interface ReceiveAddConfiguration {
	type: typeof BasketActions.RECEIVE_BASKET_ADDCONFIGURATION;
	popupState: any;
	result: IHTTPResult;
}

const receiveAddConfiguration = (popupState: any, result: IHTTPResult): ReceiveAddConfiguration => {
	return {
		type: BasketActions.RECEIVE_BASKET_ADDCONFIGURATION,
		popupState,
		result,
	};
};

/* CLOSECONFIGURATION */

interface RequestCloseConfiguration {
	type: typeof BasketActions.REQUEST_BASKET_CLOSECONFIGURATION;
}

const requestCloseConfiguration = (): RequestCloseConfiguration => {
	return {
		type: BasketActions.REQUEST_BASKET_CLOSECONFIGURATION,
	};
};

export type BasketActionTypes =
	| RequestAddToBasket
	| ReceiveAddToBasket
	| UpdateAddToBasket
	| RequestConfigurationData
	| ReceiveConfigurationData
	| RequestConfigurationContext
	| ReceiveConfigurationContext
	| RequestAddConfiguration
	| ReceiveAddConfiguration
	| RequestAddBundleToBasket
	| ReceiveAddBundleToBasket
	| RequestGetBundleData
	| ReceiveGetBundleData
	| RequestCloseConfiguration;

const delayAsync = (time) => {
	return new Promise((resolve) => {
		setTimeout(resolve, time);
	});
};

export function addToBasket(
	applicationData: IApplicationData,
	sku: string,
	quantity: number,
	configuration?: IConfiguration
) {
	return (dispatch: any, getState: any) => {
		dispatch(requestAddToBasket(sku, quantity, 'waiting'));

		const { extensions, synchronizerToken } = applicationData;
		let url = extensions.BasketDispatchURL;
		let newQuantity = quantity;

		let body = '';
		if (configuration) {
			body += 'addProductWithReferenceAndQuantity=addProductWithReferenceAndQuantity';
		} else {
			body += 'addProduct=addProduct';
		}

		body += '&SynchronizerToken=' + synchronizerToken;
		body += '&SKU=' + sku;
		body += '&Quantity_' + sku + '=' + quantity;

		if (configuration) {
			body += '&Reference=' + configuration.reference;
			if (configuration.suggestedDate) {
				body += '&Date=' + configuration.suggestedDate;
			}
			body += '&SetDefaultAddress=true';
		}

		//make getResultOrRedirect typed
		const getResult = (response: Response): Promise<IHTTPResult> => {
			return getResultOrRedirect(response);
		};

		return fetch(`${url}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getResult)
			.then((result: IHTTPResult) => {
				let data: IAddToBasketResult = result.json?.data as IAddToBasketResult;
				newQuantity = data.value;
				dispatch(receiveAddToBasket(sku, newQuantity, 'success', result));
			})
			.then(() => delayAsync(2000))
			.then(() => dispatch(updateAddToBasket(sku, 'none')))
			.catch((error: any) => {
				Promise.resolve()
					.then(() => dispatch(receiveAddToBasket(sku, newQuantity, 'error', errorResult)))
					.then(() => delayAsync(2000))
					.then(() => dispatch(updateAddToBasket(sku, 'none')));
			});
	};
}

export function addProductBundleToBasket(
	applicationData: IApplicationState,
	sku: string,
	quantities: IBundleItemQuantity[], 
	combinedShipping: boolean) {
	return (dispatch, getState) => {
		dispatch(requestAddBundleToBasket(sku, quantities, combinedShipping, 'waiting'));

		const { extensions, synchronizerToken } = applicationData;
		const dispatchUrl = extensions.BasketDispatchURL;
		let body =
			'addBundleConfiguration=addBundleConfiguration&SKU=' +
			sku +
			'&Quantity_' +
			sku +
			'=1' +
			'&BundleConfiguration_' +
			sku +
			'=true' +
			'&SynchronizerToken=' +
			synchronizerToken;

		if (combinedShipping) {
			body += '&CombinedShipping=true';
		}

		//TODO: test this
		let bundleItems = Array.from(quantities.entries()).filter((entry) => entry[1].quantity > 0);
		for (const entry of bundleItems) {
			let sku = entry[0];
			let quantity = entry[1].quantity;
			body += '&BundleSKU=' + sku;
			body += '&BundleQuantity_' + sku + '=' + quantity;
		}

		//make getResultOrRedirect typed
		const getResult = (response: Response): Promise<IHTTPResult> => {
			return getResultOrRedirect(response);
		};

		return fetch(`${dispatchUrl}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getResult)
			.then((result: IHTTPResult) => dispatch(receiveAddBundleToBasket(sku, quantities, 'success', result)))
			.catch((error: any) => {
				Promise.resolve()
					.then(() => dispatch(receiveAddBundleToBasket(sku, [], 'error', errorResult)))
					.then(() => delayAsync(2000))
					.then(() => dispatch(receiveAddBundleToBasket(sku, [], 'none', errorResult)));
			});;
	};
}

/**
 *  REQUEST_BASKET_GETBUNDLEDATA
 */
 interface RequestGetBundleData {
	type: typeof BasketActions.REQUEST_BASKET_GETBUNDLEDATA;
	application: IApplicationState,
	lineID: string
}

 const requestGetBundleData = (
	application: IApplicationState,
	lineID: string
) => {
	return {
		type: BasketActions.REQUEST_BASKET_GETBUNDLEDATA,
		application,
		lineID,
	};
}

interface ReceiveGetBundleData {
	type: typeof BasketActions.RECEIVE_BASKET_GETBUNDLEDATA;
	application: IApplicationState,
	lineID: string, 
	postResult: IHTTPResult
}

const receiveGetBundleData = (
	application: IApplicationState,
	lineID: string, 
	postResult: IHTTPResult
) => {
	return {
		type: BasketActions.RECEIVE_BASKET_GETBUNDLEDATA,
		application,
		lineID,
		postResult,
	};
}

export const getBundleData = (
	application: IApplicationState,
	lineID: string
) => {
	return (dispatch, getState) => {
		dispatch(requestGetBundleData(application, lineID));

		const { extensions, synchronizerToken } = application;
		let url = extensions.BasketDispatchURL;
		const body = 'getBundle=getBundle&LineID=' + lineID + '&SynchronizerToken=' + synchronizerToken;

		//make getResultOrRedirect typed
		const getResult = (response: Response): Promise<IHTTPResult> => {
			return getResultOrRedirect(response);
		};

		return fetch(`${url}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getResult)
			.then((result: IHTTPResult) => {
				dispatch(receiveGetBundleData(application, lineID, result));
			})
			.catch((error: any) => {
				dispatch(receiveGetBundleData(application, lineID, errorResult));
			});;
	};
}

export function getConfigurationData(applicationData: IApplicationData, sku: string) {
	return (dispatch: any, getState: any) => {
		dispatch(requestConfigurationData(sku, 'waiting'));

		const { extensions, synchronizerToken } = applicationData;
		let url = extensions.BasketDispatchURL;

		const body =
			'getConfigurationData=getConfigurationData&ViewBasketConfiguration_SKU=' +
			sku +
			'&SynchronizerToken=' +
			synchronizerToken;

		//make getResultOrRedirect typed
		const getResult = (response: Response): Promise<IHTTPResult> => {
			return getResultOrRedirect(response);
		};

		return fetch(`${url}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getResult)
			.then((result: IHTTPResult) => {
				let data: IConfigurationData = result.json?.data as IConfigurationData;
				let status: ProgressStatus = result.error ? 'error' : 'success';
				dispatch(receiveConfigurationData(sku, status, result, data));
			})
			.catch((error: any) => {
				dispatch(receiveConfigurationData(sku, 'error', errorResult));
			});
	};
}

export function getConfigurationContext(
	applicationData: IApplicationData,
	popupState: any,
	sku: string
) {
	return (dispatch: any, getState: any) => {
		dispatch(requestConfigurationContext(popupState, sku, 'waiting'));

		const { extensions, synchronizerToken } = applicationData;
		let url = extensions.BasketDispatchURL;

		const body =
			'getConfigurationContext=getConfigurationContext&ViewBasketConfiguration_SKU=' +
			sku +
			'&SynchronizerToken=' +
			synchronizerToken;

		//make getResultOrRedirect typed
		const getResult = (response: Response): Promise<IHTTPResult> => {
			return getResultOrRedirect(response);
		};

		return fetch(`${url}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getResult)
			.then((result: IHTTPResult) => {
				let data: IConfigurationContext = result.json?.data as IConfigurationContext;
				let status: ProgressStatus = result.error ? 'error' : 'success';
				dispatch(receiveConfigurationContext(popupState, sku, status, result, data));
			})
			.catch((error: any) => {
				dispatch(receiveConfigurationContext(popupState, sku, 'error', errorResult));
			});
	};
}

export function addConfiguration(
	applicationData: IApplicationData,
	popupState,
	addressID: string,
	deliveryDate: string,
	reference: string,
	expressDelivery: string,
	configurationQuantity: string
) {
	return (dispatch: any, getState: any) => {
		dispatch(requestAddConfiguration(popupState));

		const { extensions, synchronizerToken } = applicationData;
		let url = extensions.BasketDispatchURL;

		let body = 'addConfiguration=addConfiguration';
		body += '&SynchronizerToken=' + synchronizerToken;
		body += '&AddBasketConfiguration_LineID=' + popupState.id;
		if (addressID) {
			body += '&AddBasketConfiguration_AddressID=' + addressID;
		}
		if (deliveryDate) {
			body += '&AddBasketConfiguration_Date=' + deliveryDate;
		}
		if (reference) {
			body += '&AddBasketConfiguration_Reference=' + reference;
		}
		if (expressDelivery) {
			body += '&AddBasketConfiguration_ExpressDelivery=' + expressDelivery;
		}
		if (configurationQuantity) {
			body += '&AddBasketConfiguration_ConfigurationQuantity=' + configurationQuantity;
		}

		//make getResultOrRedirect typed
		const getResult = (response: Response): Promise<IHTTPResult> => {
			return getResultOrRedirect(response);
		};

		return fetch(`${url}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then(getResult)
			.then((result: IHTTPResult) => {
				dispatch(receiveAddConfiguration(popupState, result));
			})
			.catch((error: any) => {
				dispatch(receiveAddConfiguration(popupState, errorResult));
			});
	};
}

export function closeConfiguration() {
	return (dispatch: any, getState: any) => {
		dispatch(requestCloseConfiguration());
	};
}

const basketActions = {
	addToBasket,
	addProductBundleToBasket,
	getConfigurationData,
	getConfigurationContext,
	addConfiguration,
	getBundleData,
	closeConfiguration,
};

export default basketActions;
