import React, { Fragment, Component, ReactNode, createRef } from 'react';

interface IProps {
	label?: string;
	/*
	 * Use this for label-top
	 */
	extraClass?: string;
	extraInputClass?: string;
	combinedButton?: ReactNode;
	combinedClass?: string;
	attributes: {
		name: string;
		id: string;
		type: string;
		defaultValue?: string;
		value?: string;
		maxLength?: number;
		max?: number | string;
		min?: number | string;
		onChange?: any;
		autoComplete?: string;
	};
	onChange?: any;
	onClick?: any;
	onKeyDown?: any;
	onBlur?: any;
	error?: ReactNode;
	dataSl?: string;
}

/**
 * @deprecated consider using formikelements instead
 */
export default class TextField extends Component<IProps> {
	private textfieldElement = createRef<HTMLInputElement>();

	setFocus() {
		if (this.textfieldElement && this.textfieldElement.current) {
			this.textfieldElement.current.select();
			this.textfieldElement.current.focus();
		}
	}

	render() {
		const {
			extraClass,
			extraInputClass,
			label,
			attributes,
			onChange,
			onBlur,
			onClick,
			onKeyDown,
			dataSl,
			error,
			combinedButton,
			combinedClass,
		} = this.props;

		let cssClass = attributes.type === 'number' ? 'form-input ta-center' : 'form-input';

		let errorClass = '';
		let errorElement: ReactNode = null;
		if (React.isValidElement(error)) {
			errorClass = ' field-invalid';
			//give error component a 'name' prop so they do not have to be specified
			errorElement = React.cloneElement(error as React.ReactElement<any>, {
				name: attributes.id,
			});
		}

		let formfieldClass = `form-field  ${errorClass}  ${extraClass ?? ''}`;

		return (
			<Fragment>
				<div className={formfieldClass} data-castlecss-field>
					{label && <label htmlFor={attributes.id}>{label}</label>}
					<CombinedWrap combined={combinedButton !== undefined} combinedClass={combinedClass ?? ''}>
						<input
							ref={this.textfieldElement}
							className={`${cssClass} ${extraInputClass ?? ''}`}
							onChange={onChange}
							onBlur={onBlur}
							onClick={onClick}
							data-sl={dataSl}
							onKeyDown={onKeyDown}
							{...attributes}
						/>
						{combinedButton !== undefined && combinedButton}
					</CombinedWrap>
				</div>
				{errorElement}
			</Fragment>
		);
	}
}

interface ICombinedProps {
	combined?: boolean;
	combinedClass?: string;
}

class CombinedWrap extends Component<ICombinedProps> {
	render() {
		if (this.props.combined) {
			let cssClass = this.props.combinedClass ? this.props.combinedClass : 'input-combined';
			return <div className={cssClass}>{this.props.children}</div>;
		}

		return <Fragment>{this.props.children}</Fragment>;
	}
}
