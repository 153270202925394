import React from 'react';
import { IFeatureContext } from 'shared/context/types';

export const featureContextDefaults = () => {
	const featureContext: IFeatureContext = {
		initial: true,
		lastUpdated: null,
		flushDate: null,
		returnOrder: false,
		tileView: false,
		tweakwise: false,
		autosuggest: false,
		defaultView: null,
		expressDelivery: true
	};
	return featureContext;
};

export default React.createContext(featureContextDefaults());
