import React, { FC } from 'react';

interface OwnProps {
	order: any;
}

interface StateProps {
	localizations: any;
}

type IProps = OwnProps & StateProps;

export const DirectOrderItemDeliveryDate: FC<IProps> = (props) => {
	const { order, localizations } = props;

	if (!order?.dealerDeliveryDate?.length) {
		return null;
	}

	return (
		<div className='b0_12 b5_06'>
			<h4 className='head head4'>{localizations['common.account.directorder.dealer.info.text']}</h4>
			<br />
			<strong>{localizations['common.account.directorder.dealer.deliverydate.text']}</strong>
			<p>{order.dealerDeliveryDate}</p>
		</div>
	);
};

export default DirectOrderItemDeliveryDate;
