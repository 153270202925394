import { IHTTPResult } from 'returnorders/types';
import { IAutoComplete } from 'shared/messages/Messages';
import AutoComplete from 'shared/objects/AutoComplete';

export interface ISearchState {
	isFetching: boolean;
	autocomplete?: AutoComplete;
	result?: IHTTPResult;
}

export enum SearchActions {
	REQUEST_AUTOCOMPLETE = 'REQUEST_AUTOCOMPLETE',
	RECEIVE_AUTOCOMPLETE = 'RECEIVE_AUTOCOMPLETE',
}

interface RequestAutoCompleteAction {
	type: typeof SearchActions.REQUEST_AUTOCOMPLETE;
	searchTerm: string;
}

interface ReceiveAutoCompleteAction {
	type: typeof SearchActions.RECEIVE_AUTOCOMPLETE;
	searchTerm: string;
	result: IHTTPResult;
	autocomplete: IAutoComplete;
}

export type SearchActionTypes = RequestAutoCompleteAction | ReceiveAutoCompleteAction;
