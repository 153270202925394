import { IAutoComplete } from 'shared/messages/Messages';
import AutoComplete from 'shared/objects/AutoComplete';

export default class Mapper {
	static getAutoComplete(response: object, verbose: boolean = false): AutoComplete {
		if (verbose) {
			console.log('mapping', response);
		}

		let data = response as IAutoComplete;
		if (verbose) {
			console.log('data', data);
		}

		let output = new AutoComplete(data);
		if (verbose) {
			console.log('output', output);
		}

		return output;
	}
}
