import React, { FC } from 'react';

interface IProps {
	productStatus: any;
}

const ProductStock: FC<IProps> = (props) => {
	const { productStatus } = props;

	const {
		dispayStock,
		dispayStockMobile,
		statusClass,
		quantityClass,
		stockText,
		stockMessage,
		quantityText,
		canOrder,
	} = productStatus;

	if (!canOrder) {
		return <div className={statusClass + ' list-item-message mb-0'}>{stockMessage}</div>;
	}

	let htmlText = <div className='alert-invisible'></div>;

	if (dispayStock) {
		htmlText = (
			<div className={statusClass + ' ' + quantityClass + ' mb-0 small'}>
				<span className='hide-b4'>{stockText}</span> {quantityText}
			</div>
		);
	}

	let cssClass = !dispayStockMobile ? 'hide show-b4' : '';

	return <div className={'list-item-availability ' + cssClass}>{htmlText}</div>;
};

export default ProductStock;
