import React, { Component } from 'react';

export default class ProgressElement extends Component {
	render() {
		let loader = null;

		if (this.props.error) {
			loader = <i className='icon-cancel error' data-sl='list-icon-cancel-error'></i>;
		} else if (this.props.success) {
			loader = <i className='icon-check success' data-sl='list-icon-check-success'></i>;
		} else if (this.props.waiting) {
			loader = <i className='icon-spin4 animate-spin'></i>;
		}

		return <div className='list-item-icon'>{loader}</div>;
	}
}
