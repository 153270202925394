import React from 'react';

class LoadingElement extends React.Component {
	render() {
		let children = this.props.children;
		let content =
			this.props.children != null ? (
				children
			) : (
				<span className={'shadow-element ' + (this.props.extraClass ? this.props.extraClass : '')}>
					&nbsp;
				</span>
			);

		return <React.Fragment>{content}</React.Fragment>;
	}
}

export default LoadingElement;
