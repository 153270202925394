export interface IHTTPResult {
	json?: IJSON;
	data?: object;
	status: number;
	error: boolean;
}

export interface IJSON {
	data: object;
	errorMessages: string[];
	success: boolean;
}

export interface IProgressResult {
	key: string;
	status: ProgressStatus;
}

export type ProgressStatus = 'none' | 'waiting' | 'success' | 'error';

export enum IBreakpoints {
	/**
	 * 320px
	 */
	b1 = '320px',
	/**
	 * 480px
	 */
	b2 = '480px',
	/**
	 * 768px
	 */
	b3 = '768px',
	/**
	 * 1024px
	 */
	b4 = '1024px',
	/**
	 * 1280px
	 */
	b5 = '1280px',
	/**
	 * 1680px
	 */
	b6 = '1680px',
	/**
	 * 1920px
	 */
	b7 = '1920px',
}

export enum ISizes {
	/**
	 * 79x79
	 */
	tiny = 'tiny',
	/**
	 * 117x117
	 */
	small = 'small',
	/**
	 * 196x196
	 */
	medium = 'medium',
	/**
	 * 320x320
	 */
	large = 'large',
	/**
	 * 479x479
	 */
	huge = 'huge',
	/**
	 * 767x767
	 */
	zoom1 = 'zoom1',
	/**
	 * 1534x1534
	 */
	zoom2 = 'zoom2',
	/**
	 * 2400x2400
	 */
	zoom3 = 'zoom3',
}

export interface IConfigImage {
	b1?: IImagePixelDepth;
	b2?: IImagePixelDepth;
	b3?: IImagePixelDepth;
	b4?: IImagePixelDepth;
	b5?: IImagePixelDepth;
	b6?: IImagePixelDepth;
	b7?: IImagePixelDepth;
}

interface IImagePixelDepth {
	default: string;
	retina: string;
}

export interface IConfig {
	standardImage: string;
	sources?: IConfigImage[];
}

export interface IImage {
	name: string;
	altText?: string;
	imageLink?: string;
	videoClass?: string;
	videoLink?: string;
}
