/**

 * REPORTS ACTIONS CONSTANTS

 */
import { IHTTPResult } from '../types';
import { TweakwiseActions } from './types';
import fetch from 'isomorphic-fetch';
import { IProduct } from '../messages/messages';

function assignReactId(obj: object, reactId: string) {
	return Object.assign(obj, {
		reactId: reactId,
	});
}

/* GET PRODUCTS */

function requestRecommendedProducts(displayId: string) {
	return {
		type: TweakwiseActions.REQUEST_RECOMMENDATIONS,
		displayId,
	};
}

function receiveRecommendedProducts(postResult: IHTTPResult, products: IProduct[]) {
	return {
		type: TweakwiseActions.RECEIVE_RECOMMENDATIONS,
		postResult,
		products,
	};
}

export function fetchRecommendedProducts(reactId: string, displayId: string) {
	return (dispatch: any, getState: any) => {
		dispatch(assignReactId(requestRecommendedProducts(displayId), reactId));

		const { extensions, synchronizer } = getState();
		const synchronizerToken = synchronizer[reactId];

		let url = extensions.TweakwiseProductsUrl;
		let body = 'getRecommendedProducts=getRecommendedProducts';
		body += '&SynchronizerToken=' + synchronizerToken;
		body += '&DisplayId=' + displayId;

		// TODO: Add needed dataflags later for display correct product data
		// body += '&DataFlag=AddBasketQuantity';
		// body += '&DataFlag=AddDropshipmentQuantity';
		// body += '&DataFlag=AddVariantProducts';
		// body += '&DataFlag=AddPermissions';
		// body += '&DataFlag=AddFavorites';
		// body += '&DataFlag=AddStock';
		// body += '&DataFlag=AddBackorder';
		// body += '&DataFlag=AddPrice';
		// body += '&DataFlag=AddExtraImages';

		let postResult: IHTTPResult = {
			json: {},
			status: 0,
			error: true,
		};

		return fetch(`${url}`, {
			method: 'post',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body,
		})
			.then((response: Response) => {
				if (response.status != 200) {
					throw new Error('unable to fetch products');
				}

				let json = response.json();

				postResult.json = json;
				postResult.status = response.status;
				postResult.error = false;

				return json;
			})

			.then((data: any) =>
				dispatch(
					assignReactId(receiveRecommendedProducts(postResult, data.data as IProduct[]), reactId)
				)
			)

			.catch((error: any) =>
				dispatch(assignReactId(receiveRecommendedProducts(postResult, []), reactId))
			);
	};
}
