import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accountSwitch } from '../actions';
import Select from 'shared/formelements/select';

class AccountSwitch extends Component {
	handleChange(e) {
		this.props.accountSwitch(e.target.value);
	}

	render() {
		const { data, userName, userId } = this.props;

		return (
			<div className='header-notifications-account'>
				<User userName={userName} userId={!data ? userId : null} />
				{data && (
					<form action={data.url} method='post' data-sl='header-account-switch'>
						<Select
							name='CustomerID'
							id='CustomerID'
							selectedOption={data.currentCustomerId}
							options={data.options}
							onChange={this.handleChange.bind(this)}
						/>
					</form>
				)}
			</div>
		);
	}
}

class User extends Component {
	render() {
		const { userName, userId } = this.props;

		const decodedUserName = () => {
			let divInstance = document.createElement('div');
			divInstance.innerHTML = userName;

			return divInstance.firstChild.nodeValue;
		};

		return (
			<div className='title'>
				<i className='icon-person'></i>
				<span className='descr'>
					{decodedUserName()}
					{userId && <small>{userId}</small>}
				</span>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		notifications: state.notifications,
		general: state.general,
		localizations: state.localizations,
		extensions: state.extensions,
	};
}

export default connect(mapStateToProps, { accountSwitch })(AccountSwitch);
