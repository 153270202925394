import React, { Component } from 'react';

export default class SubMenuItem extends Component {
	render() {
		const { url, name, iconClass, amount, style } = this.props;

		return (
			<a href={url} className={`header-notifications-submenuitem ${iconClass}`} data-sl={this.props.dataSl}>
				{name}
				{amount !== undefined && (
					<em className='sticker-amount' style={style}>
						<span className='nr'>{amount}</span>
					</em>
				)}
			</a>
		);
	}
}
