import React, { FC } from 'react';
import { IProduct } from '../messages/messages';

interface OwnProps {
	product: IProduct;
}

type IProps = OwnProps;

const ProductSku: FC<IProps> = (props) => {
	const { product } = props;

	return (
		<span className='inline-block narrow'>
			<small className='color-color06'>{product.sku}</small>
		</span>
	);
};

export default ProductSku;
