import React, { FC } from 'react';
import ShadowElement from 'shared/components/ShadowElement';

const OrderInfoLineShadow: FC = (props) => {
	return (
		<li>
			<div className='m'>
				<ShadowElement extraClass='widget-order-title narrow' />
			</div>
		</li>
	);
};

export default OrderInfoLineShadow;
