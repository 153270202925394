import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchNotications } from 'shared/general/actions';
import NotifyBalloon from '../components/NotifyBalloon';
import { PROGRESS_RESULT } from '../types';
import { animations } from 'react-animation';
import FeatureProvider from 'shared/context/featureProvider';
import SubMenu from '../components/SubMenu';
import Person from 'shared/general/components/Person';

class NotificationBar extends React.Component {
	constructor(props) {
		super(props);
		this.timeOutId = null;
	}

	componentDidMount() {
		this.props.fetchNotications(this.props.general.headerDataUrl, this.getSidebarNotifications());
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.notifications.outdated && this.props.notifications.outdated) {
			//poor mans solution
			clearInterval(this.timeOutId);
			this.timeOutId = setTimeout(
				function () {
					this.props.fetchNotications(
						this.props.general.headerDataUrl,
						this.getSidebarNotifications()
					);
				}.bind(this),
				1000
			);
		}
	}

	getSidebarNotifications() {
		let param = '';

		// get specific notifications if sidebar is present
		let elements = document.getElementsByClassName('nav-menu-counter');
		if (elements?.length > 0) {
			param = '?GetSidebarNotifications=true';
		}

		return param;
	}

	setNavMenuNotification(counterName, counterValue) {
		if (counterValue != null && isNaN(counterValue) == false && counterValue > 0) {
			var mainEl = document.getElementById('menu-counter-' + counterName);
			var counterEl = document.getElementById('menu-counter-value-' + counterName);
			if (mainEl != null && counterEl != null) {
				mainEl.className = 'sticker-amount right';
				mainEl.style.removeProperty('display');
				counterEl.innerHTML = counterValue;
			}
		}
	}

	notification(name, item, children) {
		const { general } = this.props;
		const style =
			item == null || item.status == null || item.status !== PROGRESS_RESULT.SUCCESS
				? null
				: {
						animation: animations.popIn,
				  };

		// Do not show red balloon for account when no new messages
		const showNumberOfProducts = name !== 'person' ? true : item && item.numberOfProducts > 0;

		return (
			<div className={name}>
				<a href={item ? item.url : '#'} data-sl={name}>
					{name === 'person' ? (
						<i className={`icon-${name}`} />
					) : (
						<i className={`icon-${name}-thin`} />
					)}

					{showNumberOfProducts && (
						<em className='sticker-amount' style={style}>
							<span className='nr'>{item && item.numberOfProducts}</span>
						</em>
					)}
				</a>
				{children && <SubMenu items={children} general={general} />}
			</div>
		);
	}

	render() {
		const { notifications, general } = this.props;
		if (!notifications) {
			return null;
		}

		//child items
		const wishlist = {
			url: general.accountSubMenu.wishlist.url,
			name: general.accountSubMenu.wishlist.title,
			iconClass: 'icon-favorites-thin',
		};
		const mylists = {
			url: general.accountSubMenu.myLists.url,
			name: general.accountSubMenu.myLists.title,
			iconClass: 'icon-note-filled-thin',
		};
		const message = {
			url: general.accountSubMenu.messages.url,
			name: general.accountSubMenu.messages.title,
			iconClass: 'icon-mail-thin',
		};

		if (!notifications.basket) {
			const basket = { url: general.basketUrl };
			const dropshipment = { url: general.dropshipmentUrl };

			return (
				<Fragment>
					{this.notification('person', message, [message, wishlist, mylists])}
					{this.notification('dropshipment', dropshipment)}
					{this.notification('cart', basket)}
				</Fragment>
			);
		}

		// show sidebar notifications if present
		if (notifications.sidebar) {
			Object.entries(notifications.sidebar).forEach(([key, value]) => {
				const { counterValue, counterName } = value;
				this.setNavMenuNotification(counterName, counterValue);
			});
		}

		return (
			<FeatureProvider>
				<Person />
				{this.notification('dropshipment', notifications.dropshipment)}
				{this.notification('cart', notifications.basket)}
				{notifications.showMessage && (
					<NotifyBalloon
						type={notifications.basket.free ? 'positive' : 'warning'}
						message={notifications.basket.message}
					/>
				)}
			</FeatureProvider>
		);
	}
}

function mapStateToProps(state) {
	return {
		notifications: state.notifications,
		general: state.general,
		localizations: state.localizations,
		extensions: state.extensions,
	};
}

export default connect(mapStateToProps, { fetchNotications })(NotificationBar);
