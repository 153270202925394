import React, { FC } from 'react';

interface IProps {
	extraClass?: string;
}

const ShadowElement: FC<IProps> = (props) => {
	return (
		<span className={'shadow-element ' + (props.extraClass ? props.extraClass : '')}>&nbsp;</span>
	);
};

export default ShadowElement;
