import { useState, useEffect } from 'react';
import $ from 'jquery';
import Cookies from 'js-cookie';

interface ISettings {
	showMsrPrices: boolean;
	showPrices: boolean;
	showEOL: boolean;
	appUser: boolean;
}

const useSettings = (): ISettings => {
	const [showMsrPrices, setMsrPrices] = useState(false);
	const [showPrices, setPrices] = useState(true);
	const [showEOL, setEOL] = useState(false);
	const [appUser, setAppUser] = useState(false);

	const updateSettings = () => {
		let showMsrPrices = false;
		if (Cookies.get('showMsrPrices') == 'true') {
			showMsrPrices = true;
		}
		setMsrPrices(showMsrPrices);

		let showPrices = true;
		if (Cookies.get('showPrices') == 'false') {
			showPrices = false;
		}
		setPrices(showPrices);

		let showEOL = false;
		if (Cookies.get('dealerportal_eol_state') == 'true') {
			showEOL = true;
		}
		setEOL(showEOL);

		let appUser = false;
		if (Cookies.get('dealerportal_app_user') == 'true') {
			appUser = true;
		}
		setAppUser(appUser);
	};

	useEffect(() => {
		updateSettings();
	}, []);

	// document.addEventListener('view:updated', function () {
	// 	updateSettings();
	// });
	$(document).on('view:updated', function (e, data) {
		updateSettings();
	});

	return {
		showMsrPrices,
		showPrices,
		showEOL,
		appUser,
	};
};

export default useSettings;
