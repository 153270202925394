import React, { FC, Fragment, useRef, useState, useEffect } from 'react';
import FeatureProvider from 'shared/context/featureProvider';
import FeatureContext from 'shared/context/featureContext';
import createConnect from 'shared/general/searchbar/connect';
import AutoComplete from 'shared/general/searchbar/autoComplete';
import TextField from 'shared/formelements/textfield';
import useForm from 'shared/forms/useForm';
import * as validations from 'shared/forms/validations';
import { ISearchState } from 'shared/general/searchbar/types';
import Notify from 'returnorders/forms/notify';

import { BehaviorSubject } from 'rxjs';
import { filter, debounceTime } from 'rxjs/operators';
import Cookies from 'js-cookie';

interface OwnProps {
	localizations: object;
}

interface StateProps {
	extensions: object;
	general: object;
	search: ISearchState;
}

interface DispatchProps {
	fetchAutoComplete: Function;
}

type IProps = StateProps & DispatchProps & OwnProps;

interface AutoCompleteUpdate {
	SearchTerm: string;
}

const autocomplete$ = new BehaviorSubject<AutoCompleteUpdate>({
	SearchTerm: '',
});

const SearchBar: FC<IProps> = (props) => {
	const { localizations, search, general } = props;
	const { fetchAutoComplete } = props;

	const label = localizations['common.header.searchbox.search.placeholder.text'];
	const [searchTerm, setSearchTerm] = useState('');
	const [showAutoComplete, setShowAutoComplete] = useState(false);
	const [showMenuButton, setShowMenuButton] = useState(true);

	const urlParam = (name): string => {
		var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
		if (results) {
			return decodeURIComponent(results[1].replace(/\+/g, ' ')) || '';
		}
		return '';
	};

	useEffect(() => {
		if (Cookies.get('dealerportal_app_user') === 'true') {
			setShowMenuButton(false);
		}

		let searchVal = urlParam('SearchTerm');
		if (searchVal.length > 0) {
			setSearchTerm(searchVal);
			setTextFieldValue(searchVal);
		}
	}, []);

	const searchTermElement: any = useRef(null);
	const formRef: any = useRef(null);

	const onSelectSearchTerm = (selectedSearchTerm: string, submit: boolean = true) => {
		updateElements({ SearchTerm: selectedSearchTerm }, false);
		const el = searchTermElement.current.textfieldElement.current;
		el.value = selectedSearchTerm;
		if (submit) {
			formRef.current.submit();
		} else {
			setTimeout(() => {
				//set cursor to end of text when using arrows.
				//Timeout is necessary for up arrow.
				//That sets the cursor to begin of textfield
				let length = el.value.length;
				el.focus();
				el.setSelectionRange(length, length);
			}, 5);
		}
	};

	const validate: any = (currentValues: any) => {
		let errors: any = {};

		//no validation needed at the moment

		return errors;
	};

	let defaultValues = {
		SearchTerm: searchTerm,
	};

	const handleServerSubmit = (e: any): void => {
		e.preventDefault();
		formRef.current.submit();
	};

	const { values, errors, handleChange, handleBlur, handleClientSubmit, updateElements } = useForm(
		handleServerSubmit,
		validate,
		defaultValues
	);

	const updateAutoComplete = () => {
		let searchUpdate = {
			SearchTerm: formRef.current.SearchTerm.value,
		};
		autocomplete$.next(searchUpdate);
	};

	const handleSearchBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleChange(e);
	};

	const handleSearchBoxChangeAutocomplete = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleChange(e);
		updateAutoComplete();
	};

	const handleFocusSearchBox = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		searchTermElement.current.setFocus();
		setShowAutoComplete(false);
	};

	const handleClearSearchBox = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		setSearchTerm('');
		setTextFieldValue('');
		setShowAutoComplete(false);
	};

	const setTextFieldValue = (value: string) => {
		formRef.current.SearchTerm.value = value;
		formRef.current.SearchTerm.dispatchEvent(new Event('input', { bubbles: true }));
	};

	useEffect(() => {
		const subscription = autocomplete$
			.pipe(
				// wait until user stops typing
				debounceTime(500)
				// send request only if there are 3 or more characters
				//filter((update: AutoCompleteUpdate) => update.SearchTerm.length > 2)
			)
			.subscribe(
				(update: AutoCompleteUpdate) => {
					if (update.SearchTerm.length > 2) {
						setShowAutoComplete(true);
						fetchAutoComplete(update.SearchTerm);
						setSearchTerm(update.SearchTerm);
					} else {
						setShowAutoComplete(false);
						if (formRef.current.SearchTerm.value.length === 0) {
							setSearchTerm('');
						}
					}
				},
				(error) => {
					// handle error here
					console.error(error);
				}
			);
		return () => subscription.unsubscribe();
	}, []);

	const searchClassName = `search ${
		showAutoComplete || searchTerm.length > 0 ? 'has-text has-value' : ''
	}`;

	return (
		<FeatureProvider>
			{showMenuButton && !showAutoComplete && (
				<button id='nav-label' className='nav-label hide-b4'>
					<span>{localizations['common.header.loggedin.menubutton.text']}</span>
				</button>
			)}
			<fieldset className='form-autocomplete'>
				<div className='form-autocomplete-wrap'>
					<form
						ref={formRef}
						className='form'
						onSubmit={handleClientSubmit}
						action={general['searchUrl']}
						method='get'
						role='search'
						itemProp='potentialAction'
						itemScope
						itemType='http://schema.org/SearchAction'
						data-searchform
					>
						<FeatureContext.Consumer>
							{(featureContext) => {
								return (
									<div className='form-fields'>
										<TextField
											ref={searchTermElement}
											attributes={{
												name: 'SearchTerm',
												id: 'SearchTerm',
												type: 'search',
												autoComplete: 'off',
												defaultValue: searchTerm,
												maxLength: 100,
											}}
											extraClass={searchClassName}
											extraInputClass='fc_search text'
											dataSl="search-term"
											label={label}
											onChange={
												featureContext.autosuggest
													? handleSearchBoxChangeAutocomplete
													: handleSearchBoxChange
											}
											onBlur={handleBlur}
											onClick={handleFocusSearchBox}
											error={errors.SearchTerm}
											combinedButton={
												<Fragment>
													<button
														type='button'
														className='btn btn-cancel'
														data-sl="search-term-cancel"
														data-remove-seachvalue
														onClick={handleClearSearchBox}
													>
														<i className='icon-cancel'></i>
													</button>
													<button type='submit' className='btn btn-submit' data-sl="search-term-submit">
														<i className='icon-search'></i>
														<span className='hide'>
															{localizations['common.header.searchbox.search.text']}
														</span>
													</button>
												</Fragment>
											}
											combinedClass='input-combined-search'
										/>
										<Fragment>
											{featureContext.autosuggest && (
												<AutoComplete
													visible={showAutoComplete}
													onSelectSearchTerm={onSelectSearchTerm}
													searchTerm={searchTerm}
												/>
											)}
										</Fragment>
									</div>
								);
							}}
						</FeatureContext.Consumer>
					</form>
				</div>
			</fieldset>
		</FeatureProvider>
	);
};

const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(SearchBar);
