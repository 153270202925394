import React, { FC } from 'react';
import { EDirectOrderState, EProgressStatus } from '../container/types';

interface OwnProps {
	orderState: string;
	progressStatus: string;
}

interface StateProps {
	localizations: any;
}

type IProps = StateProps & OwnProps;

export const DirectOrderState: FC<IProps> = (props) => {
	const { orderState, progressStatus } = props;
	const { localizations } = props;

	const getProgressElement = () => {
		switch (progressStatus) {
			case EProgressStatus.inprogress:
				return (
					<span className='small mb-0 alert-warning'>
						{localizations['common.account.directorder.status.order.inprogress']}
					</span>
				);

			case EProgressStatus.ordering:
				return (
					<span className='small mb-0 alert-warning'>
						{localizations['common.account.directorder.status.order.ordering']}
					</span>
				);

			default:
				//instead of null, return "inprogress", because not every order will have the progressStatus attribute when this goes live
				return (
					<span className='small mb-0 alert-warning'>
						{localizations['common.account.directorder.status.order.inprogress']}
					</span>
				);
		}
	};

	const getOrderStateElement = () => {
		switch (orderState) {
			case EDirectOrderState.open:
				return (
					<span className='small mb-0 alert-warning'>
						{localizations['common.account.directorder.status.order.open']}
					</span>
				);

			case EDirectOrderState.completed:
				return (
					<span className='small mb-0 alert-positive'>
						{localizations['common.account.directorder.status.order.completed']}
					</span>
				);

			case EDirectOrderState.inprogress:
				return getProgressElement();

			case EDirectOrderState.canceled:
				return (
					<span className='small mb-0 alert-negative'>
						{localizations['common.account.directorder.status.order.canceled']}
					</span>
				);

			default:
				return null;
		}
	};

	return getOrderStateElement();
};

export default DirectOrderState;
