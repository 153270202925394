import fetch from 'isomorphic-fetch';
import { IHTTPResult } from 'returnorders/types';
import { uriEncodeFormObject } from 'shared/libs/urlhandling';
import { SearchActions } from 'shared/general/searchbar/types';
import AutoComplete from 'shared/objects/AutoComplete';
import Mapper from 'shared/objects/Mapper';

/* GET AUTOCOMPLETE SUGGESTIONS */

function requestAutoComplete(searchTerm: string) {
	return {
		type: SearchActions.REQUEST_AUTOCOMPLETE,
		searchTerm,
	};
}

function receiveAutoComplete(
	searchTerm: string,
	result: IHTTPResult | null,
	autocomplete: AutoComplete | null
) {
	return {
		type: SearchActions.RECEIVE_AUTOCOMPLETE,
		searchTerm,
		result,
		autocomplete,
	};
}

export function fetchAutoComplete(searchTerm: string) {
	return (dispatch: any, getState: any) => {
		dispatch(requestAutoComplete(searchTerm));

		const { general, synchronizerToken } = getState();
		let url = general.autocompleteURL;

		let body = {};
		body['SynchronizerToken'] = synchronizerToken;
		body['SearchTerm'] = searchTerm;
		let bodyString = uriEncodeFormObject(body);

		let httpResult: IHTTPResult = {
			json: {},
			status: 200,
			error: false,
		};

		Promise.resolve()
			//test error
			//.then(()=> {throw new Error("test error");})
			.then(() =>
				fetch(url, {
					method: 'post',
					credentials: 'same-origin',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					body: bodyString,
				})
			)
			.then((response: Response) => {
				httpResult.status = response.status;
				if (response.status == 200) {
					return response.json();
				} else {
					throw new Error('autocomplete not found');
				}
			})
			.then((json: object) => {
				httpResult.json = json;
				return Mapper.getAutoComplete(json);
			})
			.then((autocomplete: AutoComplete) =>
				dispatch(receiveAutoComplete(searchTerm, httpResult, autocomplete))
			)
			.catch((error: string) => dispatch(receiveAutoComplete(searchTerm, httpResult, null)));
	};
}

/* END AUTOCOMPLETE SUGGESTIONS */
