import React, { FC } from 'react';
import DirectOrderRemark from './directOrderRemark';

interface OwnProps {
	order: any;
	readMoreOpen: boolean;
}

interface StateProps {
	localizations: any;
}

interface DispatchProps {
	toggleReadMore: any;
}

type IProps = OwnProps & StateProps & DispatchProps;

export const DirectOrderRemarks: FC<IProps> = (props) => {
	const { order, readMoreOpen, toggleReadMore, localizations } = props;

	if (!(order?.orderRemarks?.orderRemarks?.length > 0)) {
		return <></>;
	}

	const remarktsOpenClass = readMoreOpen ? 'remarks-open' : '';

	const getReadMoreElement = () => {
		if (!(order?.orderRemarks?.orderRemarks.length > 2)) {
			return null;
		}

		return (
			<div className='read-more'>
				<button className='btn-link link-next' onClick={toggleReadMore}>
					{readMoreOpen
						? localizations['common.account.directorder.remarks.readless.text']
						: localizations['common.account.directorder.remarks.readmore.text']}
				</button>
			</div>
		);
	};

	return (
		<>
			<h4 className='head head4'>
				{localizations['common.account.directorder.remarks.title.text']}
			</h4>
			<section className={`list list-remarks ${remarktsOpenClass} mb-2x`}>
				<header className='g gutter-none list-item no-hover hide show-b4'>
					<div className='b0_12 b4_04'>
						<strong>{localizations['common.account.directorder.remarks.user.text']}</strong>
					</div>
					<div className='b0_12 b4_04'>
						<strong>{localizations['common.account.directorder.remarks.date.text']}</strong>
					</div>
					<div className='b0_12 b4_04'>
						<strong>{localizations['common.account.directorder.remarks.remark.text']}</strong>
					</div>
				</header>
				{order.orderRemarks.orderRemarks.map((remark, index) => (
					<DirectOrderRemark localizations={localizations} remark={remark} key={index} />
				))}

				{getReadMoreElement()}
			</section>
		</>
	);
};

export default DirectOrderRemarks;
