import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as listActions from 'directorder/container/actions';

function createConnect<StateProps, DispatchProps, OwnProps>() {
	const mapStateToProps = (state: any, ownProps: any): any => {
		let initialState = {
			localizations: state.localizations,
			extensions: state.extensions,
			//data: state.data,
			directorder: state.directorder,
		};

		// if (ownProps.reactId) {
		// 	//data-react-data attributes are gathered in a single object for all components, map the state back to this specific component
		// 	initialState = Object.assign(initialState, {
		// 		data: state.data[ownProps.reactId],
		// 		list: state[ownProps.reactId],
		// 	});
		// }
		return initialState;
	};

	const mapDispatchProps = (dispatch: any): any => {
		const actions = {
			...listActions,
		};
		return bindActionCreators(actions, dispatch);
	};

	const connectContext = connect<StateProps, DispatchProps, OwnProps>(
		mapStateToProps,
		mapDispatchProps
	);
	return connectContext;
}

export default createConnect;
