import React, { FC } from 'react';
import createConnect from 'dashboard/orderinfo/container/connect';
import { IChangedDeliveryDate } from '../messages/types';

interface OwnProps {
	line: IChangedDeliveryDate;
}

interface StateProps {
	extensions: any;
}

type IProps = StateProps & OwnProps;

const OrderInfoLine: FC<IProps> = (props) => {
	const { line, extensions } = props;
	const detailUrl = extensions?.OrderHistoryDetailUrl.replace(/_$/, line.salesId);
	return (
		<li>
			<a href={detailUrl} title={line.itemDescription}>
				<span className='widget-order-title'>{line.itemDescription}</span>
				<span
					className={`widget-order-state ${line.delayed ? 'notify-negative' : 'notify-positive'}`}
				>
					{line.newShippingDateConfirmed}
				</span>
			</a>
		</li>
	);
};

const connectContext = createConnect<StateProps, {}, OwnProps>();
export default connectContext(OrderInfoLine);
