import React, { Component } from 'react';
import SubMenuItem from 'shared/general/components/SubMenuItem';
import AccountSwitch from 'shared/general/components/AccountSwitch';

export default class SubMenu extends Component {
	render() {
		const { items, general } = this.props;
		
		let subItems = items.map((item, idx) => (
			<SubMenuItem
				key={idx}
				url={item.url}
				dataSl={idx === 0 ? "top-right-menu-inbox" : idx === 1 ? "top-right-menu-favorites" : "top-right-menu-most-ordered"}
				name={item.name}
				iconClass={item.iconClass}
				amount={item.numberOfProducts}
				style={item.style}
			/>
		));

		return (
			<div className={`header-notifications-submenu`}>
				{subItems}

				<div className='pt-2x pb'>
					<SubMenuItem
						url={general.accountSubMenu.logout.url}
						name={general.accountSubMenu.logout.title}
						iconClass={'icon-mail-thin rotate'}
						dataSl="top-right-menu-logout-user"
					/>
					<AccountSwitch
						userName={general.accountSubMenu.currentUser}
						userId={general.accountSubMenu.currentUserId}
						data={general.accountSubMenu.accountSwitch}
					/>
				</div>
			</div>
		);
	}
}
