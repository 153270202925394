import React, { FC} from 'react';
import { Customer } from 'directorder/objects/DirectOrder';

interface OwnProps {
	customer: Customer;
}

type IProps = OwnProps;

export const DirectOrderCustomerInfo: FC<IProps> = (props) => {
	const { customer } = props;

	return (
		<p className='do-item-customerinfo'>
			<span>{customer.name}</span>
			<span>{customer.address}</span>
			<span>
				{customer.postalCode} {customer.city}
			</span>
			<span>{customer.email}</span>
			<span>{customer.phone}</span>
		</p>
	);
};

export default DirectOrderCustomerInfo;
