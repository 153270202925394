import React, { FC, Fragment, useState, useEffect } from 'react';
import { IFeatureContext } from 'shared/context/types';
import FeatureContext, { featureContextDefaults } from 'shared/context/featureContext';
import fetch from 'isomorphic-fetch';
import Cookies from 'js-cookie';
import moment from 'moment';
import { ready } from 'shared/libs/readyState';

interface OwnProps {
	updateTileView?: boolean;
}

interface StateProps {
	//featureContext: IFeatureContext;
}

type IProps = OwnProps & StateProps;

const FeatureProvider: FC<IProps> = (props) => {
	const { updateTileView } = props;

	/*constants*/
	const verbose = false;
	const fetchFeaturesUrl = '/b2b/nl/actions/ViewFeatures-View';
	const cookieFlushDate = 'dealerportal_feature_flushDate';
	const cookieLastUpdated = 'dealerportal_feature_lastUpdated';
	const cookieTileView = 'dealerportal_feature_tileView';
	const defaultView = 'dealerportal_defaultView';
	const cookieReturnOrder = 'dealerportal_feature_returnOrder';
	const cookieTweakwise = 'dealerportal_feature_tweakwise';
	const cookieAutosuggest = 'dealerportal_feature_autosuggest';
	const cookieExpressDelivery = 'dealerportal_feature_expressDelivery';
	const hoursToRefresh = 3;
	const dateFormat = 'DD.MM.YYYY HH:mm';

	/*state*/
	const [featureContext, setFeatureContext] = useState(featureContextDefaults());

	const loadCookies = (context: IFeatureContext) => {
		if (verbose) {
			console.log('FeatureProvider useEffect loadCookies');
		}

		let newFeatureContext = Object.assign({}, context, {
			initial: false,
		});

		let flushDate = Cookies.get(cookieFlushDate);
		if (flushDate) {
			newFeatureContext = Object.assign({}, newFeatureContext, {
				flushDate: flushDate,
			});
		}

		let lastUpdated = Cookies.get(cookieLastUpdated);
		if (lastUpdated) {
			let lastUpdatedMoment = moment(lastUpdated, dateFormat);
			if (lastUpdatedMoment.isValid()) {
				newFeatureContext = Object.assign({}, newFeatureContext, {
					lastUpdated: lastUpdatedMoment.toDate(),
				});
			}
		}

		let tileView = Cookies.get(cookieTileView);
		if (tileView) {
			newFeatureContext = Object.assign({}, newFeatureContext, {
				tileView: tileView == 'true',
			});
		}

		let returnOrder = Cookies.get(cookieReturnOrder);
		if (returnOrder) {
			newFeatureContext = Object.assign({}, newFeatureContext, {
				returnOrder: returnOrder == 'true',
			});
		}

		let tweakwise = Cookies.get(cookieTweakwise);
		if (tweakwise) {
			newFeatureContext = Object.assign({}, newFeatureContext, {
				tweakwise: tweakwise == 'true',
			});
		}

		let autosuggest = Cookies.get(cookieAutosuggest);
		if (autosuggest) {
			newFeatureContext = Object.assign({}, newFeatureContext, {
				autosuggest: autosuggest == 'true',
			});
		}

		let expressDelivery = Cookies.get(cookieExpressDelivery);
		if (expressDelivery) {
			newFeatureContext = Object.assign({}, newFeatureContext, {
				expressDelivery: expressDelivery == 'true',
			});
		} else {
			newFeatureContext = Object.assign({}, newFeatureContext, {
				expressDelivery: true,
			});
		}

		setFeatureContext(newFeatureContext);
	};

	const storeCookies = (context: IFeatureContext) => {
		if (verbose) {
			console.log('FeatureProvider useEffect storeCookies');
		}

		const settings = {
			path: '/',
			secure: true,
			sameSite: 'strict',
		};

		Cookies.set(cookieFlushDate, context.flushDate, settings);
		if (context.lastUpdated) {
			Cookies.set(cookieLastUpdated, moment().format(dateFormat), settings);
		}
		Cookies.set(cookieTileView, context.tileView, settings);
		Cookies.set(defaultView, context.defaultView, settings);
		Cookies.set(cookieReturnOrder, context.returnOrder, settings);
		Cookies.set(cookieTweakwise, context.tweakwise, settings);
		Cookies.set(cookieAutosuggest, context.autosuggest, settings);
		Cookies.set(cookieExpressDelivery, context.expressDelivery, settings);

		/*refresh header in case user has logged in on a different customer with different features*/
		if (window['modules']) {
			window['modules'].viewsettings.initTileViewCheckbox();

			if (updateTileView) {
				window['modules'].viewsettings.initTileViewIcon();
			}
		}
	};

	const updateFeatureDateTime = () => {
		let newFeatureContext = Object.assign({}, featureContext, {
			lastUpdated: new Date(),
		});
		storeCookies(newFeatureContext);
		setFeatureContext(newFeatureContext);
	};

	const refreshCookies = () => {
		let url = fetchFeaturesUrl;
		if (featureContext.flushDate) {
			url += `?FlushDate=${featureContext.flushDate}`;
		}

		Promise.resolve()
			//test error
			//.then(()=> {throw new Error("test error");})
			.then(() =>
				fetch(`${url}`, {
					method: 'get',
					credentials: 'same-origin',
				})
			)
			.then((response: Response) => {
				if (response.status == 200) {
					return response.json();
				} else {
					throw new Error('unable to fetch features');
				}
			})
			.then((json) => {
				//if 'updated' is set to false the features values have not changed
				if (json.updated == false) {
					if (verbose) {
						console.log('FeatureProvider server says: "nothing to update"');
					}
					updateFeatureDateTime();
					return;
				}
				if (verbose) {
					console.log('FeatureProvider server says: "update!"');
				}
				//update state
				let newFeatureContext = Object.assign({}, featureContext, {
					tileView: json.tileView,
					defaultView: json.defaultView,
					returnOrder: json.returnOrder,
					tweakwise: json.tweakwise,
					autosuggest: json.autosuggest,
					flushDate: json.flushDate,
					expressDelivery: json.expressDelivery,
					lastUpdated: new Date(),
				});
				storeCookies(newFeatureContext);
				setFeatureContext(newFeatureContext);
			})
			.catch((e: any) => {
				console.log(e);
				//failed refresh attempt, try again later
				updateFeatureDateTime();
			});
	};

	useEffect(() => {
		//load current values from cookies
		loadCookies(featureContext);

		// /*refresh header in case user has logged in on a different customer with different features*/
		if (updateTileView) {
			//timeout added to make sure the modules have been initialized
			ready(() => {
				if (window['modules']) {
					window['modules'].viewsettings.initTileViewIcon();
				}
			});
		}
	}, []);

	useEffect(() => {
		if (verbose) {
			console.log('FeatureProvider useEffect refreshCookies', featureContext);
		}

		if (featureContext.initial) {
			if (verbose) {
				console.log('SKIP INITIAL DATA, WAIT FOR COOKIES TO LOAD');
			}
			return;
		}

		let shouldRefresh = false;
		if (featureContext.lastUpdated === null) {
			shouldRefresh = true;
		} else {
			let currentDate = moment(new Date());
			let lastUpdated = moment(featureContext.lastUpdated);
			let refreshDate = lastUpdated.add(hoursToRefresh, 'h');
			shouldRefresh = lastUpdated.isValid() == false || currentDate.isAfter(refreshDate);

			if (verbose) {
				console.log('currentDate', currentDate);
			}
			if (verbose) {
				console.log('lastUpdated', lastUpdated);
			}
			if (verbose) {
				console.log('refreshDate', refreshDate);
			}
		}

		if (verbose) {
			console.log('shouldRefresh', shouldRefresh);
		}

		if (shouldRefresh) {
			if (verbose) {
				console.log('REFRESHING');
			}
			refreshCookies();
		}
		//check when to refresh after every featureContext update
	}, [featureContext]);

	return <FeatureContext.Provider value={featureContext}>{props.children}</FeatureContext.Provider>;
};

export default FeatureProvider;
