import React, { FC, useState, useEffect, Fragment } from 'react';
import { objectStatus, getOrderQuantity } from 'shared/libs/lib';
import { ISizes } from 'shared/types';

import { IProductBlockState } from 'productblock/container/types';
import { IProduct } from 'productblock/messages/messages';
import useSettings from 'productblock/container/useSettings';

import ProductFavorite from 'productblock/components/productFavorite';
import ProductImage from 'shared/components/Image';
import ProductPrice from 'productblock/components/productPrice';
import ProductStock from 'productblock/components/productStock';
import Progress from 'shared/components/Progress';
import Empty from 'shared/components/Empty';
import { pushProductClick } from 'shared/libs/gaevents';
import Alert from 'returnorders/forms/alert';
import createConnect from 'productblock/container/connect';

interface OwnProps {
	reactId: string;
	product: IProduct;
	hideEOLProducts: boolean;
}

interface StateProps {
	localizations: object;
	list: IProductBlockState;
}

interface DispatchProps {
	addToBasket: Function;
	updateFavoriteProduct: Function;
}

type IProps = StateProps & DispatchProps & OwnProps;

const ProductItem: FC<IProps> = (props) => {
	const { reactId, product, hideEOLProducts } = props;
	const { localizations, list } = props;
	const { addToBasket, updateFavoriteProduct } = props;

	const { addToBasketResult, isFetching } = list;

	const { sku, name, productUrl, prices, sale, images, stock, quantity, behavior } = product;

	const productStatus = objectStatus({
		stock,
		behavior,
	});

	if (hideEOLProducts && productStatus.isEOL) {
		return null;
	}

	const { showMsrPrices, showPrices } = useSettings();

	const [currentQuantity, setCurrentQuantity] = useState(quantity);

	const [orderQuantity, setOrderQuantity] = useState({});

	useEffect(() => {
		setCurrentQuantity(quantity);
	}, [quantity]);

	let productPrice = (
		<ProductPrice
			prices={prices}
			sale={sale}
			showPrices={showPrices}
			showMsrPrices={showMsrPrices}
			productStatus={productStatus}
		/>
	);

	let productImage = <ProductImage images={images} b1={ISizes.large} />;

	let productStock = <ProductStock productStatus={productStatus} />;

	let addToBasketStatus = (
		<Fragment>
			{productStatus.canOrder && addToBasketResult && addToBasketResult.key === sku ? (
				<Progress status={addToBasketResult.status} />
			) : (
				<Empty />
			)}
		</Fragment>
	);

	const handleAddToBasket = (e) => {
		e.preventDefault();

		let newQuantity = currentQuantity + 1;
		let maxQuantity = behavior.maxQuantity;
		let stockQuantity = product.stock ? product.stock.stockQuantity : 0;
		let stockDate = product.stock ? product.stock.date : '';
		let orderQuantity = getOrderQuantity(
			newQuantity,
			stockQuantity,
			stockDate,
			maxQuantity,
			behavior.status
		);
		if (orderQuantity.message) {
			setOrderQuantity(orderQuantity);
			newQuantity = orderQuantity.quantity;
			setTimeout(() => {
				setOrderQuantity({});
			}, 2000);
		}
		addToBasket(reactId, sku, newQuantity);
	};

	let productAddToBasketButton = (
		<Fragment>
			{productStatus.canOrder ? (
				<span className='btn' onClick={handleAddToBasket}>
					<i className='icon-cart'></i>
				</span>
			) : (
				<Empty />
			)}
		</Fragment>
	);

	const handleProductClick = (e) => {
		e.preventDefault();
		pushProductClick(product, 'productblocklister', 0);
		window.location.href = e.currentTarget.href;
	};

	return (
		<article className='b0_10 b3_12 b4_12 b5_06'>
			<div className='list-item list-item-block column'>
				{sale && !showMsrPrices && (
					<span className='list-item-sale'>{localizations['shared.listeritem.sale.label']}</span>
				)}
				<a className='list-img' href={productUrl} onClick={handleProductClick}>
					{productImage}
				</a>
				<ProductFavorite
					reactId={reactId}
					product={product}
					updateFavoriteProduct={updateFavoriteProduct}
					isFetching={isFetching}
				/>
				<h2 className='head head5 mb-2x'>{name}</h2>
				<div className='g ai-center'>
					<div className='b0_04'>{productPrice}</div>
					<div className={!productStatus.canOrder ? 'b0_12' : 'b0_02'}>{productStock}</div>
					<div className='b0_02'>{addToBasketStatus}</div>
					<div className='b0_04'>{productAddToBasketButton}</div>
				</div>
				<div className='g ai-center'>
					{/*@Gerwin: check alert message*/}
					<div className='b0_12'>
						{orderQuantity['message'] && (
							<Alert
								state={'warning'}
								message={localizations['shared.product.quantity.message']
									.split('$(0)')
									.join(orderQuantity['quantity'])}
							/>
						)}
					</div>
				</div>
			</div>
		</article>
	);
};


const connectContext = createConnect<StateProps, DispatchProps, OwnProps>();
export default connectContext(ProductItem);
