import AutoComplete from 'shared/objects/AutoComplete';

export enum PROGRESS_RESULT {
	NONE = 'NONE',
	WAITING = 'WAITING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

// export enum SearchActions {
// 	REQUEST_AUTOCOMPLETE = 'REQUEST_AUTOCOMPLETE',
// 	RECEIVE_AUTOCOMPLETE = 'RECEIVE_AUTOCOMPLETE',
// }

// interface RequestAutoCompleteAction {
// 	type: typeof SearchActions.REQUEST_AUTOCOMPLETE;
// 	searchTerm: string;
// }

// interface ReceiveAutoCompleteAction {
// 	type: typeof SearchActions.RECEIVE_AUTOCOMPLETE;
// 	searchTerm: string;
// 	data: AutoComplete;
// }

// export type SearchActionTypes = RequestAutoCompleteAction | ReceiveAutoCompleteAction;
