import {
	IProductBlockState,
	ProductActions,
	ProductActionTypes,
} from 'productblock/container/types';
import { IProgressResult } from 'productblock/types';
import { pushProductImpressions, pushUpdateFromBasket } from 'shared/libs/gaevents';
import produce from 'immer';

const initialState: IProductBlockState = {
	isFetching: false,
	products: [],
	productResult: undefined,
	addToBasketResult: undefined,
	reactId: undefined,
};

export function productblockReducer(reactId: string) {
	const componentInitialState: IProductBlockState = Object.assign({}, initialState, {
		reactId: reactId,
	});
	function productblockComponent(
		state = componentInitialState,
		action: ProductActionTypes
	): IProductBlockState {
		return productblock(state, action);
	}
	return productblockComponent;
}

function productblock(state = initialState, action: ProductActionTypes): IProductBlockState {
	const { reactId } = action;

	if (reactId !== state.reactId) {
		return state;
	}

	switch (action.type) {
		case ProductActions.REQUEST_PRODUCT: {
			return Object.assign({}, state, {
				isFetching: true,
			});
		}

		case ProductActions.RECEIVE_PRODUCT: {
			const { products, productResult } = action;

			let filteredProducts = products.slice(0, 4);
			let newState = Object.assign({}, state, {
				isFetching: false,
				products: filteredProducts,
				productResult,
			});

			if (filteredProducts.length > 0) {
				let extra = { position: 0 };
				pushProductImpressions(filteredProducts, 'productblocklister', extra);
			}

			return newState;
		}

		case ProductActions.REQUEST_ADDTOBASKET: {
			const { sku, quantity, status } = action;
			let addToBasketResult: IProgressResult = {
				key: sku,
				status: status,
			};
			let newState = Object.assign({}, state, {
				addToBasketResult: addToBasketResult.status === 'none' ? undefined : addToBasketResult,
			});
			return newState;
		}

		case ProductActions.UPDATE_ADDTOBASKET: {
			const { sku, status } = action;
			let addToBasketResult: IProgressResult = {
				key: sku,
				status: status,
			};

			//update addToBasketResult and quantity
			const newState = produce(state, (draft) => {
				draft.addToBasketResult =
					addToBasketResult.status === 'none' ? undefined : addToBasketResult;
			});

			return newState;
		}

		case ProductActions.RECEIVE_ADDTOBASKET: {
			const { sku, quantity, status } = action;
			let addToBasketResult: IProgressResult = {
				key: sku,
				status: status,
			};

			//update GA
			if (addToBasketResult.status === 'success') {
				let productIndex = state.products.findIndex((product) => product.sku === sku);
				pushUpdateFromBasket(state.products[productIndex], quantity, 'productblocklister', null);
			}

			//update addToBasketResult and quantity
			const newState = produce(state, (draft) => {
				draft.addToBasketResult =
					addToBasketResult.status === 'none' ? undefined : addToBasketResult;
				if (addToBasketResult.status === 'success') {
					let productIndex = state.products.findIndex((product) => product.sku === sku);
					draft.products[productIndex].quantity = quantity;
				}
			});

			return newState;
		}

		case ProductActions.REQUEST_FAVORITEPRODUCT: {
			const { sku } = action;
			return state;
		}

		case ProductActions.RECEIVE_FAVORITEPRODUCT: {
			const { sku, favorite, postResult } = action;

			const newState = produce(state, (draft) => {
				if (postResult && !postResult.error) {
					let productIndex = state.products.findIndex((product) => product.sku === sku);
					draft.products[productIndex].isFavorite = favorite;
				}
			});

			return newState;
		}

		default:
			return state;
	}
}
