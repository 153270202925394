import React from 'react';

const NotifyBalloon = ({ type, message }) => {
	return (
		<div className={`header-basket-notifications ${type} fade-in-out`} style={{ display: 'flex' }}>
			<div className='g ai-center'>
				<div className='b0_03'>
					<i className='icon-truck'></i>
				</div>
				<div className='b0_09 as-center'>
					<span>{message}</span>
				</div>
			</div>
		</div>
	);
};

export default NotifyBalloon;
